// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=qa` then `environment.qa.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
   // apiBasePath: 'https://stageadminapi.mykartavya.site/api',
      apiBasePath:'https://api.mykartavya.org/api', // local
    //apiBasePath: 'https://qaadminapi.mykartavya.site/api', //qa
    // apiBasePath: 'https://mkdevapi.bluelup.in/api',
   // apiBasePath: 'https://api.mykartavya.org/api',
    
      port: 6061
};

