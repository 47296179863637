import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { FormGroup, FormControl,FormBuilder, Validators,FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from '../../../shared/services/master.services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { DomSanitizer, SafeResourceUrl,SafeHtml } from '@angular/platform-browser';
import { ReportService } from '../../../shared/services/report.service';

@Component({
  selector: 'campaign-taskdetail',
  templateUrl: './campaign-taskdetail.component.html',
})

export class CampaignTaskDetailComponent implements OnInit {


  adminLoginId: number;
  campaignId: number;
  taskId: number;
  details: any;
  isAddNewVolunteer: boolean = false;
  searchedVolunteers: any[] = [];
  volunteerSearchTerm: string;
  isAddNewGroup: boolean = false;
  pushmessage: string;
  pushmsgValidation: boolean = false;
  pushmsgSendSuccess: boolean = false;
  pushemail: string;
  pushemailValidation: boolean = false;
  pushemailSendSuccess: boolean = false;
  skills: any;
  skillId:any = 0;
    isPublic: boolean;
  selectedVolunteer: any;
  comment: string;

  groupForm: FormGroup;
  validateForm: boolean = false;
  groupId: number;
    cities: any;
    states: any;
    countryId: number;
    citiesStateWise: any[]=[];
    volunteerForm: FormGroup;
    countries: any;
    validateVolunteersForm: boolean = false;
  createGroup: boolean = false;
  showGroupList:boolean = false;
  groups: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  groupIcon: string = "sorting";
  applicantsIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
  feedbacks: any[] = [];
  userFeedbackComment: string;
  userId: any;
  rating: any;
  feedbackDesc: any;
  eventId: any;
  adminFeedback: any;
  feedbackMedia: any;
  activeTab: string = "detail";
  listVolunteers: boolean = false;
  detail: boolean = false;
  addedVoluteers: any[] = [];
  addedGroups: any[] = [];
  addedGroupVoluteers: any[] = [];
  karmaMsg: string;
  htmlContent:SafeHtml;
  isLastTask:boolean;
  rejectedGroupId: any;
  isPushNotification: boolean = true;
  isEmail: boolean = false;
  statesCountryWise: any[]=[];
  

    constructor(private _service: AdminCampaignService,private master:AdminMasterService, private volunteerService: AdminVolunteerService, private route: ActivatedRoute, private authentication: AuthenticationService, private router: Router, private location: Location,private fb:FormBuilder,private toaster: ToastrManager,private feedback:FeedbackService, private sanitizer: DomSanitizer,private reportService:ReportService) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==2){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.campaignId = route.snapshot.params['campaignid'];
      this.taskId = route.snapshot.params['taskid'];
      this.activeTab = route.snapshot.params['volunteer'] === "vol" ? "volunteers" : "detail";
    });

      var routeMap = this.route.snapshot.url;
      if (routeMap[1].path.toLowerCase() == "public") {
          this.isPublic = true;
      }
  }

  getTaskDetails() {
    var obj = { taskId: this.taskId, campaignId: this.campaignId,formatDescription:true };
    this._service.getGetTaskDetail(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
          this.details = response.data;
          this.isLastTask = response.data.isLastTask;
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.details.description);
        console.log(this.details);
      }
    });
  }
  addVolunteer() {
    this.isAddNewVolunteer = true;
  }

  addGroup() {
    this.isAddNewGroup = true; this.createGroup = false;
  }
  searchVolunteer() {
  
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { "searchTerm": this.volunteerSearchTerm,"existingUserIds":this.addedVoluteers };
     
      this.volunteerService.searchVolunteer(obj).subscribe(response => {
       
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }
  selectVolunteer(volunteer: any, i: number): boolean {
    if (this.details.taskUsers == null) {
      this.details.taskUsers = [];
    }
    volunteer.statusId = 2;
    volunteer.status = "Approved";
    if (this.details.taskUsers.length > 0) {
      var exist = this.details.taskUsers.filter(a => a.userLoginId == volunteer.userLoginId);
      if (exist.length > 0) {
        this.searchedVolunteers = [];
        this.volunteerSearchTerm = '';
        this.isAddNewVolunteer = false;
        return this.isAddNewVolunteer;
      }
    }
    var obj = { taskId: this.taskId, taskUsers: volunteer };
    this._service.AddTaskuser(obj).subscribe(response => {
    
      if (response.isSuccess) {
        this.details.taskUsers.push(volunteer);
        this.addedVoluteers.push(volunteer.userLoginId);
      }else{
        this.toaster.errorToastr(response.responseMessage);
      }
    });
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewVolunteer = false;
  }

  searchGroup() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { "searchTerm": this.volunteerSearchTerm,"existingGroupIds":this.addedGroups };
      this.volunteerService.searchGroup(obj).subscribe(response => {
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }
  selectVolunteerForAction(volunteer: any) {
    this.selectedVolunteer = volunteer;
    this.comment = '';
  }

  selectGroup(group: any, i: number): boolean {
    if (this.details.taskUsers == null) {
      this.details.taskUsers = [];
    }
    group.statusId = 2;
    group.status = "Approved";
    if (this.details.taskUsers.length > 0) {
      var exist = this.details.taskUsers.filter(a => a.groupId == group.groupId);
      if (exist.length > 0) {
        this.searchedVolunteers = [];
        this.volunteerSearchTerm = '';
        this.isAddNewGroup = false;
        return this.isAddNewGroup;
      }
    }
    var obj = { taskId: this.taskId, taskUsers: group };
    
    this._service.AddTaskuser(obj).subscribe(response => {
      if (response.isSuccess) {
           this.getTaskDetails();
          if (!this.details.taskGroups) {
           
              this.details.taskGroups = [];
          }
          this.getDetails();
          this.details.taskGroups.push(group);
          this.addedGroups.push(group.groupId);
      }
      else{
        this.toaster.errorToastr(response.responseMessage);
    }
    });
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewGroup = false;
  }

  performActionOnUser(statusId: number, status: string, volunteer: any) {
    var obj = { campaignId: this.campaignId, StatusId: statusId, taskId: this.taskId, taskUsers: volunteer,comment:this.comment };
  
    this._service.performActionOnUser(obj).subscribe(response => {
      if (response.isSuccess) {
        volunteer.statusId = statusId;
        volunteer.status = status;
      }
    });
  }

  RejectGroup(groupId:any){
    this.rejectedGroupId = groupId;
  }
  
  performActionOnGroup(statusId: any, groupId: any) {
    var obj = { campaignId: this.campaignId,taskId: this.taskId,AdminLoginId:this.adminLoginId, GroupId: this.rejectedGroupId, Status: statusId, Comment:this.comment };
    this._service.performActionOnGroup(obj).subscribe(response => {
      if (response) {       
        this.getTaskDetails();
        this.comment = '';
      }
    });
  }

  maganeTapped() {
    this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);

    }

    navigateBack() {
        this.location.back();
    }

    onPushNotificationSelect(){
      this.isPushNotification = true; this.isEmail = false;
    }
  
    onEmailSelect(){
      this.isEmail = true; this.isPushNotification = false;
    }
  
    sendPushNotification() {
  
      this.pushmsgValidation = false; this.pushemailValidation = false;
  
      if(this.isPushNotification){
        if (this.pushmessage == undefined || this.pushmessage == null || this.pushmessage.trim() == "" ) {
          this.pushmsgValidation = true;
        }
        else {
          this.pushmsgValidation = false;
          var obj = { TaskId: this.taskId, Message: this.pushmessage };
         
          this._service.pushNotificationforCampaignsTaskUser(obj).subscribe(response => {
            if (response.isSuccess) {
              this.toaster.successToastr("Notification Sent Successfully");
              this.pushmessage = '';
            }else{
              this.toaster.errorToastr("Notification Not Sent");
            }
          },(err)=>{
            this.toaster.errorToastr("Notification Not Sent");
          });
        }
      }
      else{
        if (this.pushemail == undefined || this.pushemail == null || this.pushemail.trim() == "" ) {
          this.pushemailValidation = true;
        }
        else {
          this.pushemailValidation = false;
          var reqobj = { taskId: this.taskId, Content: this.pushemail };
       
          this._service.mailNotificationforCampaignsUser(reqobj).subscribe(response => {
            if (response) {
              this.toaster.successToastr("Mail Sent Successfully");
              this.pushemail = '';
            }else {
              this.toaster.errorToastr("Mail Not Sent");
            }
          }, (err) => {
            this.toaster.errorToastr("Mail Not Sent");
          });
        }
      }
    
    }
  

  createGroupForm() {
    this.groupForm = new FormGroup({
      groupId: new FormControl(''),
      adminLoginId: new FormControl(''),
      groupName: new FormControl('', Validators.required),
      volunteers: new FormArray([])
    });
  }

  get volunteers(): FormArray {
    return this.groupForm.get("volunteers") as FormArray;
  }

  add(data?: any) {
    this.createVolunteerForm();
    this.volunteers.push(new FormGroup({
      userLoginId: new FormControl(0),
      email: new FormControl('', Validators.required),
      mobileNumber: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      isNew: new FormControl(true),
      volunteerSearchTerm: new FormControl(''),
      searchedVolunteers: new FormControl([])
    }));
    
    
  }

  remove(index: number) {
    this.volunteers.removeAt(index)
  }

  saveGroup() {
    this.validateForm = true;
    if (this.groupForm.valid) {
      this.groupForm.value.adminLoginId = this.adminLoginId;
      if (this.groupForm.value.volunteers.length > 0) {
        var val = this.groupForm.value;
        var ob = {"taskId":this.taskId}
        this.volunteerService.addGroup({ ...val,...ob}).subscribe(response => {
          if (response.isSuccess) {
            var group = {
              "groupId": response.data.groupId,
              ​"groupName": response.data.groupName,
              "status": response.data.status,
              ​"statusId": response.data.statusId,
              "volunteerCount":response.data.volunteerCount
            }
            if (this.details.taskGroups == null) {
           
              this.details.taskGroups = []; this.details.taskGroups.push(group);
          }else{
            this.details.taskGroups.push(group);
          }
           
            this.addedGroups.push(group.groupId);
            this.getGroups();   
            this.toaster.successToastr("Group added successfully");    
            this.isAddNewGroup = false; this.createGroup = false;
          }
        });
      }
      else {
        this.toaster.errorToastr("Please add a volunteer first.");
      }
    }
  }

  searchGroupVolunteer(volunteer: FormGroup) {
    if (volunteer.controls.volunteerSearchTerm.value != '' && volunteer.controls.volunteerSearchTerm.value != null) {
      var obj = { "searchTerm": volunteer.controls.volunteerSearchTerm.value,"existingGroupUserIds":this.addedGroupVoluteers };
      console.log(obj);
      this.volunteerService.searchVolunteer(obj).subscribe(response => {
        console.log(response);
        if (response.isSuccess) {
          volunteer.controls.searchedVolunteers.setValue({ data: response.list });// = response.list;
          
        }
      });
    }
    else {
      volunteer.controls.searchedVolunteers.setValue({ data: [] }); // = [];
    }
  }


  getDetails() {
    var obj = { GroupId: this.groupId };
    this.volunteerService.groupDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var details = response.data;
        this.groupForm.setValue({
          adminLoginId: '',
          groupId: '',
          groupName: '',
          volunteers: []
        });

        if(details!=null && details.volunteers!=null){
          for (var i = 0; i < details.volunteers.length; i++) {
            this.volunteers.push(new FormGroup({
              userLoginId: new FormControl(details.volunteers[i].userLoginId),
              firstName: new FormControl(details.volunteers[i].firstName),
              lastName: new FormControl(details.volunteers[i].lastName),
              email: new FormControl(details.volunteers[i].email),
              mobileNumber: new FormControl(details.volunteers[i].mobileNumber),
              isNew: new FormControl(false),
              volunteerSearchTerm: new FormControl(''),
              searchedVolunteers: new FormControl([{ data: [] }])
            }));
          }
        }
     
      
        this.groupForm.setValue({
          adminLoginId: this.adminLoginId,
          groupId: details.groupId,
          groupName: details.groupName,
          volunteers: this.volunteers.value
        });

        
      }
    });
  }



  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
    }

    noWhiteSpace(event) {
        if (event.keyCode === 32) {
            return false;
        }
        return true;
    }

    whiteSpaceValidator(control: FormControl): { [key: string]: any } | null {
        var val = control.value;

        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  //checkIfBoxEmpty() {
  //  if (volunteer.controls.volunteerSearchTerm.value == '' || volunteer.controls.volunteerSearchTerm.value == null) {
  //    this.searchedVolunteers = [];
  //  }
  //}

  selectGroupVolunteer(volunteer: any, i: number) {
   //if (!volunteer.isPartOfGroup) {
      var exist = this.volunteers.value.filter(a => a.userLoginId == volunteer.userLoginId);
      if (exist.length == 0) {
        this.volunteers.controls[i].setValue({
          userLoginId: volunteer.userLoginId,
          email: volunteer.email,
          mobileNumber: volunteer.mobileNumber,
          firstName: volunteer.firstName,
          lastName: volunteer.lastName,
          isNew: false,
          volunteerSearchTerm: '',
          searchedVolunteers: { data: [] }
        });

        this.addedGroupVoluteers.push(volunteer.userLoginId);
      }
      else {
        this.volunteers.controls.pop();
      }
    //}
    //else {
    //  alert("Volunteer is already a part of another group");
    //}
    }

    createVolunteerForm() {
        this.volunteerForm = this.fb.group({
            firstName: ['', [Validators.required, this.whiteSpaceValidator]],
            lastName: ['', [Validators.required, this.whiteSpaceValidator]],
            countryId: [this.countryId, Validators.required],
            stateId: ['', Validators.required],
            cityId: ['', Validators.required],
            gender: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
            mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            
        });
    }
    resetForm() {
        this.volunteerForm.get("firstName").setValue('');
        this.volunteerForm.get("lastName").setValue('');
        this.volunteerForm.get("stateId").setValue('');
        this.volunteerForm.get("cityId").setValue('');
        this.volunteerForm.get("gender").setValue('');
        this.volunteerForm.get("email").setValue('');
        this.volunteerForm.get("mobileNumber").setValue('');

        this.validateForm = false;
    }

    createVolunteer() {

        var isExist = this.checkExisting(this.volunteerForm.get("email").value, this.volunteerForm.get("mobileNumber").value);
        if (isExist)
        {
            this.toaster.errorToastr("Volunteer already Exist"); this.resetForm();
            return;
        }
        if (this.volunteerForm.valid) {

            var val = this.volunteerForm.value;
            var data = {
                "adminLoginId": this.adminLoginId
            }
            var obj = { ...data, ...val }
            this.volunteerService.addVolunteer(obj).subscribe(response => {
                if (response.isSuccess) {
                   
                        this.toaster.successToastr("Volunteer created successfully");
                        this.volunteers.push(new FormGroup({
                            userLoginId: new FormControl(response.data.userLoginId),
                            email: new FormControl(response.data.email),
                            mobileNumber: new FormControl(response.data.mobileNumber),
                            firstName: new FormControl(response.data.firstName),
                            lastName: new FormControl(response.data.lastName),
                            isNew: new FormControl(false),
                            volunteerSearchTerm: new FormControl(''),
                            searchedVolunteers: new FormControl([])
                        }))
                }
                else {

                    if (response.responseMessage != null && response.responseMessage != '') {
                        this.toaster.errorToastr(response.responseMessage);
                    }
                }
                this.resetForm();
            }, (err) => {
                this.toaster.errorToastr("Some error occured");
                    this.resetForm();
            });

        }
    }

    // getCities() {
    //     var obj = { PageNumber: 1, PageSize: 4000 };
    //     this.master.getCities(obj).subscribe(response => {
    //         this.cities = response.data;
    //     });
    // }

    // FilterCityByStateId(stateId: number) {
    //     console.log(stateId)
    //     this.citiesStateWise = [];
    //     this.cities.forEach(c => {
    //         if (c.stateId == stateId) {
    //             this.citiesStateWise.push(c);
    //         }
    //     });
    // }

    FilterStateByCountryId(countryId: number) {
      
      var obj = { pageNumber: 1, PageSize: 500,CountryId:countryId };
      this.master.getStates(obj).subscribe(response => {
        this.statesCountryWise = response.data;
      });
  }

  FilterCityByStateId(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 4000, StateId:stateId };
    this.master.getCities(obj).subscribe(response => {
      this.citiesStateWise = response.data;
    });
  }

    // getStates() {
    //     var obj = { pageNumber: 1, PageSize: 500, CountryId: this.countryId };
    //     this.master.getStates(obj).subscribe(response => {
    //         this.states = response.data;
    //     });
    // }

    onCancel() {
        this.resetForm();
    }

    checkExisting(email: string, mobileNumber: string): boolean {

        var isExist = false;

        for (var i = 0; i < this.volunteers.length; i++)
        {
            var frm = this.volunteers.at(i);
            
            if (frm.get("email").value.toLowerCase() == email.toLowerCase() && frm.get("mobileNumber").value == mobileNumber)
            {
                isExist = true;
            }
        }
      
        return isExist;
    }

  onClickAddGroup() {
      this.createGroup = true; this.isAddNewGroup = false; this.showGroupList = false;
      var obj = { pageNumber: 1, PageSize: 500 };
      this.master.getCountries(obj).subscribe(response => {
          this.countries = response.data;
          //console.log(this.countries)
          //this.countryId = this.countries[0].countryId;
      });
       //this.getStates(),this.getCities();
    if (this.groupId > 0) {
      this.getDetails();
    }
  }

  
  modifyTimeHr(e){
    if(e > 12){
       var t = parseInt(e)-12;
       if(t < 10)
       return "0"+t.toString();
    else 
      return t.toString();   
    }
    else {
      if(e < 10)
         return "0"+e.toString();
      else 
        return e.toString();
    }
 }

 modifyTimeMin(t){
      if(t < 10)
      return "0"+t.toString();
   else 
     return t.toString();   
}

  showGroups(){
    this.showGroupList = true; this.createGroup = false;
    this.getGroups();
  }
  
  cancelGroup(){
    this.createGroup = false;    this.showGroupList = true;
  }

  getGroups() {
    var obj = { AdminLoginId: this.adminLoginId, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
    console.log(obj);
    this.volunteerService.getAllGroups(obj).subscribe(response => {
      if (response.isSuccess) {
         console.log(response);
        this.groups = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Group":
        if (this.groupIcon == "sorting") {
          this.groupIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.groupIcon == "sorting_asc") {
          this.groupIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.groupIcon == "sorting_desc") {
          this.groupIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.applicantsIcon = "sorting";
        break;
      case "Applicants":
        this.groupIcon = "sorting";
        if (this.applicantsIcon == "sorting") {
          this.applicantsIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.applicantsIcon == "sorting_asc") {
          this.applicantsIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.applicantsIcon == "sorting_desc") {
          this.applicantsIcon = "sorting_asc";
          this.isAscending = true;
        }
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getGroups();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getGroups();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getGroups();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getGroups();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getGroups();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getGroups();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getGroups();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getGroups();
    }
  }

  deleteGroup(group: any, index: number) {
    var obj = { GroupId: group.groupId };
    this.volunteerService.deleteGroup(obj).subscribe(response => {
      if (response.isSuccess) {
        this.groups.splice(index, 1);
      }
    });
  }

  setPushMessage() {
    this.pushmsgSendSuccess = false;
    this.pushmsgValidation = false;
  }

  getFeedbacks(){
    var obj = { adminLoginId:this.adminLoginId, taskId: this.taskId, PageSize: 500, PageNumber: 1 };
    this.feedback.getEventFeedback(obj).subscribe(response => {
      if (response.isSuccess) {
        this.feedbacks = response.list;
      }
    },(err)=>{
      this.toaster.errorToastr("Some error occurred");
    });
  }

  performActionOnKarmaPoints(userId,checked){    
    var obj = {"userLoginId":userId, "taskId":this.taskId, "isKarmaPointsApproved":checked}
    this._service.actionOnKarmaPoints(obj).subscribe(response => {    
        if(response){
          this.getFeedbacks();
          if(checked){
            this.karmaMsg = "karma points for the volunteer have been approved";
          }
          else{
            this.karmaMsg = "karma points for the volunteer have been disapproved";
          }    
          
          let a = document.getElementById("karmaPopupButton") as HTMLElement
          a.click();
        }
    },(err)=>{
      this.toaster.errorToastr("Some error occured");
    })
  }

  onClickEdit(){
    var redirect = "sa/campaigns/public/taskList/" + this.campaignId.toString() + "/taskEdit/" + this.taskId.toString();
    if(this.route.url[1]=="private"){
      redirect = "sa/campaigns/private/taskList/" + this.campaignId.toString() + "/taskEdit/" + this.taskId.toString();
    }
    this.router.navigateByUrl(redirect);
  }

  getTrimmedContent(content:string){
    if(content.length == 0){
      return content = "-"
    }
    else if(content.length > 10){
      return content.substring(0,5) + "...";
    }else{
      return content;
    }
    
  }
  
  viewContent(content:string){
    this.userFeedbackComment = content;
  }
  
  viewMedia(media:any){
    this.feedbackMedia = media;
  }

  onClickReport(userId:any){
    this.userId = userId;
  }

  generateReport() {
    var req = { "taskId": this.taskId }
    this.reportService.generateVolunteerReport(req).subscribe(response => {
      if(response.isSuccess){
        let elem = document.createElement("a");
        elem.target = "_self"; elem.href = response.url; elem.setAttribute("download", response.url);
        elem.click();
      }
    })
  }

  createReverseFeedback(){
    var req = {"taskId":this.taskId,"adminLoginId":this.adminLoginId,"userLoginId":this.userId,"rating":this.rating,"comment":this.feedbackDesc}
    this.feedback.submitReverseFeedback(req).subscribe(response => {
        if(response){
           this.toaster.successToastr("Feedback submit successfully");
           this.getFeedbacks();
        }
    });
  }

  GetAdminFeedback(userId:any){
      var req = {"taskId":this.taskId,"adminLoginId":this.adminLoginId,"userLoginId":userId}
      this.feedback.getAdminFeedback(req).subscribe(response => {
          if(response.isSuccess){
              this.adminFeedback = response.data;          
              var e = document.getElementById("showAdminFeedback") as HTMLElement;
              e.click();
          }
          else{
            this.toaster.errorToastr("Some error occured");
          }
      });
  }
  
  setTabActive(tabname){
    switch(tabname){
      case "detail" : this.detail = true; break;
      case "volunteers" :  this.listVolunteers = true; break;
    }
  }

  ngOnInit() {
    this.getTaskDetails();
    this.createGroupForm();
    this.createVolunteerForm();
    //this.getCountries();
    this.setTabActive(this.activeTab)
  }

}
