import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'group-list',
  templateUrl: './group-list.component.html',
})

export class GroupListComponent implements OnInit {

  adminLoginId: number;
  groups: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  groupIcon: string = "sorting";
  applicantsIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;

  constructor(private _service: AdminVolunteerService, private authentication: AuthenticationService) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
  }



  getGroups() {
    var obj = { AdminLoginId: this.adminLoginId, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
    console.log(obj);
    this._service.getAllGroups(obj).subscribe(response => {
      if (response.isSuccess) {
         console.log(response);
        this.groups = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Group":
        if (this.groupIcon == "sorting") {
          this.groupIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.groupIcon == "sorting_asc") {
          this.groupIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.groupIcon == "sorting_desc") {
          this.groupIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.applicantsIcon = "sorting";
        break;
      case "Applicants":
        this.groupIcon = "sorting";
        if (this.applicantsIcon == "sorting") {
          this.applicantsIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.applicantsIcon == "sorting_asc") {
          this.applicantsIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.applicantsIcon == "sorting_desc") {
          this.applicantsIcon = "sorting_asc";
          this.isAscending = true;
        }
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getGroups();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getGroups();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getGroups();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getGroups();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getGroups();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getGroups();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getGroups();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getGroups();
    }
  }

  deleteGroup(group: any, index: number) {
    var obj = { GroupId: group.groupId };
    this._service.deleteGroup(obj).subscribe(response => {
      if (response.isSuccess) {
        this.groups.splice(index, 1);
      }
    });
  }


  ngOnInit() {
    this.getGroups();
  }
}
