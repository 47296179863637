
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class AdminVolunteerService {

  constructor(private router: Router, private http: HttpClient) { }

  getAllVolunteers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAllBeneficiaries(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetBeneficiaryList', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  
  deleteBeneficiaries(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/UnassignCurriculumFromBeneficiary', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getAllGroups(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetAllGroups', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  addGroup(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/AddGroup', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  searchVolunteer(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/SearchVolunteer', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  searchGroup(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/SearchGroup', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addVolunteer(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/Add', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  performAction(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  performActionBysuperAdmin(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/PerformActionBySuperAdmin', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  deleteGroup(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/DeleteGroup', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  groupDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GroupVolunteers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/Details', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerEventDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/VolunteerEvents', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerCampaignDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/VolunteerCampaigns', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerCsv(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetVolunteerListCsv', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  addVolunteerCsv(obj): Observable<any> {
    console.log(obj);
    const form: FormData = new FormData();
    form.append("AdminLoginId", obj.AdminLoginId.toString());
    if (obj.CorporateId != null) {
      form.append("CorporateId", obj.CorporateId.toString());
    }
    form.append("UploadBy", obj.file);
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/BulkUpload', form).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }

    getCorporates(obj) : Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Corporate/GetCorporates',obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }

    getUserRegistrationStatus(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Master/GetUserRegistrationStatus', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }

    getAllHoursDonated(obj): Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/AdminLogin/GetAdminHoursDetail', obj).pipe(
        tap(res => res),
        catchError(this.handleError),);
    }

    activateUserView(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/Volunteer/ActivateUserViews', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
