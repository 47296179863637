import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventListComponent } from './event/public/event-list.component';
import { EventPrivateListComponent } from './event/private/event-private-list.component';
import { EventPrivateCreateComponent } from './event/private/event-private-create.component';
import { EventDetailComponent } from './event/details/event-detail.component';
import { EventCreateComponent } from './event/public/event-create.component';
import { CampaignListComponent } from './campaign/campaign-list.component';
import { CampaignCreateComponent } from './campaign/campaign-create.component';
import { VolunteersListComponent } from './volunteers/volunteers-list.component';
import { VolunteersCreateComponent } from './volunteers/volunteers-create.component';
import { GroupCreateComponent } from './group/group-create.component';
import { GroupListComponent } from './group/group-list.component';
import { CampaignTaskListComponent } from './campaign/task/campaign-tasklist.component';
import { CampaignTaskCreateComponent } from './campaign/task/campaign-taskcreate.component';
import { CampaignTaskDetailComponent } from './campaign/task/campaign-taskdetail.component';
import { CampaignDetailComponent } from './campaign/campaign-detail.component';
import { AdminProfileComponent } from './profile/admin-profile.component';

import { SurveyListComponent } from './survey/surveyList.component';
import { SurveyCreateComponent } from './survey/create/survey-create.component';
import { SurveyDetailComponent } from './survey/detail/survey-detail.component';
import { UsersListComponent } from './users/users-list.component';
import { UsersCreateComponent } from './users/users-create.component';
import { SurveyUserDetailComponent } from './survey/response/survey-response.component';

import { FeedbackListComponent } from './feedback/feedback-list.component';
import { MediaListComponent } from './media/media-list.component';
import { VolunteersDetailComponent } from './volunteers/volunteers-detail.component';

import { WallPostListComponent } from './wallpost/wallpost-list.component';
import { WallPostCreateComponent} from './wallpost/wallpost-create.component';
import { WallPostDetailComponent} from './wallpost/wallpost-detail.component';

import { NGOCreateComponent} from './ngo/ngo-create.component';
import { NGOListComponent} from './ngo/ngo-list.component';


import { CompaniesCreateComponent} from './masters/companies-create.component';
import { CompaniesListComponent} from './masters/companies-list.component';
import { CountriesListComponent} from './masters/countries-list.component';
import { SupporterLogosComponent} from './masters/supporterlogos.component';



import { PushNotificationComponent} from './pushnotification/pushnotification.component';
import { ReportListComponent} from './report/report-list.component';
import { SkillComponent } from './masters/skill.component';
import { MediaSelectComponent } from './media/select-media/selectMedia.component';
import { SuperAdminDefaultModule } from './default/default.module';
import { DefaultComponent } from './default/default.component';

import { LearningZoneDetailComponent } from './learningzone/learningZone-detail.component';
import { LearningZoneListComponent} from './learningzone/learningzone-list.component';
import { LearningZoneCreateComponent} from './learningzone/learningzone-create.component';

import { EventDetailComponentWithoutPN } from './event/details/event-detailwithoutPN.component';

import { CreateQuizComponent } from './quiz/create-quiz/create-quiz.component';
import { QuizListComponent } from './quiz/quiz-list/quiz-list.component';
import { QuizUserComponent } from './quiz/quiz-user/quiz-user.component';
import { UpdateQuizComponent } from './quiz/update-quiz/update-quiz.component';
import { QuestionListComponent } from './quiz/quiz-question/question-list/question-list.component';
import { CreateQuestionComponent } from './quiz/quiz-question/create-question/create-question.component';
import { UpdateQuestionComponent } from './quiz/quiz-question/update-question/update-question.component';
import { CurriculumComponent } from './campaign/curriculum/curriculum.component';
import { CurriculumListComponent } from './campaign/curriculum/curriculum-list.component';
import { CampaignPublicListComponent } from './campaign/campaign-list-public.component';
import { HoursDonatedComponent} from './hoursdonated/hours-donated.component';
import { BeneficiaryListComponent } from './beneficiary/beneficiary-list/beneficiary-list.component';
import { CampaignBasedOnKindComponent } from './campaign/campaign-based-on-kind/campaign-based-on-kind.component';
import { BirthdayListComponent } from './webusers/birthday-list.component';
import { ContactusListComponent } from './webusers/contactus-list.component';






const routes: Routes = [
  {
    path: '',
    data: {
      title: 'super-admin',
      status: false
    },
    children: [
      {
        path: 'dashboard',
        component: DefaultComponent // './default/default.module#SuperAdminDefaultModule',
      },
      {
        path: 'events/public',
        component: EventListComponent
      },

      {
        path: 'hours',
        component: HoursDonatedComponent
      },
      {
        path: 'events/private',
        component: EventPrivateListComponent
      },
      {
        path: 'events/public/create',
        component: EventCreateComponent
      },
      {
        path: 'events/public/edit/:id',
        component: EventCreateComponent
      },
      {
        path: 'events/private/create',
        component: EventPrivateCreateComponent
      },
      {
        path: 'events/private/edit/:id',
        component: EventPrivateCreateComponent
      },
      {
        path: 'events/detail/:id',
        component: EventDetailComponent
      },
      {
        path: 'events/detail/:id/:volunteer',
        component: EventDetailComponent
      },
      {
        path: 'campaigns/public',
          component: CampaignPublicListComponent
        },
        {
            path: 'campaigns/private',
            component: CampaignListComponent
        },
        {
            path: 'campaigns/campaigns-based-on-kind',
            component: CampaignBasedOnKindComponent
        },
      {
          path: 'campaigns/public/curriculum/:id/:status',
        component: CurriculumListComponent
      },
      {
          path: 'campaigns/public/curriculum/create/:id',
        component: CurriculumComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status',
            component: CurriculumListComponent
        },
        {
            path: 'campaigns/private/curriculum/create/:id',
            component: CurriculumComponent
        },
      {
        path: 'campaigns/public/create/:isPublic',
        component: CampaignCreateComponent
        },
        {
            path: 'campaigns/private/create/:isPublic',
            component: CampaignCreateComponent
        },
      {
        path: 'campaigns/public/detail/:id',
        component: CampaignDetailComponent
        },
        {
            path: 'campaigns/private/detail/:id',
            component: CampaignDetailComponent
        },
      {
          path: 'campaigns/public/taskList/:id',
        component: CampaignTaskListComponent
        },
        {
            path: 'campaigns/private/taskList/:id',
            component: CampaignTaskListComponent
        },
      {
          path: 'campaigns/public/edit/:id',
        component: CampaignCreateComponent
        },
        {
            path: 'campaigns/private/edit/:id',
            component: CampaignCreateComponent
        },
      {
          path: 'campaigns/public/taskList/:campaignid/taskDetail/:taskid',
        component: CampaignTaskDetailComponent
      },
      {
        path: 'campaigns/public/taskList/:campaignid/taskDetail/:taskid/:volunteer',
      component: CampaignTaskDetailComponent
    },
      {
          path: 'campaigns/public/taskList/:id/taskCreate',
        component: CampaignTaskCreateComponent
      },
      {
          path: 'campaigns/public/taskList/:id/taskEdit/:taskid',
        component: CampaignTaskCreateComponent
        },
        {
          path: 'campaigns/private/taskList/:campaignid/taskDetail/:taskid',
          component: CampaignTaskDetailComponent
      },
        {
            path: 'campaigns/private/taskList/:campaignid/taskDetail/:taskid/:volunteer',
            component: CampaignTaskDetailComponent
        },
        {
            path: 'campaigns/private/taskList/:id/taskCreate',
            component: CampaignTaskCreateComponent
        },
        {
            path: 'campaigns/private/taskList/:id/taskEdit/:taskid',
            component: CampaignTaskCreateComponent
        },
      {
        path: 'user/volunteers',
        component: VolunteersListComponent
      },
      {
        path: 'user/beneficiaries',
        component: BeneficiaryListComponent
      },
      {
        path: 'user/volunteers/create',
        component: VolunteersCreateComponent
      },
      {
        path: 'user/volunteers/edit/:id',
        component: VolunteersCreateComponent
      },
      {
        path: 'user/volunteers/detail/:id',
        component: VolunteersDetailComponent
      },
      {
        path: 'webuser/userbirthdays',
        component: BirthdayListComponent
      },
      {
        path: 'webuser/contactus',
        component: ContactusListComponent

      },        
      {
        path: 'masters/groups',
        component: GroupListComponent
      },
      {
        path: 'masters/groups/create',
        component: GroupCreateComponent
      },
      {
        path: 'masters/groups/edit/:id',
        component: GroupCreateComponent
      },
      {
        path: 'profile',
        component: AdminProfileComponent
      },
      {
        path: 'survey',
        component: SurveyListComponent
      },
      {
        path: 'survey/:eventId/create',
        component: SurveyCreateComponent
      },
      {
        path: 'survey/:eventId/edit/:id',
        component: SurveyCreateComponent
      },
      {
        path: 'survey/detail/:id',
        component: SurveyDetailComponent
      },
      {
        path: 'survey/:surveyId/response/:userLoginId',
        component: SurveyUserDetailComponent
      },
      {
        path: 'users',
        component: UsersListComponent
      },
      {
        path: 'users/Add',
        component: UsersCreateComponent
      },
      {
        path: 'users/edit/:id',
        component: UsersCreateComponent
      },
      {
        path: 'feedback',
        component: FeedbackListComponent
      },
      {
        path: 'media',
        component: MediaListComponent
      },
      {
        path: 'eventMedia/:eventId',
        component: MediaSelectComponent
      },
      {
        path: 'taskMedia/:taskId',
        component: MediaSelectComponent
      },
      {
        path: 'newsFeed',
        component: WallPostListComponent
      },
      {
        path: 'newsFeed/create',
        component: WallPostCreateComponent
      },
      {
        path: 'newsFeed/detail/:id',
        component: WallPostDetailComponent
      },
      
      {
        path: 'ngo',
        component: NGOListComponent
      },
      {
        path: 'ngo/Add',
        component: NGOCreateComponent
      },
      {
        path: 'ngo/edit/:id',
        component: NGOCreateComponent
      },
      
      {
        path: 'Companies',
        component: CompaniesListComponent
      }, 

      {
        path: 'Companies/Add',
        component: CompaniesCreateComponent
      }, 
      {
        path: 'Companies/edit/:id/:adminLoginId',
        component: CompaniesCreateComponent
      },

      {
        path: 'masters/Company',
        component: CompaniesListComponent
      }, 
      {
        path: 'masters/Company/Add',
        component: CompaniesCreateComponent
      }, 
      {
        path: 'masters/Company/edit/:id/:adminLoginId',
        component: CompaniesCreateComponent
      },
       {
        path: 'masters/supporterlogos',
        component: SupporterLogosComponent
      },
      {
        path: 'masters/Country',
        component: CountriesListComponent
      },
      {
        path: 'masters/NGO',
        component: NGOListComponent
      },
      {
        path: 'masters/skill',
        component: SkillComponent
      },
      {
        path: 'masters/NGO/Add',
        component: NGOCreateComponent
      },
      {
        path: 'masters/NGO/edit/:id',
        component: NGOCreateComponent
      },
      {
        path: 'settings/pushNotifications',
        component: PushNotificationComponent
      },
      {
        path: 'settings/reports',
        component: ReportListComponent
      },
        {
            path: 'campaigns/public/curriculum/:id/:status/learningZone/:curriculumId',
            component: LearningZoneListComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status/learningZone/:curriculumId',
            component: LearningZoneListComponent
        },
        {
            path: 'campaigns/public/curriculum/:id/:status/learningZone/:curriculumId/create',
            component: LearningZoneCreateComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status/learningZone/:curriculumId/create',
            component: LearningZoneCreateComponent
        },
        {
          path: 'campaigns/public/curriculum/:id/:status/learningZone/:curriculumId/update/:learningZoneId',
          component: LearningZoneCreateComponent
      },
      {
          path: 'campaigns/private/curriculum/:id/:status/learningZone/:curriculumId/update/:learningZoneId',
          component: LearningZoneCreateComponent
      },
        {
            path: 'campaigns/public/curriculum/:id/:status/learningZone/:curriculumId/detail/:learningZoneId',
            component: LearningZoneDetailComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status/learningZone/:curriculumId/detail/:learningZoneId',
            component: LearningZoneDetailComponent
        },
      {
          path: 'campaigns/public/curriculum/:id/:status/quiz/:curriculumId',
        component: QuizListComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status/quiz/:curriculumId',
            component: QuizListComponent
        },
      {
         path:'quiz/create/:id',
         component:CreateQuizComponent
      },
      {
        path:'quiz/quiz-user/:id',
        component:QuizUserComponent
      },
      {
          path:'campaigns/public/curriculum/:id/:status/quiz/:curriculumId/update/:quizId',
        component:UpdateQuizComponent
     },
     {
         path: 'campaigns/public/curriculum/:id/:status/quiz/:curriculumId/question/:quizId',
      component: QuestionListComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status/quiz/:curriculumId/update/:quizId',
            component: UpdateQuizComponent
        },
        {
            path: 'campaigns/private/curriculum/:id/:status/quiz/:curriculumId/question/:quizId',
            component: QuestionListComponent
        },
     {
      path: 'quiz/question/create/:id',
      component: CreateQuestionComponent
     },
     {
         path: 'quiz/question/update/:id',
      component: UpdateQuestionComponent
     },
        {
        path: 'events/detailwithoutpn/:id',
        component: EventDetailComponentWithoutPN
      },
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperadminRoutingModule { }
