import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { LeaderboardService } from '../../../shared/services/leaderboard.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss']
})
export class LeaderboardListComponent implements OnInit {
  adminLoginId: any;
  corporateId: any;
  list:any;
  skillDropdownSetting:{};
  countryDropdownSettting:{};
  stateDropdownSettting:{};
  stateId:any;
  countryId:any;
  skills: any;
  countryList: any;
  stateList: any;
  selectedSkills: any;
  isStateWise:boolean = false;
  isSkillWise:boolean = false;
  count: any;
  startFrom: number;
  pageNumber: number;
  pageSize: number;
  endOn: any;
  nextPageClass: string;
  lastPageClass: string;
  numberOfPages: number;
  firstPageClass: string;
  previousPageClass: string;

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private auth:AuthenticationService,
    private service:LeaderboardService,
    private master:AdminMasterService,
    private toaster:ToastrManager
    ) {
      var details = this.auth.getToken();
      this.adminLoginId = details.access_token.adminLoginId;
      this.corporateId = details.access_token.corporateId;
     }


  getLeaderboardListLocationBase(obj){
   
    this.service.getLeaderBoardListLocationBase(obj)
    .subscribe(res=>{
      console.log(res);
      if(res.isSuccess){
        this.list = res.list;
          this.count = res.totalCount;
          this.setPaging();
      }
    })
  }

  getLeaderboardListSkillsBase(obj){
    this.service.getLeaderBoardListLocationBase(obj)
    .subscribe(res=>{
      if(res.isSuccess){
        this.list = res.list;
          this.count = res.totalCount;
          this.setPaging();
      }
    })
  }

  getPaginatedList(){
    if(this.isSkillWise){
      var requestObj = { "corporateId":this.corporateId,"skills":this.selectedSkills,"pageNumber":1,"pageSize":10 }
      this.getLeaderboardListSkillsBase(requestObj);
    }
    else {
      var Obj = { "corporateId":this.corporateId,"stateId":this.stateId,"pageNumber":1,"pageSize":10 }
      this.getLeaderboardListLocationBase(Obj);
    }
  }

  setPaging(){
    if (this.count == 0) {
      this.startFrom = 0;
  }
  else {
      this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
  }
  if (this.count <= this.pageSize) {
      this.endOn = this.count;
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
  }
  else {
      this.endOn = this.pageNumber * this.pageSize;
      if (this.endOn > this.count) {
          this.endOn = this.count;
      }
  }
  var pages = this.count / this.pageSize;
  this.numberOfPages = 0;
  for (var i = 0; i < pages; i++) {
      this.numberOfPages = i + 1;
  }
  if (this.endOn > this.count) {
      this.endOn = this.count;
  }
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getPaginatedList();
      
    }
  }


  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getPaginatedList();
 
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getPaginatedList();
 
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getPaginatedList();
 
    }
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getPaginatedList();
  }
     
  getSkills(){ 
    this.master.getSkills({"pageNumber":1,"pageSize":500,"corporateId":this.corporateId})
    .subscribe(response => {
      if(response.isSuccess){
        this.skills = response.list;
      }
    })
  }
   
  getCountries(){
    var requestObj = { "pageNumber":1,"pageSize":500 }
    this.master.getCountries(requestObj)
    .subscribe(res => {
      this.countryList = res.data;
    })
  }

  onSelectCountries(event){
    var requestObj = { "countryId":event.countryId,"pageNumber":1,"pageSize":500 }
    this.getStates(requestObj); 
  }

  onSelectState(event){
    this.isStateWise = true;
    var requestObj = { "corporateId":this.corporateId,"stateId":event.stateId,"pageNumber":1,"pageSize":10 }
    this.getLeaderboardListLocationBase(requestObj);
  }

  onSelectSkills(event){
    this.isSkillWise = true;
    var requestObj = { "corporateId":this.corporateId,"skills":event,"pageNumber":1,"pageSize":10 }
    this.getLeaderboardListSkillsBase(requestObj);    
  }

  getStates(obj){
    this.master.getStates(obj)
    .subscribe(res => {     
      this.stateList = res.data;
    })
  }

  ngOnInit() {

    this.getCountries();
    this.countryDropdownSettting = {
      singleSelection: true,
      idField:'countryId',
      textField: 'country',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.stateDropdownSettting = {
      singleSelection: true,
      idField:'stateId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.getSkills();
    this.skillDropdownSetting = {
      singleSelection: false,
      idField:'skill',
      textField: 'skill',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    var obj = { "corporateId":this.corporateId,"stateId":0,"pageNumber":1,"pageSize":10 }
    this.getLeaderboardListLocationBase(obj);
  }


}
