import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service'
import { TestimonialService } from '../../../shared/services/testimonial.service'
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-testimonial-list',
  templateUrl: './testimonial-list.component.html',
  styleUrls: ['./testimonial-list.component.scss']
})
export class TestimonialListComponent implements OnInit {

  adminLoginId:number;
  pageSize:number = 10;
  pageNumber:number = 1;
  testimonialsList:any;
  searchTerm: string;
  count: number = 0;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  isSearchEnabled: boolean;


  constructor(private authentication:AuthenticationService,private service:TestimonialService,private toaster:ToastrManager) {
      var auth = authentication.getToken();
      if (auth == null) {
          this.authentication.logout(); return;
      } else {
          var login = auth.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = auth.access_token.adminLoginId;
   }



  ngOnInit() {
    this.getTestimonialList();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
    this.getTestimonialList();
    }
}

  firstPage() {
    if (this.pageNumber > 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.pageNumber = 1;
        this.getTestimonialList();
    }
}

lastPage() {
    if (this.pageNumber < this.numberOfPages) {
        this.firstPageClass = "";
        this.previousPageClass = "";
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
        this.pageNumber = this.numberOfPages;
        this.getTestimonialList();
    }
}

previousPage() {
    if (this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
        if (this.pageNumber == 1) {
            this.firstPageClass = "disabled";
            this.previousPageClass = "disabled";
            this.nextPageClass = "";
            this.lastPageClass = "";
        }
        this.getTestimonialList();
    }
}

nextPage() {
    if (this.pageNumber < this.numberOfPages) {
        this.pageNumber = this.pageNumber + 1;
        this.firstPageClass = "";
        this.previousPageClass = "";
        if (this.pageNumber == this.numberOfPages) {
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
        }
        this.getTestimonialList();
    }
}

removeSearch(){
  this.searchTerm = '';
  this.isSearchEnabled = false;
  this.getTestimonialList();
}
applySearch() {
  if(this.searchTerm == undefined || this.searchTerm == null || this.searchTerm.trim() == ''){
     this.toaster.errorToastr("Enter search term");
  }
  else{    
      this.getFilteredList();
      this.isSearchEnabled = true;
  }        
}
keyDownHandler(event) {
  if (event.target.value.length > 0) {
    return true;
  } else {
    if (event.keyCode === 32) {
      return false;
    }
  }
}

getFilteredList() {
  this.pageNumber = 1;
  this.firstPageClass = "disabled";
  this.previousPageClass = "disabled";
  this.nextPageClass = "";
  this.lastPageClass = "";
  this.getTestimonialList();
}

changePageSize(pageSize: number) {
  this.pageSize = pageSize;
  this.pageNumber = 1;
  this.firstPageClass = "disabled";
  this.previousPageClass = "disabled";
  this.nextPageClass = "";
  this.lastPageClass = "";
  this.getTestimonialList();
  
}

getTestimonialList(){
  var obj = { "AdminLoginId": this.adminLoginId, "PageSize": this.pageSize, "PageNumber": this.pageNumber, "SearchTerm": this.searchTerm }
  this.service.getTestimonial(obj).subscribe(response => {
    console.log(response);
    if (response.isSuccess) {
      this.testimonialsList = response.list;
      this.count = response.count; 
      if (this.count == 0) {
        this.startFrom = 0;
      }
      else {
        this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
      }
      if (this.count <= this.pageSize) {
        this.endOn = this.count;
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      else {
        this.endOn = this.pageNumber * this.pageSize;
        if (this.endOn > this.count) {
          this.endOn = this.count;
        }
      }
      var pages = this.count / this.pageSize;
      this.numberOfPages = 0;
      for (var i = 0; i < pages; i++) {
        this.numberOfPages = i + 1;
      }
      if (this.endOn > this.count) {
        this.endOn = this.count;
      }
    }
  })
}

performAction(id:number) {
  var obj = {"AdminLoginId":this.adminLoginId, "TestimonialId": id};
  this.service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
          this.getTestimonialList();
      }
  });
}
 

}
