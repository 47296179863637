import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FeedbackService } from '../../shared/services/feedback.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'feedback-list',
  templateUrl: './feedback-list.component.html',
})

export class FeedbackListComponent implements OnInit {

  adminLoginId: number;
  events: any[] = [];
  tasks: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 500;
  searchTerm: string;
  count: number;
  taskCount: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  isAscending: boolean = false;
  sortColumn: string;
  showFeedback: boolean = false;
  feedbacks: any[];
  selectedEvent: number = 0;
  selectedTask: number = 0;
  isTask: boolean = false;
  isEvents: boolean = true;
  isTasks: boolean = false;
  userFeedbackComment: string;
  feedbackMedia: any;

  constructor(private _service: FeedbackService, private authentication: AuthenticationService) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
  }



  getEvents() {
    var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn, IsTask: this.isTask };
    console.log(obj);
    this._service.getAllSuperAdminEvents(obj).subscribe(response => {
      if (response.isSuccess) {
        this.events = response.list;
        this.tasks = response.taskList;
        this.count = response.count;
        this.taskCount = response.taskCount;
        if (!this.isTask) {
          if (this.count == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.count <= this.pageSize) {
            this.endOn = this.count;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
            this.endOn = this.count;
          }
        }
        else {
          if (this.taskCount == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.taskCount <= this.pageSize) {
            this.endOn = this.taskCount;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
          }
          var pages = this.taskCount / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.taskCount) {
            this.endOn = this.taskCount;
          }
        }
      }
    });
  }

  showTaskFeedback() {
    this.showFeedback = true; this.isTasks = true; this.isEvents = false; this.feedbacks = [];
    var obj = { IsTasks: this.isTasks, PageSize: 500, PageNumber: 1 };
    this._service.getAllFeedback(obj).subscribe(response => {
      if (response.isSuccess) {
    
        this.feedbacks = response.list;
       // this.selectedTask = task.taskId;
      }
    });
  }

  showEventFeedback() {
    this.showFeedback = true; this.isTasks = false; this.isEvents = true; this.feedbacks = [];
    var obj = { IsEvents:this.isEvents, PageSize: 500, PageNumber: 1 };
    this._service.getAllFeedback(obj).subscribe(response => {
      if (response.isSuccess) {
        this.feedbacks = response.list;
       // this.selectedEvent = evnt.eventId;
      }
    });
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;

    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
    this.feedbacks = null;
    this.selectedEvent = 0;
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
    this.feedbacks = null;
    this.selectedEvent = 0;
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  setStyle(evnt: any) {
    if (this.selectedEvent == evnt.eventId) {
      return "#aac6ef";
    }
    else {
      return "";
    }
  }

  setStyleForTask(task: any) {
    if (this.selectedTask == task.taskId) {
      return "#aac6ef";
    }
    else {
      return "";
    }
  }

  showEvents() {
    this.showFeedback = false;
    this.pageNumber = 1;
    this.pageSize = 500;
    this.isTask = false;
    this.getEvents();
  }

  showTasks() {
    this.showFeedback = false;
    this.pageNumber = 1;
    this.pageSize = 500;
    this.isTask = true;
    this.getEvents();
  }

  getTrimmedContent(content:string){
    if(content.length == 0){
      return content = "-"
    }
    else if(content.length > 10){
      return content.substring(0,5) + "...";
    }else{
      return content;
    }
    
  }

  viewContent(content:string){
    this.userFeedbackComment = content;
  }

  viewMedia(media:any){
    this.feedbackMedia = media;
  }

  

  ngOnInit() {
    //this.getEvents();
    this.showEventFeedback();
  }
}
