import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { PushNotificationService } from '../../shared/services/pushNotification.service';
//import { DISABLED } from '@angular/forms/src/model';



@Component({
  selector: 'pushnotification',
  templateUrl: './pushnotification.component.html',
})
 
export class PushNotificationComponent implements OnInit {

  PNForm: FormGroup;
  successMessage: string;
  errorMessage: string
  states: any[] = [];
  corporates: any[] = [];
  
  validateForm: boolean = false;
  disabled: boolean = false;
  test:string;
  adminLoginId: any;
  groups: any[] = [];
  groupsSelected: any[] = [];
  groupDropdownSettings: {};
  disableGroup: boolean = false;
  countries: any;
  statesCountryWise: any;
  countryId:any;

    constructor(private pnService: PushNotificationService, private masterService: AdminMasterService, private _service: AuthenticationService,private groupService: AdminVolunteerService) {
        var details = this._service.getToken();
        if (details == null) {
            this._service.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this._service.logout(); return;
            }
        }

        this.adminLoginId = details.access_token.adminLoginId;
    }

  createForm() {
    this.PNForm = new FormGroup({
      message: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      stateId: new FormControl(''),
      allUsers: new FormControl(false),
      corporateId: new FormControl('')
    });
  }

  

  save() {
    this.validateForm = true;
    this.errorMessage = '';
    this.successMessage = '';
    if (this.PNForm.valid) {

      if (this.PNForm.value.message.trim() == ""){
        this.errorMessage = "Please enter the message";
      }
      else if (this.PNForm.value.stateId == "" && this.PNForm.value.corporateId == "" && (this.groupsSelected==null || this.groupsSelected.length == 0)){
         this.errorMessage = "Please select either state or corporate or group";
      }
    //else if(this.PNForm.value.corporateId == "")
    //  {
    //  this.errorMessage = "Please select the Corporate";

    //  }
    else
    {

      var pnForm = this.PNForm.value;
      var admin = {"AdminLoginId": this.adminLoginId};

      var grpsSelected = [];
      this.groupsSelected.forEach(element => {
        grpsSelected.push(element.groupId);
      });

      var groups = {"GroupsId": grpsSelected};
      var obj = {...admin,...pnForm,...groups}
       this.pnService.sendPN(obj).subscribe(response => {
     
       this.PNForm.get('stateId').enable();
       this.PNForm.get('corporateId').enable();
       this.disabled = false;

       if (response.isSuccess) {
           this.validateForm = false;
          this.createForm();
          this.successMessage = "Push notification sent successfully";
          this.errorMessage = '';

        }
        else{
          this.errorMessage =  "Volunteer not available in the selected combination";
          this.successMessage = '';
       }

       

      });
    }
   
    }
  }

  // getStates() {
  //   var obj = { pageNumber: 1, PageSize: 500, CountryId:0 };
  //   this.masterService.getStates(obj).subscribe(response => {
  //       this.states = response.data;
  //       console.log(response.data);
  //   });
  // }

  getCountries(){
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
        this.countries = response.data;
        this.countryId='';
    });

  }

  FilterStateByCountryId(countryId: number) {
      
    var obj = { pageNumber: 1, PageSize: 500,CountryId:countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.statesCountryWise = response.data;
    });
}

  getCorporates() {
    var obj = { pagenumber: 1, pagesize: 500 };
    this._service.getAllCompanies(obj).subscribe(response => {
      if (response.isSuccess) {
        this.corporates = response.list;
      }
    });
  }

  changeStatus() {
    if (!this.disabled) {
      this.PNForm.get('stateId').disable();
      this.PNForm.get('corporateId').disable();
      this.disabled = true;
    }
    else {
      this.PNForm.get('stateId').enable();
      this.PNForm.get('corporateId').enable();
      this.disabled = false;
    }
  }
  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  getGroups() {
    var obj = { AdminLoginId: this.adminLoginId, PageNumber: 1, PageSize: 1000 };  
    this.groupService.getAllGroups(obj).subscribe(response => {
      if (response.isSuccess) {
       //  console.log(response);
        this.groups = response.list;
      }
    });
  }

  ngOnInit() {
    this.createForm();
    //this.getStates();
    this.getCountries();
    this.getCorporates();
    this.getGroups();

    this.groupDropdownSettings = {
      singleSelection: false,
      idField:'groupId',
      textField: 'groupName',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll:true,
      selectAllText:"Select All"
    };
  }

  disableGroupHandler() {
    if (this.PNForm.value.corporateId) {
      this.groupsSelected = []
      this.disableGroup = true
    }else {
      this.disableGroup = false
    }
  }
}
