import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuizService } from '../../../shared/services/quiz.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { Location } from '@angular/common';



@Component({
  selector: 'app-create-quiz',
  templateUrl: './create-quiz.component.html',
  styleUrls: ['./create-quiz.component.scss']
})
export class CreateQuizComponent implements OnInit {
  form: any;
  adminLoginId: any;
  learningZoneId: any;
  validateForm: boolean;
  setQuizTime:boolean = false;

  startDateOptions: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
  };
  endDateOptions: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
  };
    curriculumId: any;

  constructor(
    private router:Router,
    private activeRoute:ActivatedRoute,
    private fb:FormBuilder,
    private service:QuizService,
    private auth:AuthenticationService,
    private toaster:ToastrManager,
    private location: Location

    ) { 
      var details = this.auth.getToken();
      if (details == null) {
          this.auth.logout(); return;
      } 
      this.adminLoginId = details.access_token.adminLoginId;
      this.curriculumId = this.activeRoute.snapshot.params['id'];
    }

    navigateBack() {
      // window.history.back();
      this.location.back();
  
    }

  createQuiz(formValue){  
    
      var obj = { "adminLoginId": this.adminLoginId, "curriculumId": this.curriculumId }
      var reqObj = { ...formValue, ...obj }
      this.service.callCreateQuizService(reqObj)
      .subscribe(res => {
         if(res.isSuccess){
           this.toaster.successToastr(res.responseMessage)
             this.navigateBack();
         }
         else {
             this.toaster.errorToastr("Some Error Occured")
         }
      }, (err) => {
              this.toaster.errorToastr("Some Error Occured")
      })     
  }

  save(){
    this.validateForm = true;
    
    if(this.form.valid){
      var formValue = this.form.value;
      this.createQuiz(formValue);
    }
  }

  get f() {
    return this.form.controls;
  }

  changePeriod(event){
   var v = event.target.value;
   if(v=="1"){
     this.setQuizTime = false;
     this.form.get("startDate").setValue(null);
     this.form.get("endDate").setValue(null);
   }
   else{
     this.setQuizTime = true;
     this.form.get("startDate").setValue(new Date());
     this.setEndDateOptions(this.form.controls.startDate.value);
   }
  }

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
  }

  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  setEndDateOptions(event){
    this.form.get("endDate").setValue(event);
    this.endDateOptions = {
      minDate: new Date((new Date().setDate((new Date(event).getDate() - 1))))
    }
  }

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  ngOnInit() {
    this.form = this.fb.group({
      quizName:['',[Validators.required, this.whiteSpaceValidator]],
        maxAttemptAllowed: ['', [Validators.required]],
      //startDate:null,
      //endDate:null
    });
  }
}
