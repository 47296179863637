import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { PushNotificationService } from '../../shared/services/pushNotification.service';



@Component({
  selector: 'pushnotification',
  templateUrl: './pushnotification.component.html',
})

export class PushNotificationComponent implements OnInit {

  PNForm: FormGroup;
  successMessage: string;
  errorMessage: string;
  states: any[] = [];
  corporates: any[] = [];
  validateForm: boolean = false;
  corporateId: number;
  disabled: boolean = false;
  groups: any[] = [];
  groupsSelected: any[] = [];
  groupDropdownSettings: {};
  adminLoginId: any;
  countries: any;
  statesCountryWise: any;
  countryId:any;

  constructor(private pnService: PushNotificationService, private masterService: AdminMasterService, private _service: AuthenticationService, private authentication: AuthenticationService,private groupService: AdminVolunteerService) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.corporateId = details.access_token.corporateId;
  }

  createForm() {
    this.PNForm = new FormGroup({
        message: new FormControl('', [Validators.required,this.whiteSpaceValidator] ),
      stateId: new FormControl(''),
      allUsers: new FormControl(false),
      corporateId: new FormControl(this.corporateId)
    });
  }

  save() {
    this.validateForm = true;
    this.errorMessage = '';
    this.successMessage = '';
    if (this.PNForm.valid) {

      if (this.PNForm.value.message.trim() == ""){
        this.errorMessage = "Please enter the message";
      }
      else if (this.PNForm.value.stateId == "" && (this.groupsSelected==null || this.groupsSelected.length == 0)){
         this.errorMessage = "Please select either state or group";
      }
    //else if(this.PNForm.value.corporateId == "")
    //  {
    //  this.errorMessage = "Please select the Corporate";

    //  }
    else
    {

      var pnForm = this.PNForm.value;
      var admin = {"AdminLoginId": this.adminLoginId,"CorporateId":this.corporateId};

      var grpsSelected = [];
      this.groupsSelected.forEach(element => {
        grpsSelected.push(element.groupId);
      });

      var groups = {"GroupsId": grpsSelected};
      var obj = {...admin,...pnForm,...groups}
       this.pnService.sendPN(obj).subscribe(response => {
     
       this.PNForm.get('stateId').enable();
     //  this.PNForm.get('corporateId').enable();
       this.disabled = false;

       if (response.isSuccess) {
           this.validateForm = false;
          this.createForm();
          this.successMessage = "Push notification sent successfully";
          this.errorMessage = '';

        }
        else{
          this.errorMessage =  "Volunteer not available in the selected combination";
          this.successMessage = '';
       }

       

      });
    }
   
    }
  }

  // save() {
  //   this.validateForm = true;
  //   this.errorMessage =  '';
  //   this.successMessage =  '';
  //   if (this.PNForm.valid) {
  //     if(this.PNForm.value.message.trim() == ""){
  //       this.errorMessage = "Please enter the message";
  //     }
  //     else if(this.PNForm.value.stateId == ""){
  //       this.errorMessage = "Please select the state";
  //     }
  //     else
  //     {
  //         this.pnService.sendPN(this.PNForm.value).subscribe(response => {
  //             this.PNForm.get('stateId').enable();
  //             this.disabled = false;
  //             if (response.isSuccess) {
  //                 this.validateForm = false;
  //         this.createForm();
  //         this.successMessage = "Push notification sent successfully";
       
  //         this.errorMessage =  '';
  //       }
  //       else{

  //         this.successMessage = '';
  //         this.errorMessage =  "Volunteer not available in the selected combination";
  //       }
  //     });
  //     }
  //   }
  // }

  // getStates() {
  //   var obj = { pageNumber: 1, PageSize: 500, CountryId: 0 };
  //   this.masterService.getStates(obj).subscribe(response => {
  //     this.states = response.data;
  //   });
  // }

  getCountries(){
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
        this.countries = response.data;
        this.countryId='';
    });

  }

  FilterStateByCountryId(countryId: number) {
      
    var obj = { pageNumber: 1, PageSize: 500,CountryId:countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.statesCountryWise = response.data;
    });
}

  changeStatus() {
    if (!this.disabled) {
      this.PNForm.get('stateId').disable();
      this.disabled = true;
    }
    else {
      this.PNForm.get('stateId').enable();
      this.disabled = false;
    }
  }
  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  getGroups() {
    var obj = { AdminLoginId: this.adminLoginId,CorporateId:this.corporateId, PageNumber: 1, PageSize: 1000 };  
    this.groupService.getAllGroups(obj).subscribe(response => {
      if (response.isSuccess) {
       //  console.log(response);
        this.groups = response.list;
      }
    });
  }

  ngOnInit() {
    this.createForm();
    //this.getStates();
    this.getCountries();
    this.getGroups();

    this.groupDropdownSettings = {
      singleSelection: false,
      idField:'groupId',
      textField: 'groupName',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll:true,
      selectAllText:"Select All"
    };
  }
}
