import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';


@Component({
    selector: 'hours-donated',
    templateUrl: './hours-donated.component.html',
  })


  export class HoursDonatedComponent implements OnInit {

    adminLoginId: number;
    volunteers: any[] = [];
    pageNumber: number = 1;
    pageSize: number = 10;
    searchTerm: string;
    count: number;
    startFrom: number = 1;
    endOn: number = this.pageSize;
    numberOfPages: number;
    lastPageClass: string;
    firstPageClass: string = "disabled";
    previousPageClass: string = "disabled";
    nextPageClass: string;
    corporateId: any;
    corporates: any;
   
  
    constructor(private _service: AdminVolunteerService, private authentication: AuthenticationService) {
      var details = this.authentication.getToken();
      if(details == null){
        this.authentication.logout(); return;
      } else {
        var login = details.access_token;
        if(login.role==2){
          this.authentication.logout(); return;
        }
      }
      this.adminLoginId = details.access_token.adminLoginId;
    
    }
  
  
  
    getVolunteers() {
        var obj = {
            AdminLoginId:this.adminLoginId, PageNumber: this.pageNumber, PageSize: this.pageSize,
        };
      this._service.getAllHoursDonated(obj).subscribe(response => {
        if (response.isSuccess) {
          this.volunteers = response.list;
          console.log(response.list);
          this.count = response.totalCount;
          if (this.count == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.count <= this.pageSize) {
            this.endOn = this.count;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
            if (this.endOn > this.count) {
              this.endOn = this.count;
            }
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
            this.endOn = this.count;
        }
        }
      });
    }
  
  
    firstPage() {
      if (this.pageNumber > 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.pageNumber = 1;
        this.getVolunteers();
      }
    }
  
    lastPage() {
      if (this.pageNumber < this.numberOfPages) {
        this.firstPageClass = "";
        this.previousPageClass = "";
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
        this.pageNumber = this.numberOfPages;
        this.getVolunteers();
      }
    }
  
    previousPage() {
      if (this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
        if (this.pageNumber == 1) {
          this.firstPageClass = "disabled";
          this.previousPageClass = "disabled";
          this.nextPageClass = "";
          this.lastPageClass = "";
        }
        this.getVolunteers();
      }
    }
  
    nextPage() {
      if (this.pageNumber < this.numberOfPages) {
        this.pageNumber = this.pageNumber + 1;
        this.firstPageClass = "";
        this.previousPageClass = "";
        if (this.pageNumber == this.numberOfPages) {
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        this.getVolunteers();
      }
    }
  
 
    changePageSize(pageSize: number) {
      this.pageSize = pageSize;
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getVolunteers();
    }
  
    checkIfBoxEmpty() {
      if (this.searchTerm == '' || this.searchTerm == null) {
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getVolunteers();
      }
    }
  
      ngOnInit() {
  
          this.getVolunteers();
          this.corporateId = '';
          
      }
  }
