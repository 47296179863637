import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuizService } from '../../../shared/services/quiz.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'app-update-quiz',
  templateUrl: './update-quiz.component.html',
  styleUrls: ['./update-quiz.component.scss']
})
export class UpdateQuizComponent implements OnInit {
  adminLoginId: any;
  quizId: any;
  form: any;
  validateForm: boolean;
  setQuizTime:boolean = false;

  startDateOptions: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
  };
  endDateOptions: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
  };

  constructor(
    private router:Router,
      private activeRoute: ActivatedRoute,
      private location: Location,
    private fb:FormBuilder,
    private service:QuizService,
    private auth:AuthenticationService,
    private toaster:ToastrManager
    ) { 
     
      var details = this.auth.getToken();
      if (details == null) {
          this.auth.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.auth.logout(); return;
          }
      }
      this.adminLoginId = details.access_token.adminLoginId;
      this.quizId = this.activeRoute.snapshot.params['quizId'];
    }


    createQuiz(formValue){  
    
      var obj = {"adminLoginId":this.adminLoginId,"quizId":this.quizId}
      var reqObj = { ...formValue, ...obj }
      this.service.callCreateQuizService(reqObj)
      .subscribe(res => {
         if(res.isSuccess){
             this.toaster.successToastr("Assessment Update Successfully")
             this.navigateBack();
         }
         else{
          this.toaster.successToastr("Some Error Occured")
         }
      })     
  }

  save(){
    this.validateForm = true;
    
    if(this.form.valid){
      var formValue = this.form.value;
      this.createQuiz(formValue);
    }
  }

  get f() {
    return this.form.controls;
  }

  changePeriod(event){
   var v = event.target.value;
   if(v=="1"){
     this.setQuizTime = false;
     this.form.get("startDate").setValue(null);
     this.form.get("endDate").setValue(null);
   }
   else{
     this.setQuizTime = true;
      this.form.get("startDate").setValue(new Date());
      this.setEndDateOptions(this.form.controls.startDate.value);
   
   }
  }

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
  }

    navigateBack() {
        this.location.back();
  }

  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  setEndDateOptions(event){
    this.form.get("endDate").setValue(event);
    this.endDateOptions = {
      minDate: new Date((new Date().setDate((new Date(event).getDate() - 1))))
    }
  }


   getQuiz(){
    var reqObj = {"adminLoginId":this.adminLoginId,"quizId":this.quizId}
    this.service.callGetQuizService(reqObj)
    .subscribe(res => {
      console.log(res);
       if(res.isSuccess){
         this.setValueToControls(res.quiz);
       }
    })
   }

   setValueToControls(data){
     if(data!=null){
       this.setQuizTime = data.startDate != null ? true : false;
       this.form.setValue({
          quizName:data.quizName,
          maxAttemptAllowed:data.maxAttemptAllowed,
          startDate:data.startDate,
          endDate:data.endDate
       })

       this.setEndDateOptions(data.endDate);
     }
   }
   
  ngOnInit() {
    this.form = this.fb.group({
      quizName:['',[Validators.required]],
      maxAttemptAllowed:[''],
      startDate:null,
      endDate:null
    });
  
    if(this.quizId!=null && this.quizId!=''){
        this.getQuiz();
    }
  }

}
