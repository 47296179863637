import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'app-curriculum-list',
  templateUrl: './curriculum-list.component.html',
})
export class CurriculumListComponent implements OnInit {
  adminLoginId: any;
  corporateId: any;
  campaignId: any;
  curriculums: any;
    curriculumTitle: any = '';
    errorMessage: string;
    successMessage: string;
    isVolunteer: boolean = true;
    isBeneficiary: boolean = false;
    campaignStatusId: number;
    invalidEntry: boolean;
    curriculumId: any;
    campaign: any;
    campaignTitle: any;
  isBoth: boolean = false;
    


  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private auth:AuthenticationService,
    private service:AdminCampaignService,
    private toaster:ToastrManager
    ) { 
      var details = this.auth.getToken();
      if (details == null) {
          this.auth.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.auth.logout(); return;
          }
      }
      this.adminLoginId = details.access_token.adminLoginId;
      this.corporateId = details.access_token.corporateId;
      this.campaignId = this.route.snapshot.params['id'];
      this.campaignStatusId = this.route.snapshot.params['status'];
    }

    getCurriculum(){
        this.service.getCurriculum({"CampaignId":this.campaignId})
        .subscribe(res => {
          if(res.isSuccess){
              this.curriculums = res.list;
              this.campaign = res.campaign;
              this.campaignTitle = this.campaign != null ? this.campaign.title : '';
          }
        })
    }

    performDelete(event){
       this.service.performDelete({"LearningZoneCurriculumId":event})
       .subscribe(res => {
         if(res.isSuccess){
           this.toaster.successToastr("Curriculum deleted successfully");
           this.getCurriculum();
         }
       },(err)=>{
         this.toaster.errorToastr("Some error occurred");
       })
    }

    setActive(event){
      this.service.activateCurriculum({"LearningZoneCurriculumId":event,"campaignId":this.campaignId})
      .subscribe(res => {
        if(res.isSuccess){
          this.toaster.successToastr("Curriculum is activated");
          this.getCurriculum();
        } else {
            res.responseMessage != '' ? this.toaster.errorToastr(res.responseMessage) : "";
        }
      },(err)=>{
        this.toaster.errorToastr("Some error occurred");
      })
    }

    setInActive(event){
      this.service.deactivateCurriculum({"LearningZoneCurriculumId":event})
      .subscribe(res => {
        if(res.isSuccess){
          this.toaster.successToastr("Curriculum is deactivated");
          this.getCurriculum();
        } else {
            res.responseMessage != '' ? this.toaster.errorToastr(res.responseMessage) : "";
        }
      },(err)=>{
        this.toaster.errorToastr("Some error occurred");
      })
    }
    createCurriculum() {

        if (this.curriculumTitle == null || this.curriculumTitle == '') {
            this.errorMessage = "Enter curriculum name";
            return;
        }

        var request = { "campaignId": this.campaignId, "title": this.curriculumTitle, "isBeneficiary": this.isBeneficiary, "isUser": this.isVolunteer,"isBoth":this.isBoth }

        this.service.createCurriculum(request)
            .subscribe(res => {
                if (res.isSuccess) {
                    this.getCurriculum();
                    this.toaster.successToastr("Curriculum created successfully");
                  
                } else {
                    this.toaster.errorToastr("Some error occured");
                }
                this.clear();
            }, (err) => {
                    this.toaster.errorToastr("Some error occured");
                    this.clear();
            });
    }

    onSelectVolunteer() {
        this.isVolunteer = true;
        this.isBeneficiary = false;
        this.isBoth = false;
    }
    onSelectBeneficiary() {
        this.isBeneficiary = true;
        this.isVolunteer = false;
        this.isBoth = false;
    }
    onSelectBoth(){
      this.isBeneficiary = false;
      this.isVolunteer = false;
      this.isBoth = true;
    }
    checkError() {
        this.invalidEntry = false;
        if (this.curriculumTitle.trim() == '') {
            this.invalidEntry = true;
        }
    }
    onClickCreate() {
        this.errorMessage = "";
        this.successMessage = "";
    }
    clear() {
        this.curriculumTitle = "";
        this.isVolunteer = true;
        this.isBeneficiary = false;
    }
    navigateBack() {
        var routeParams = this.route.snapshot.url;
        var route = "/sa/" + routeParams[0].path + "/" + routeParams[1].path
        this.router.navigateByUrl(route);
    }

  ngOnInit() {
     this.getCurriculum();
  }

}
