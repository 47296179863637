
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class AdminMasterService {

  constructor(private router: Router, private http: HttpClient) { }


  getStates(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/States', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getCities(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/Cities', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getCountries(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/Countries', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
    addCountry(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/AdminSelectCountry', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
   addState(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/AdminSelectState', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
    addCity(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/AdminSelectCity', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
   deleteCity(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/DeleteCity', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getGoals(): Observable<any> {
    return this.http.get<any>(environment.apiBasePath + '/Master/Goals').pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
   deleteState(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/DeleteState', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteCountry(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/DeleteCountry', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSkills(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/GetSkills', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  updateSkill(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/UpdateSkill', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  deleteSkill(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/DeleteSkill', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addUpdateAdminSkill(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/UpdateSkillByAdmin',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getSkillsByAdmin(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/GetSkills',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  updateSkillStatusByAdmin(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/UpdateSkillStatusByAdmin',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getMasterSections():Observable<any> {
    return this.http.get(environment.apiBasePath + '/Master/GetMasterSections').pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  addUpdateSections(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/UpsertCorporateSection',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getCorporateSections(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/GetCorporateSection',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  addDomain(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/AddDomain',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getDomain(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/GetDomain',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  performActionOnDomain(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/PerformActionOnDomain',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }



  getThematicAreas(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/ThematicAreas', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

 addsupporterlogo(obj): Observable<any> {
   console.log("my OBJECT");
   console.log(obj);
    const formData: FormData = new FormData();
    formData.append('SponsoredMediaId', obj.SponsoredMediaId);
    formData.append('UploadedImage', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/SponsoredMedia/UploadImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
 
   getsupporterlogo(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/SponsoredMedia/GetSponsoredMedia', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }



  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
