import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
//import { IfStmt } from '@angular/compiler';
import { DatepickerOptions } from 'ng2-datepicker';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as _moment from 'moment';
import { async } from '@angular/core/testing';





@Component({
  selector: 'companies-create',
  templateUrl: './companies-create.component.html',
})

export class CompaniesCreateComponent implements OnInit {


  email: string;
  password: string;
  errorMessage: string;
  registerForm: FormGroup;
  validateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  corporateimageUrl: string;
  corprateLogofile: any;
  corporateLicenseUrl: string;
  corprateLicensefile: any;
  corporateId: number;
  adminLoginId: number;
  uploadedLicense: any;
  licenseName:string;
  comment: string = '';
  corporatename: string ;
  corporateslug: string ;
  NGOs: any[] = [];
  selectedNGOs:any[] = [];
  ngoDropdownSettings: {};
  loading : any;

  

  options: DatepickerOptions = {
    maxDate: new Date(Date.now()), // Minimal selectable date
    displayFormat: 'YYYY',
  };
    isClientCreated: boolean;

    constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute,private ngoService: MasterNGOService, private toaster: ToastrManager) {

        var details = this._service.getToken();
        if (details == null) {
            this._service.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this._service.logout(); return;
            }
        }
    router.events.subscribe((response) => {
      this.corporateId = route.snapshot.params['id'];
      this.adminLoginId = route.snapshot.params['adminLoginId'];

    });
  }

  createForm() {
    
    this.registerForm = new FormGroup({
      adminEmail: new FormControl('', [Validators.required, Validators.email, this.whiteSpaceValidator]),
      adminFirstName: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      adminLastName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      adminMobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      location: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      area: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      zipcode: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      cityId: new FormControl('', Validators.required),
      stateId: new FormControl('', Validators.required),
      countryId: new FormControl('', Validators.required),
      corporateName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
    //  corporateRegistrationDate: new FormControl(_moment(new Date(Date.now())).format('YYYY'), Validators.required),
      corporateEmail: new FormControl('', [Validators.required, Validators.email,this.whiteSpaceValidator]),
      corporateMobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      volunteeringInchargeName: new FormControl('',[Validators.required, this.whiteSpaceValidator]),
      corporateKey: new FormControl('',[Validators.required,this.whiteSpaceValidator]),
      corporateLogo: new FormControl(''),
      licenses: new FormControl(''),
      registrationYear:new FormControl('',Validators.required)
    //  ngos: new FormControl([],Validators.required),
    });
  }
  register() {
    if (this.corporateId > 0) {
      this.UpdateCorporateProfile();
    }
    else {
      this.saveData();
    }

  }

  saveData() {
    this.registerForm.value.corporateKey = this.registerForm.value.corporateKey.split(' ').join(''); 
    this.validateForm = true;
    this.errorMessage = '';
    this.corporateslug =  this.registerForm.value.corporateKey;
    this.corporatename =  this.registerForm.value.corporateName;

    //  let registrationDate = new Date(this.registerForm.value.corporateRegistrationDate).toUTCString();
    //  let registrationDateLine = new Date(registrationDate);
    //  registrationDateLine.setHours(registrationDateLine.getHours() + 5);
    //  registrationDateLine.setMinutes(registrationDateLine.getHours() + 30);
    //  this.registerForm.value.corporateRegistrationDate = registrationDateLine;

    if (this.registerForm.valid) {
      this.loading = true;

       if(this.registerForm.value.corporateKey == null || this.registerForm.value.corporateKey.trim() == ''){

        this.toaster.errorToastr('Slug cannot be empty');
        return;
        }
        this.isClientCreated = true;
        var formval = this.registerForm.value;
        var slug = { "corporateKey": this.registerForm.value.corporateKey };
        this._service.register({ ...formval,...slug }).subscribe(response =>
        {

            if (response.isSuccess) {

                if (this.corprateLogofile != null && this.corprateLogofile != undefined) {
                    // upload image
                    var obj = { CorporateId: response.data.corporateId, file: this.corprateLogofile };
                    this._service.addCorporateLogo(obj).subscribe(response => {
                        if (response.isSuccess) {
                            this.corporateimageUrl = response.data.logo;
                        }
                    });
                }
                if (this.corprateLicensefile != null && this.corprateLicensefile != undefined) {
                    // upload image
                    var obj = { CorporateId: response.data.corporateId, file: this.corprateLicensefile };
                    this._service.addCorporateLicense(obj).subscribe(response => {
                        if (response.isSuccess) {
                            this.corporateLicenseUrl = response.data.logo;
                        }
                    });
                }
                this.getProfile();
                this.toaster.successToastr('Corporate created successfully');               
               // this.router.navigate(['/sa/masters/Company']);
                setTimeout(() => {
                  this.router.navigate(['/sa/masters/Company'])
                }
                , 1000);
               
            }
            
            else {
                if (response.data.isExist) {
                    this.errorMessage = "Company with this E-mail already exist ";
                }
                if (response.responseMessage != null && response.responseMessage != '') {
                    this.errorMessage = response.responseMessage;
                }
            }

            
        });

    }
  }
  getProfile() {
    var obj = { adminloginId: this.adminLoginId, corporateId: this.corporateId };
    console.log(obj);
    this._service.getAdminProfile(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        var detail = response.data;
        this.selectedNGOs = response.data.masterNgos;
        this.comment = detail.comment;
        this.registerForm.setValue({
          location: detail.address.location,
          area: detail.address.area,
          zipcode: detail.address.zipcode,
          cityId: detail.address.cityId,
          stateId: detail.address.stateId,
          countryId: detail.address.countryId,
          corporateName: detail.corporateName,
         //corporateRegistrationDate: detail.corporateRegistrationDate,
          corporateEmail: detail.corporateEmail,
          corporateMobileNumber: detail.corporateMobileNumber,
          volunteeringInchargeName:detail.volunteeringInchargeName,
          corporateLogo: detail.corporateLogo,
          licenses: detail.licenses,
          adminEmail: detail.adminEmail,  
          adminFirstName: detail.adminFirstName,
          adminLastName: detail.adminLastName,
          adminMobileNumber: detail.adminMobileNumber,
          corporateKey: detail.corporateKey,
          registrationYear:detail.registrationYear
       //   ngos:this.selectedNGOs

        });
        this.registerForm.get('adminEmail').disable();
     //   this.registerForm.get('adminFirstName').disable();
     //   this.registerForm.get('adminLastName').disable();
     //   this.registerForm.get('adminMobileNumber').disable();
        this.registerForm.get('corporateKey').disable();


        this.getStates(detail.address.countryId);
        this.getCities(detail.address.stateId);
        this.corporateimageUrl = detail.corporateLogo;
        this.uploadedLicense = detail.licenses;
        // this.adminProfileUrl = detail.adminProfilePicture;


      }
    });
  }
  UpdateCorporateProfile() {
 
    this.validateForm = true;
    if (this.registerForm.valid) {  
     
      let registrationDate = new Date(this.registerForm.value.corporateRegistrationDate).toUTCString();
      let registrationDateLine = new Date(registrationDate);
      registrationDateLine.setHours(registrationDateLine.getHours() + 5);
      registrationDateLine.setMinutes(registrationDateLine.getHours() + 30);
      this.registerForm.value.corporateRegistrationDate = registrationDateLine;

      var obj = {"adminLoginId":this.adminLoginId,"corporateId":this.corporateId}
      var form = this.registerForm.value;

      this._service.updateCorporateAdminProfile({ ...obj,...form }).subscribe(response => {
        if (response.isSuccess) {

          if (this.corprateLogofile != null && this.corprateLogofile != undefined) {
            // upload image
            var obj = { CorporateId: this.corporateId, file: this.corprateLogofile };
            this._service.addCorporateLogo(obj).subscribe(response => {
              if (response.isSuccess) {
                this.corporateimageUrl = response.data.logo;
              }
            });
          }
          if (this.corprateLicensefile != null && this.corprateLicensefile != undefined) {
            // upload image
            var obj = { CorporateId: this.corporateId, file: this.corprateLicensefile };
            this._service.addCorporateLicense(obj).subscribe(response => {
              if (response.isSuccess) {
                this.corporateLicenseUrl = response.data.logo;
              }
            });
          }          
          this.getProfile()
          this.errorMessage = "Corporate profile updated"

          this.router.navigate(['/sa/masters/Company']);
        }
        else {
          this.errorMessage = response.responseMessage;
        }
      },(err)=>{
        this.errorMessage = "Some error occurred"
      });
    }
  }
  getCities(stateId: any) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: parseInt(stateId) };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }

  getStates(countryId: any) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: parseInt(countryId) };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  selectImage(event) {
      var file = event.target.files[0];

      if (file.size >= 1048576)
      {
          this.toaster.errorToastr("Upload limit is 1 mb only");
          event.target.value = null;
          return;
      }

    this.corprateLogofile = file;
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.corporateimageUrl = e.target.result;
    }
    reader.readAsDataURL(file);

  }
  selectCorporateLicense(event) {
       var files = event.target.files;
    if(this.corprateLicensefile == null){
      this.corprateLicensefile = [];
    }
    if(this.uploadedLicense == null){
      this.uploadedLicense = [];
    }
    for(var i = 0; i < files.length; i++){
      this.corprateLicensefile.push(files[i]);
      let file = {fileName: files[i].name};
      this.uploadedLicense.push(file);
    }
    
    
    
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.corporateLicenseUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);
    // var files = event.target.files;
    // this.corprateLicensefile = files[0];
    // var reader = new FileReader();
    // reader.onload = (e: any) => {
    //   this.corporateLicenseUrl = e.target.result;
    // }
    // reader.readAsDataURL(files[0]);

  }

  deleteLicense(corporateLicenseId: number) {
    var obj = { CorporateLicenseId: corporateLicenseId };
    console.log(obj);
    this._service.deletecompanyLicense(obj).subscribe(response => {
      this.getProfile();
    });
  }

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
    
    }
  } 
  
  restrictSpace(event){
    if (event.keyCode === 32) {
      return false;
    }
  }



  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val!=null && val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  getNGO() {
    var obj = { PageSize: 1000, PageNumber: 1 };
    this.ngoService.searchNGO(obj).subscribe(response => {
      if (response.isSuccess) {
        this.NGOs = response.list;    
      }
    });
  }



  ngOnInit() {

    this.createForm();

    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
      if (this.corporateId > 0) {
        this.getProfile();
      }
    });

    this.getNGO();
    
    this.ngoDropdownSettings = {
      singleSelection: false,
      idField:'masterNgoId',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

}
