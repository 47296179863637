import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { VolunteerManagerService } from '../../../shared/services/volunteer-manager.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-volunteermanager-profile',
  templateUrl: './volunteermanager-profile.component.html',
  styleUrls: ['./volunteermanager-profile.component.scss']
})
export class VolunteermanagerProfileComponent implements OnInit {

  adminLoginId: number;
  volunteerManagerId: number;
  corporateId: number;
  personalInfoForm: FormGroup;
  validateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  adminProfileUrl: string;
  adminProfileFile: any;
  fullname: string;
  errorMessage: string = "";
  Email: string;
  options: DatepickerOptions = {
    maxDate: new Date(Date.now()), // Minimal selectable date

  };

  constructor(private router: Router,private toaster:ToastrManager, private route: ActivatedRoute, private authentication: AuthenticationService, private service: VolunteerManagerService) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1 || login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.volunteerManagerId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
  }


  createForm() {
    this.personalInfoForm = new FormGroup({
      Email: new FormControl(''),
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      MobileNumber: new FormControl('', [Validators.required,Validators.minLength(10)])

    });
  }
  getProfile() {
    var obj = { adminloginId: this.adminLoginId, volunteerManagerId:this.volunteerManagerId, corporateId: this.corporateId };
    console.log(obj);
    this.service.detailVMService(obj).subscribe(response => {
    
      if (response.isSuccess) {
        var detail = response.data;
        this.fullname = detail.firstName + " " + detail.lastName;
        this.Email = detail.email;

        this.personalInfoForm.setValue({
          Email: detail.email,
          FirstName: detail.firstName,
          LastName: detail.lastName,
          MobileNumber: detail.mobileNumber
        });
//         if(detail.address){
// this.corporateInfoForm.setValue({
//           location: detail.address.location,
//           area: detail.address.area,
//           zipcode: detail.address.zipcode,
//           cityId: detail.address.cityId,
//           stateId: detail.address.stateId,
//           countryId: detail.address.countryId,
//           corporateName: detail.corporateName,
//           corporateRegistrationDate: detail.corporateRegistrationDate,
//           corporateEmail: detail.corporateEmail,
//           corporateMobileNumber: detail.corporateMobileNumber,
//           corporateLogo: detail.corporateLogo,
//           licenses: detail.licenses,

//         });
//          this.getStates(detail.address.countryId);
//         this.getCities(detail.address.stateId);
//         }
        
       
//         this.corporateimageUrl = detail.corporateLogo;
//         this.adminProfileUrl = detail.adminProfilePicture;
//         this.uploadedLicense = detail.licenses;


      }
    });
  }

  UpdatePersonalProfile() {
    this.validateForm = true;
    if (this.personalInfoForm.valid) {
      var val = this.personalInfoForm.value;
      var pair = { "VolunteerManagerId": this.volunteerManagerId };
      var obj = { ...val, ...pair };
      this.service.updateVMService(obj).subscribe(response => {
        if (response.isSuccess) {
          this.toaster.successToastr("Profile updated successfully");
          this.getProfile()
        }
        else {
          this.toaster.errorToastr("Some Error Occured");
        }
      });
    }
  }
  // UpdateCorporateProfile() {
  //   this.validateCorporateForm = true;
  //   if (this.corporateInfoForm.valid) {
  //     this.corporateInfoForm.value.corporateId = this.corporateId;
  //     this.corporateInfoForm.value.adminLoginId = this.adminLoginId;
  //     this._service.updateCorporateAdminProfile(this.corporateInfoForm.value).subscribe(response => {
  //       if (response.isSuccess) {
  //         this.errorMessage = "Corporate Info Updated Successfully";
  //         if (this.corprateLogofile != null && this.corprateLogofile != undefined) {
  //           // upload image
  //           var obj = { CorporateId: this.corporateId, file: this.corprateLogofile };
  //           this._service.addCorporateLogo(obj).subscribe(response => {
  //             if (response.isSuccess) {
  //               this.corporateimageUrl = response.data.logo;
  //               var details = this.authentication.getToken();
  //               details.access_token.corporateLogo = this.corporateimageUrl;
  //               this.authentication.setToken(details);
  //             }
  //           });
  //         }
  //         else if (this.corprateLicensefile != null && this.corprateLicensefile != undefined) {
  //           // upload license

  //           var obj = { CorporateId: this.corporateId, file: this.corprateLicensefile };
  //           this._service.addCorporateLicense(obj).subscribe(response => {
  //             if (response.isSuccess) {
  //               this.uploadedLicense = response.data.licenses;
  //             }
  //           });
  //         }
  //         else {
  //           console.log("Some Error Found");
  //         }


  //         //this.getProfile()
  //       }
  //     });
  //   }
  // }

  // getCities(stateId: number) {
  //   var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
  //   this.masterService.getCities(obj).subscribe(response => {
  //     this.cities = response.data;
  //   });
  // }

  // getStates(countryId: number) {
  //   var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
  //   this.masterService.getStates(obj).subscribe(response => {
  //     this.states = response.data;
  //   });
  // }


  // selectImage(event) {
  //   var files = event.target.files;
  //   this.corprateLogofile = files[0];
  //   var reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     this.corporateimageUrl = e.target.result;
  //   }
  //   reader.readAsDataURL(files[0]);

  // }
  // selectCorporateLicense(event) {
  //   var files = event.target.files;
  //   if(this.corprateLicensefile == null){
  //     this.corprateLicensefile = [];
  //   }
  //   if(this.uploadedLicense == null){
  //     this.uploadedLicense = [];
  //   }
  //   for(var i = 0; i < files.length; i++){
  //     this.corprateLicensefile.push(files[i]);
  //     let file = {fileName: files[i].name};
  //     this.uploadedLicense.push(file);
  //   }
    
    
    
  //   var reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     this.corporateLicenseUrl = e.target.result;
  //   }
  //   reader.readAsDataURL(files[0]);

  // }
  // selectProfileUrl(event) {
  //   var files = event.target.files;
  //   this.adminProfileFile = files[0];
  //   var reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     this.adminProfileUrl = e.target.result;
  //   }
  //   reader.readAsDataURL(files[0]);

  //   if (this.adminProfileFile != null && this.adminProfileFile != undefined) {
  //     // upload image
  //     var obj = { adminLoginId: this.adminLoginId, file: this.adminProfileFile };
  //     this._service.uploadProfile(obj).subscribe(response => {
  //       if (response.isSuccess) {
  //         this.adminProfileUrl = response.data.profilePicture;
  //         var details = this.authentication.getToken();
  //         details.access_token.profilePicture = this.adminProfileUrl;
  //         this.authentication.setToken(details);
  //       }
  //     });
  //   }

  // }

    keyDownHandler(event) {
        if (event.target.value.length > 0) {
            return true;
        } else {
            if (event.keyCode === 32) {
                return false;
            }
        }
    }

    // only number validation
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

  ngOnInit() {
    this.createForm();
    this.getProfile();
  }


}
