
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class MasterNGOService {

  constructor(private router: Router, private http: HttpClient) { }

  // Events
  uploadImage(obj): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Uploaded', obj.file);

    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/UploadLogo', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  searchNGO(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/Search', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  createNGO(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/Add', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  NGOList(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/Search', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  DownloadCSV(): Observable<any> {
    return this.http.get<any>(environment.apiBasePath + '/MasterNgo/GetNGOCsv').pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getNGODetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/Details', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteNGO(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/Delete', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  updateNGO(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/MasterNgo/Update', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }







  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
