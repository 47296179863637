import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { QuizService } from '../../../shared/services/quiz.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Location } from '@angular/common';
import * as _moment from 'moment';

@Component({
  selector: 'app-quiz-user',
  templateUrl: './quiz-user.component.html',
  styleUrls: ['./quiz-user.component.scss']
})
export class QuizUserComponent implements OnInit {
  quizId: any;
  adminLoginId: any;
  quizUsers: any[] = [];
  pageNumber: number=1;
  pageSize: number=10;
  firstPageClass: string;
  previousPageClass: string;
  nextPageClass: string;
  lastPageClass: string;
  numberOfPages: any;
  searchTerm: string;
  count: number = 0;
  startFrom: number;
  endOn: any;
  quizDetails: any[] = [];
  userAttempts: any[] = [];


  constructor(  
    private router:Router,
    private activeRoute:ActivatedRoute,
    private service:QuizService,
    private auth:AuthenticationService,
    private loc:Location,
    private toaster: ToastrManager) {
      var details = this.auth.getToken();
      
      if (details == null) {
          this.auth.logout(); return;
      } 

      this.adminLoginId = details.access_token.adminLoginId;
      this.quizId = this.activeRoute.snapshot.params['id'];
    }

    getUserQuizDetail(evt)
    {
      
      this.service.getQuizDetail({"quizId":evt.quizId,"UserLoginId":evt.userId})
      .subscribe(response => 
      {
          if(response.isSuccess)
          {
            this.quizDetails = response.quizDetail;
            let elem = document.getElementById("model-detail") as HTMLElement;
            elem.click();
          }
      });
    }

    getAllAttempts(user){
      var reqObj = { "quizId":this.quizId,"UserLoginId":user.userId }
      this.service.callQuizUsersService(reqObj)
      .subscribe(res => {
        if(res.isSuccess){
          this.userAttempts = res.quizUserList;
           // this.count = this.userAttempts.length;
            let elem = document.getElementById("model-user") as HTMLElement;
            elem.click();
          
        }
        else{
            this.toaster.errorToastr("Some Error Occured");
        }
      })
    }

    playAudio(event) {
      let opner = window.open(event, '_blank', 'height=300,width=300');
  }

    getQuizUsers(){
      var reqObj = { "quizId":this.quizId,"pageNumber":this.pageNumber,"pageSize":this.pageSize }
      this.service.callQuizUsersService(reqObj)
      .subscribe(res => {
        if(res.isSuccess){
          this.quizUsers = res.userList;
            this.count = this.quizUsers.length;
            

          if (this.count == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.count <= this.pageSize) {
            this.endOn = this.count;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
            this.endOn = this.count;
        }
        }
        else{
            this.toaster.errorToastr("Some Error Occured");
        }
      })
    }

    firstPage() {
      if (this.pageNumber > 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.pageNumber = 1;
        this.getQuizUsers();
      }
    }
  
    lastPage() {
      if (this.pageNumber < this.numberOfPages) {
        this.firstPageClass = "";
        this.previousPageClass = "";
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
        this.pageNumber = this.numberOfPages;
        this.getQuizUsers();
      }
    }
  
    previousPage() {
      if (this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
        if (this.pageNumber == 1) {
          this.firstPageClass = "disabled";
          this.previousPageClass = "disabled";
          this.nextPageClass = "";
          this.lastPageClass = "";
        }
        this.getQuizUsers();
      }
    }
  
    nextPage() {
      if (this.pageNumber < this.numberOfPages) {
        this.pageNumber = this.pageNumber + 1;
        this.firstPageClass = "";
        this.previousPageClass = "";
        if (this.pageNumber == this.numberOfPages) {
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        this.getQuizUsers();
      }
    }
  
    checkIfBoxEmpty() {
      if (this.searchTerm == '' || this.searchTerm == null) {
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getQuizUsers();
      }
    }
  
    search() {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getQuizUsers();
    }
  
    changePageSize(pageSize: number) {
      this.pageSize = pageSize;
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getQuizUsers();
    }
    navigateBack(){
      this.loc.back();
    }
    getTime(dt){
      return _moment(dt).format('HH:mm');
    }
  
  ngOnInit() {
    this.getQuizUsers();
  }

}
