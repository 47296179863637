import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router'
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { QuizService } from '../../../../shared/services/quiz.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit {
  quizId: any;
  adminLoginId: any;
  pageSize: any = 10;
  pageNumber: any = 1;
  questionList: any;
  count: any;
  startFrom: number;
  endOn: any;
  nextPageClass: string;
  lastPageClass: string;
  numberOfPages: number;
  firstPageClass: string;
  previousPageClass: string;
    searchTerm: string;
    campaignId: any;
    campaignStatusId: any;
    curriculumId: any;
  hintText: string;

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private service:QuizService,
    private auth:AuthenticationService,
    private toaster:ToastrManager
    ) { 
      var details = this.auth.getToken();
      if (details == null) {
          this.auth.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.auth.logout(); return;
          }
      }
      this.quizId = this.route.snapshot.params["quizId"];
      this.campaignId = this.route.snapshot.params['id'];
      this.campaignStatusId = this.route.snapshot.params['status'];
      this.curriculumId = this.route.snapshot.params["curriculumId"];
      this.adminLoginId = details.access_token.adminLoginId;
  }


  getList(){
    var obj = {"quizId":this.quizId,"adminLoginId":this.adminLoginId,"pageSize":this.pageSize,"pageNumber":this.pageNumber}
    this.service.callGetQuestionListService(obj)
    .subscribe(res => {
      console.log(res);
      if(res.isSuccess){
        this.questionList = res.questionList;
        this.count = res.count;

        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    })
  }

  performAction(event){
    var reqObj = { "questionId":event.questionId,"adminLoginId":this.adminLoginId }
    this.service.callPerformActionOnQuestionService(reqObj)
    .subscribe(res=>{
      if(res.isSuccess){
         this.getList();
         this.toaster.successToastr(res.responseMessage);
      }
      else{
        this.toaster.errorToastr("Some error occured");
      }
    })
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getList();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getList();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getList();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getList();
    }
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getList();
    }
  }

    navigateBack() {
        var routeParams = this.route.snapshot.url;
        var route = "/admin/" + routeParams[0].path + "/" + routeParams[1].path + "/" + routeParams[2].path + "/" + routeParams[3].path + "/" + routeParams[4].path + "/" + routeParams[5].path + "/" + routeParams[6].path
        this.router.navigateByUrl(route);
    }

    playAudio(event) {
        let opner = window.open(event, '_blank', 'height=300,width=300');
    }

    getTrimmedContent(content:string){
      if(content.length == 0){
        return content = "-"
      }
      else if(content.length > 30){
        return content.substring(0,30) + "...";
      }else{
        return content;
      }
      
    }
    
    viewContent(content:string){
      this.hintText = content;
    }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getList();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getList();
  }

  ngOnInit() {
    this.getList();
  }

}
