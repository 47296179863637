import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { MasterNGOService } from '../../../shared/services/masterNGO.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { SelectOptionService } from '../../../shared/elements/select-option.service'
import * as _moment from 'moment';
import { EditorconfigModule } from '../../../shared/models/editorconfig/editorconfig.module';
import 'quill-emoji/dist/quill-emoji.js';

@Component({
  selector: 'event-private-create',
  templateUrl: './event-private-create.component.html',
})

export class EventPrivateCreateComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  eventForm: FormGroup;
  validateForm: boolean = false;
  eventGoals: any[] = [];
  eventContributionTypes: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  statusId: number = 2;
  countries: any[] = [];
  selectedSkills: any[] = [];
  skills: any[] = [];
  eventId: number;
  imageUrl: string;
  file: any;
  SearchedNGOs: any[] = [];
  ngoSearchTerm: string;
  selectedNgoId: number;
  selectedNgo: any;
  options: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))), // Minimal selectable date
  };
  optionsStart: DatepickerOptions = {
    minDate: new Date(Date.now()), // Minimal selectable date
  };
  optionsApplication: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))), 
    maxDate: new Date(Date.now()), // Minimal selectable date
  };
  optionsEnd: DatepickerOptions = {
    minDate: new Date(Date.now()), // Minimal selectable date
  };
  optionsReporting: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() + 1)))), // Minimal selectable date
  };
  hours: any[];
  minutes: any[];
  editorUrl:string = '';
  editorConfiguration:any;
  errorMessage: string;
  showSkills: boolean = false;
//  IsCertificateRequired: boolean = true;
  isSubmitEnabled: boolean = true;
  skillDropdownSettings: {};
  serviceCallCount: number = 0
  IsOpenApplication:boolean = true;

  openReward: boolean = false;
  addrssess:FormArray[];
  showAddressButton:boolean = false;
  showAddress: boolean;

  addressCountriesList: any[] = [];
  addressStatesList: any[] = [];
  addressCitiesList: any[] = [];

  timeFormat:any;
  maxHours:any[] = [0,1,2,3,4,5,6,7,8,9,10,11,12];
  maxMinutes:any[] = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,
    31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59];
  startTimeZone:number=0;
  endTimeZone:number = 0;
  setOptionsEnable: boolean = true;
  invalidEndTime: boolean = false;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
   
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
   
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
   
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
   
      ['clean'],                                         // remove formatting button
      ['emoji'],
      ['link','image','video'],
                               // link and image, video
    ],
    "emoji-toolbar": true
  };

  constructor(private _service: AdminEventsService, private authentication: AuthenticationService, private masterService: AdminMasterService, private router: Router, private route: ActivatedRoute, private ngoService: MasterNGOService, private selectOptionService: SelectOptionService, private editorConfig:EditorconfigModule) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==1){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    this.editorConfiguration = this.editorConfig.configuration;
    this.editorUrl = this.editorConfig.editorUrl;
    router.events.subscribe((response) => {
      this.eventId = route.snapshot.params['id'];
    });
    this.hours = this.selectOptionService.getHours();
    this.minutes = this.selectOptionService.getMinutes();

  }

  setCorrectTime(event) {
    this.eventForm.controls.publishDate.setErrors(null);
    var st = new Date(this.eventForm.value.publishDate),
        et = new Date(this.eventForm.value.publishDate)

    if (st.getTime() > et.getTime()) {
        this.eventForm.controls.publishDate.setErrors({ 'incorrect': true });
    }
}
    whiteSpaceValidator(
        control: FormControl
    ): { [key: string]: any } | null {
        var val = control.value;

        for (var i = 0; i <= val.length - 1; i++) {
            val = val.replace("&nbsp;", "");
        }
        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }


  selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    //if (this.file.size <= 2 * 1024 * 1024) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);
    //}
    //else {
    //  this.file = null;
    //  alert("File size should be less than 2MB.");
    //}
  }




    selectValueType(type: number) {
        this.eventForm.controls["skillSets"].setValue([]);
        this.eventForm.controls["value"].setValue('');
    if (type == 1) {
      this.showSkills = false;  
      this.eventForm.controls["skillSets"].clearValidators();
      this.eventForm.controls["skillSets"].setErrors(null);
    }
    else {
        this.showSkills = true;
      
      this.eventForm.controls["skillSets"].setValidators(Validators.required);
    }
  }
// selectValueTypeforCertificate(type: number) {
//     if (type == 2) {
//       this.IsCertificateRequired = false;
//     }
//     else {
//       this.IsCertificateRequired = true;

//     }
//   }

  createForm() {
    this.eventForm = new FormGroup({
      eventId: new FormControl(''),
      corporateId: new FormControl(''),
      isPublic: new FormControl(false),
      statusId: new FormControl(2),
      pictureUrl: new FormControl(''),
      IsCertificateRequired: new FormControl(true),
        title: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
        description: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
        publishDate: new FormControl(new Date(Date.now()), Validators.required),
        applicationDate: new FormControl(new Date(new Date().setDate(new Date(Date.now()).getDate()+1))),
        startDate: new FormControl(new Date(new Date().setDate(new Date(Date.now()).getDate()+1))),
        endDate: new FormControl(new Date(new Date().setDate(new Date(Date.now()).getDate() + 1)), Validators.required),
        reportingDeadline: new FormControl(new Date(new Date().setDate(new Date(Date.now()).getDate() + 2)), Validators.required), 
      goalId: new FormControl('', Validators.required),
      eventContributionTypeId: new FormControl('', Validators.required),
      vacancy: new FormControl({value:'',disabled:true},[Validators.required,Validators.maxLength(10)]),
      bufferVacancy: new FormControl({value:'',disabled:true}, Validators.maxLength(10)),
        hours: new FormControl('', [Validators.required, Validators.maxLength(10)]),
  minutes: new FormControl('', [Validators.required, Validators.maxLength(10)]),
        cappedHours: new FormControl('', Validators.maxLength(10)),
        value: new FormControl('', Validators.maxLength(10)),
        valueType:new FormControl(1),
        score:new FormControl('',[Validators.maxLength(10),Validators.required]),
      //skills: new FormControl(''),
      skillSets: new FormControl([]),
      organiserName: new FormControl(''),
      organiserEmail: new FormControl(''),
      organiserMobileNumber: new FormControl(''),
      organiserNgoname: new FormControl(''),
      masterNgoId: new FormControl(''),
      isSameDayEvent:new FormControl(false),
      isWorldWide:new FormControl(true),
      autoApprove:new FormControl(false),
      rewardDescription:new FormControl(''),
            rewardImage:new FormControl(''),
   eventType:new FormControl('',Validators.required),
 addresses:new FormArray([]),
 startTimeHour:new FormControl(0),
 endTimeHour:new FormControl(0),
 startTimeMin:new FormControl(0),
 endTimeMin:new FormControl(0),
 startTimeZone:new FormControl("am"),
 endTimeZone:new FormControl("am"),
 team: new FormControl(false),
 submitFeedbackImage: new FormControl(false)
    });
  }

  get addresses() : FormArray {
    return this.eventForm.get('addresses') as FormArray;
 }

 addNewAddress(){
   this.addresses.push(new FormGroup({
     addressId: new FormControl(0),
     countryId: new FormControl('',Validators.required),
     stateId: new FormControl({value:'',disabled:true}),
     cityId: new FormControl({value:'',disabled:true}),               
     location: new FormControl({value:'',disabled:true}),
     area: new FormControl({value:'',disabled:true}),
     zipcode: new FormControl({value:'',disabled:true}),
     latitude: new FormControl({value:'',disabled:true}),
     longitude: new FormControl({value:'',disabled:true}),
   }));

   this.addressCountriesList.push([]);
   this.addressStatesList.push([]);
   this.addressCitiesList.push([]);

   var i = this.addresses.length-1;
   this.getCountries(i);      
 }

 removeAddress(i:number){
   
   var rem = true;
   var addressMappings = this.eventForm.get("addresses") as FormArray;
   var addressId = addressMappings.controls[i].get("addressId").value;
  
   if(addressId.value > 0){
      this._service.RemoveAddress({"eventId":this.eventId,"addressId":addressId.value}).subscribe(response =>{
           rem=response;  
      })
   } 

   if(rem){
     this.addresses.removeAt(i);
     this.addressCountriesList.slice(i,i);
     this.addressStatesList.slice(i,i);
     this.addressCitiesList.slice(i,i);
   }
 }

 onSelectEndTimeZone(v){
  switch(v){
    case "pm": this.endTimeZone = 12; break;
    case "am": this.endTimeZone = 0; break;
  }
  this.validateTimeInput();
}

onSelectStartTimeZone(v){
  switch(v){
    case "pm": this.startTimeZone = 12; break;
    case "am": this.startTimeZone = 0; break;
  }
  this.validateTimeInput();
}

validateTimeInput(){
  this.invalidEndTime = false;
  var stTimeHr = this.eventForm.get("startTimeHour").value;
  var endTimeHr = this.eventForm.get("endTimeHour").value;
  var stTimeMin = this.eventForm.get("startTimeMin").value;
  var endTimeMin = this.eventForm.get("endTimeMin").value;

  if(stTimeHr == 0){
    this.eventForm.get("startTimeZone").setValue("am");
  }
  if(endTimeHr == 0){
    this.eventForm.get("endTimeZone").setValue("am");
  }
  
  stTimeHr = parseInt(stTimeHr) + this.startTimeZone;
    endTimeHr = parseInt(endTimeHr) + this.endTimeZone;

  var startDate = this.eventForm.get("startDate").value;
  var endDate = this.eventForm.get("endDate").value;

  if(_moment(startDate).format('YYYY-MM-DD') == _moment(endDate).format('YYYY-MM-DD')){
    var startTimeMinutes = (parseInt(stTimeHr)*60) + parseInt(stTimeMin);
    var endTimeMinutes = (parseInt(endTimeHr)*60) + parseInt(endTimeMin);
    
  
    if(endTimeMinutes < startTimeMinutes){
      console.log("invalid")
       this.invalidEndTime = true;
    }
    else{
       this.invalidEndTime = false;
       console.log("valid")
    }
  }  
}

onSelectCustomDate(evt){
  

  this.eventForm.get('isSameDayEvent').setValue(evt);

  if(evt){
    var publish = this.eventForm.get('publishDate').value;
    this.setOptions('publish', publish);
    this.eventForm.get("publishDate").setValue(new Date(Date.now()));
    this.setOptions('end', publish);
    this.eventForm.get("endDate").setValue(new Date(Date.now()));
    this.eventForm.get("reportingDeadline").setValue(new Date(new Date().setDate(new Date(Date.now()).getDate() + 1)));
}
else{
  this.setOptions('publish',publish);
  this.eventForm.get("publishDate").setValue(new Date(Date.now()));
  this.setOptions('application',publish);   
  this.eventForm.get("applicationDate").setValue(new Date(new Date().setDate(new Date(Date.now()).getDate() + 1)));  
  this.setOptions('start',publish);   
  this.eventForm.get("startDate").setValue(new Date(new Date().setDate(new Date(Date.now()).getDate() + 2)));  
  this.setOptions('end',publish);
  this.eventForm.get("endDate").setValue(new Date(new Date().setDate(new Date(Date.now()).getDate() + 2)));
  this.eventForm.get("reportingDeadline").setValue(new Date(new Date().setDate(new Date(Date.now()).getDate() + 3)));
}


 
}


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          applicationDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  startdateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          startDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  enddateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          endDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  reportdateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          reportingDeadline: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  saveAsDraft() {
    this.statusId = 1;
  }
  deleteImage() {
    this.imageUrl = null;
  }

  getLatLongFromAddress(i:number) {

    var addressMappings = this.eventForm.get('addresses') as FormArray;
    
    var req = {
      addressId: addressMappings.controls[i].get('addressId').value,
      location: addressMappings.controls[i].get('location').value,
      area: addressMappings.controls[i].get('area').value,
      zipcode: addressMappings.controls[i].get('zipcode').value,
      countryId: addressMappings.controls[i].get("countryId").value,
      stateId: addressMappings.controls[i].get("stateId").value,
      cityId: addressMappings.controls[i].get("cityId").value,
    }

    this._service.getLatLong(req).subscribe(response => {
      if (response.isSuccess) {
          this.addresses.controls[i].setValue({
          addressId: addressMappings.controls[i].get('addressId').value,
          location: addressMappings.controls[i].get('location').value,
          area: addressMappings.controls[i].get('area').value,
          zipcode: addressMappings.controls[i].get('zipcode').value,
          countryId: addressMappings.controls[i].get("countryId").value,
          stateId: addressMappings.controls[i].get("stateId").value,
          cityId: addressMappings.controls[i].get("cityId").value,
          latitude: response.latitude,
          longitude: response.longitude,
        });
      }
    });
  }

    save() {
        if (this.serviceCallCount == 0) {
            this.serviceCallCount = 1;
            this.validateForm = true;   this.validateTimeInput();
            if (this.eventForm.valid && !this.invalidEndTime) {
                this.isSubmitEnabled = false;
                this.setOptionsEnable = false;
                this.errorMessage = '';
                //if (!this.selectedNgoId) {
                //  this.errorMessage = "Please select a NGO.";
                //}
                //else {
                  this.eventForm.get("statusId").setValue(this.statusId);
                  this.eventForm.get("isPublic").setValue(false);
                
                  this.eventForm.get("skillSets").setValue(this.selectedSkills);
                  this.eventForm.get("masterNgoId").setValue(this.selectedNgoId);
                 // this.eventForm.get("IsCertificateRequired").setValue(this.IsCertificateRequired);
  

                if(!this.openReward){
                  this.eventForm.value.rewardDescription = '';
                  this.eventForm.value.rewardImage = '';
                 }

                 let publishDate = new Date(this.eventForm.value.publishDate).toUTCString();       
                 let publishDateLine = new Date(publishDate);
                 publishDateLine.setHours(publishDateLine.getHours() + 5);
                 publishDateLine.setMinutes(publishDateLine.getHours() + 30);                
                 this.eventForm.get("publishDate").setValue(publishDateLine);
         
                 let startDate = new Date(this.eventForm.value.startDate).toUTCString();
                 let startDateLine = new Date(startDate);
                 startDateLine.setHours(startDateLine.getHours() + 5);
                 startDateLine.setMinutes(startDateLine.getHours() + 30);
                 this.eventForm.get("startDate").setValue(startDateLine);
         
                 let endDate = new Date(this.eventForm.value.endDate).toUTCString();
                 let endDateLine = new Date(endDate);
                 endDateLine.setHours(endDateLine.getHours() + 5);
                 endDateLine.setMinutes(endDateLine.getHours() + 30);
                 this.eventForm.get("endDate").setValue(endDateLine);
         
                 let applicationDate = new Date(this.eventForm.value.applicationDate).toUTCString();
                 let applicationDateLine = new Date(applicationDate);
                 applicationDateLine.setHours(applicationDateLine.getHours() + 5);
                 applicationDateLine.setMinutes(applicationDateLine.getHours() + 30);
                 this.eventForm.get("applicationDate").setValue(applicationDateLine);
                
                 let reportingDeadlineDate = new Date(this.eventForm.value.reportingDeadline).toUTCString();
                 let reportingDateline = new Date(reportingDeadlineDate);
                 reportingDateline.setHours(reportingDateline.getHours() + 5);
                 reportingDateline.setMinutes(reportingDateline.getHours() + 30);
                 this.eventForm.get("reportingDeadline").setValue(reportingDateline);
 

                var endtime = this.eventForm.get("endTimeHour").value;
                var newEndTime = parseInt(endtime) + this.endTimeZone;            
                this.eventForm.get("endTimeHour").setValue(newEndTime);

                var startTime = this.eventForm.get("startTimeHour").value;
                var newStartTime = parseInt(startTime) + this.startTimeZone;;
                this.eventForm.get("startTimeHour").setValue(newStartTime);

                if(this.imageUrl==null || this.imageUrl.trim()==''){
                  this.eventForm.get("pictureUrl").setValue('');
                }


                this._service.createEvent(this.eventForm.value).subscribe(response => {
                    if (response.isSuccess) {
                        if (this.file != null && this.file != undefined) {
                            // upload image
                            var obj = { eventId: response.data.eventId, file: this.file };
                            this._service.uploadImage(obj).subscribe(response => {
                                if (response.isSuccess) {
                                    this.imageUrl = response.data.pictureUrl;
                                }
                            });
                        }
                        this.router.navigate(['/admin/events/private']);
                    }
                    else {
                        this.isSubmitEnabled = true; this.serviceCallCount = 0;  this.setOptionsEnable = true;
                    }
                }, (err) => {
                    this.isSubmitEnabled = true; this.serviceCallCount = 0; this.setOptionsEnable = true;
                });
            }else{
              this.serviceCallCount = 0; this.isSubmitEnabled = true; this.setOptionsEnable = true;
            }
        }
        
  }

  searchNGO() {
    var obj = { SearchTerm: this.ngoSearchTerm, PageSize: 1000, PageNumber: 1 };
    this.ngoService.searchNGO(obj).subscribe(response => {
      if (response.isSuccess) {
        this.SearchedNGOs = response.list;
        console.log(this.SearchedNGOs);
      }
    });
  }

  setApplicationCriteria(IsOpenVacancy:boolean){

    var objVacancy = this.eventForm.get("vacancy");
    var objBufferVacancy = this.eventForm.get("bufferVacancy");
     if(IsOpenVacancy){
      objVacancy.disable(); objVacancy.clearValidators(); objVacancy.setErrors(null);
      objBufferVacancy.disable();  objBufferVacancy.clearValidators(); objBufferVacancy.setErrors(null);
     } 
     else{
      objVacancy.enable(); objVacancy.setValidators(Validators.required); 
      objBufferVacancy.enable(); 
     }
  }

  selectNGO(ngoId) {
    this.errorMessage = '';
    if(ngoId!=''){
    let ngo = this.SearchedNGOs.filter(a => a.masterNgoId == ngoId)[0];
    this.selectedNgoId = ngo.masterNgoId;
    this.selectedNgo = ngo;
    this.ngoSearchTerm = '';
    }
    else{
      this.selectedNgo = null;
      this.selectedNgoId = null;
    }
    
  }
  
  getStates(countryId: any,i:number,evt:boolean) {
    if(countryId != ''){
      var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
      this.masterService.getStates(obj).subscribe(response => {
         this.addressStatesList[i] = response.data;     
      });
    }  
    if(evt){
      this.enableDisableAddressControls("country",i);
    }
   
  }

  getCities(stateId: any,i:number,evt:boolean) {
    if(stateId != ''){
      var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
      this.masterService.getCities(obj).subscribe(response => {
        this.addressCitiesList[i] = response.data;              
      });
    }
    if(evt){
      this.enableDisableAddressControls("state",i); 
    }
    
  }

  onCitySelect(cityId:any,i:number){
    this.enableDisableAddressControls("city",i);
  }

  enableDisableAddressControls(selectedItem:string,i:number){
 
      var addressMappings = this.eventForm.get('addresses') as FormArray;
     
      var country = addressMappings.controls[i].get("countryId").value
      var state = addressMappings.controls[i].get("stateId").value
      var city =  addressMappings.controls[i].get("cityId").value

      if(selectedItem == "country"){
         if(country!=''){
             addressMappings.controls[i].get("stateId").enable();
         }
         else{
          addressMappings.controls[i].get("stateId").disable();                              
         }
         addressMappings.controls[i].get("stateId").setValue('');
         addressMappings.controls[i].get("cityId").setValue(''); addressMappings.controls[i].get("cityId").disable();   
         addressMappings.controls[i].get("location").setValue(''); addressMappings.controls[i].get("location").disable();           
         addressMappings.controls[i].get("area").setValue(''); addressMappings.controls[i].get("area").disable();          
         addressMappings.controls[i].get("zipcode").setValue(''); addressMappings.controls[i].get("zipcode").disable();        
         addressMappings.controls[i].get("latitude").setValue(''); addressMappings.controls[i].get("latitude").disable();      
         addressMappings.controls[i].get("longitude").setValue(''); addressMappings.controls[i].get("longitude").disable();      
      }
      else if(selectedItem == "state"){
        if(state!=''){
          addressMappings.controls[i].get("cityId").enable();
      }
      else{
       addressMappings.controls[i].get("cityId").disable();                              
      }
      addressMappings.controls[i].get("cityId").setValue('');    
      addressMappings.controls[i].get("location").setValue(''); addressMappings.controls[i].get("location").disable();           
      addressMappings.controls[i].get("area").setValue(''); addressMappings.controls[i].get("area").disable();          
      addressMappings.controls[i].get("zipcode").setValue(''); addressMappings.controls[i].get("zipcode").disable();        
      addressMappings.controls[i].get("latitude").setValue(''); addressMappings.controls[i].get("latitude").disable();      
      addressMappings.controls[i].get("longitude").setValue(''); addressMappings.controls[i].get("longitude").disable();      
      }
      else{
        
        if(city!=''){
           addressMappings.controls[i].get("location").enable();           
           addressMappings.controls[i].get("area").enable();          
           addressMappings.controls[i].get("zipcode").enable();        
           addressMappings.controls[i].get("latitude").enable();      
           addressMappings.controls[i].get("longitude").enable();      
        }
        else{

          addressMappings.controls[i].get("location").setValue('');
          addressMappings.controls[i].get("area").setValue('');
          addressMappings.controls[i].get("zipcode").setValue('');
          addressMappings.controls[i].get("latitude").setValue('');
          addressMappings.controls[i].get("longitude").setValue('');
          addressMappings.controls[i].get("location").disable();           
          addressMappings.controls[i].get("area").disable();          
          addressMappings.controls[i].get("zipcode").disable();        
          addressMappings.controls[i].get("latitude").disable();      
          addressMappings.controls[i].get("longitude").disable();   
        }
      }
  }

  disableAddress(evt:boolean){
    if(evt){
      var addressMappings = this.eventForm.get("addresses") as FormArray;
     
      while (addressMappings.length > 0){     
        this.removeAddress(0);
        addressMappings.removeAt(0);  
        this.addressCountriesList.slice(0,0);
        this.addressStatesList.slice(0,0); 
        this.addressCitiesList.slice(0,0);      
      }    
    
    }
  }

 

  isWorldWideActivity(evt){ 
    this.eventForm.get('isWorldWide').setValue(evt);
    if(!evt){
      if(!(this.addresses.length > 0)){
        this.addNewAddress(); 
      }
    }else{
      this.disableAddress(evt)
    }  
  }

  setOptions(controlName: string, evnt: any) {    
    if(this.setOptionsEnable){
      var d = this.eventForm.get('isSameDayEvent').value;
      if(d){
        switch(controlName){
  
          case 'publish':     
             this.eventForm.get("startDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())));
             var enddt = this.eventForm.get("endDate").value;
             if(_moment(enddt).format('YYYY-MM-DD') < _moment(evnt).format('YYYY-MM-DD')){
                this.eventForm.get("applicationDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())));          
                this.eventForm.get("endDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())));     
             }  
          this.optionsEnd = {
            minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()-1)),
          }; break;
  
          case 'end':
            var dt = this.eventForm.get("reportingDeadline").value;
            if(_moment(dt).format('YYYY-MM-DD') <= _moment(evnt).format('YYYY-MM-DD')){
              this.eventForm.get("reportingDeadline").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()+1)));
            }             
            this.optionsReporting = {
              minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())), 
            }; break;
        }
      }
      else{
        switch(controlName){
           
          case 'publish': 
  
            var applicationdt = this.eventForm.get("applicationDate").value;      
            
            if(_moment(applicationdt).format('YYYY-MM-DD') < _moment(evnt).format('YYYY-MM-DD')){
               this.eventForm.get("applicationDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()))); 
            }     
  
            var dt = this.eventForm.get("startDate").value;   
            if(_moment(dt).format('YYYY-MM-DD') <= _moment(evnt).format('YYYY-MM-DD')){
               this.eventForm.get("startDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()+1)));
            }        
  
            var enddt = this.eventForm.get("endDate").value;
            this.optionsApplication = {          
              minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()-1)),
              maxDate: new Date(new Date().setFullYear(new Date(enddt).getFullYear(),new Date(enddt).getMonth(),new Date(enddt).getDate())),
            }; 

            this.optionsStart = {  
              minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()))
            }; break;      
  
          case 'start':
            var dt = this.eventForm.get("endDate").value;
            if(_moment(dt).format('YYYY-MM-DD') < _moment(evnt).format('YYYY-MM-DD')){
               this.eventForm.get("endDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()))); 
            }     
            this.optionsEnd = {
              minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()-1))
           }; 
  
            var publishdt = this.eventForm.get("publishDate").value;     
            var dt = this.eventForm.get("endDate").value;         
            this.optionsApplication = {  
              minDate: new Date(new Date().setFullYear(new Date(publishdt).getFullYear(),new Date(publishdt).getMonth(),new Date(publishdt).getDate()-1)),        
              maxDate: new Date(new Date().setFullYear(new Date(dt).getFullYear(),new Date(dt).getMonth(),new Date(dt).getDate()))
            }; break;
  
           
  
          case 'end':
          
  
            var dt = this.eventForm.get("reportingDeadline").value;
            if(_moment(dt).format('YYYY-MM-DD') <= _moment(evnt).format('YYYY-MM-DD')){
               this.eventForm.get("reportingDeadline").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()+1)));
            }        
            var applicationdt = this.eventForm.get("applicationDate").value;
            if(_moment(applicationdt).format('YYYY-MM-DD') > _moment(evnt).format('YYYY-MM-DD')){
              this.eventForm.get("applicationDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())));
           }        
           
            var publishdt = this.eventForm.get("publishDate").value;
            this.optionsApplication = {  
              minDate: new Date(new Date().setFullYear(new Date(publishdt).getFullYear(),new Date(publishdt).getMonth(),new Date(publishdt).getDate()-1)),        
              maxDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()))
            }; 
            this.optionsReporting = {
                minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()))
            }; break;
        }  
      }
    }
   this.validateTimeInput();
  }
 
 
  getEventDetails() {
    var obj = { EventId: this.eventId, CorporateId: this.corporateId };
    this._service.getDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        this.setOptionsEnable = false;
        var detail = response.data;
        this.imageUrl = detail.pictureUrl;
        this.selectedSkills = detail.skillSets;
        if (detail.masterNgo != null) {
          this.selectedNgoId = detail.masterNgo.masterNgoId;
          this.selectedNgo = detail.masterNgo;
        }

        if(detail.addresses!=null && detail.addresses.length > 0){
          var data = detail.addresses
          for(var i = 0; i < data.length; i++){

            this.addresses.push(new FormGroup({
              addressId: new FormControl(data[i].addressId > 0 ? data[i].addressId : 0),
              cityId: new FormControl({value:data[i].cityId > 0 ? data[i].cityId : '',disabled:false}),
              stateId: new FormControl({value:data[i].stateId > 0 ? data[i].stateId : '',disabled:false}),       
              countryId: new FormControl(data[i].countryId > 0 ? data[i].countryId : '',Validators.required),
              location: new FormControl({value:data[i].location!='' ? data[i].location : '',disabled:false}),
              area: new FormControl({value:data[i].area!='' ? data[i].area : '',disabled:false}),
              zipcode: new FormControl({value:data[i].zipcode!='' ? data[i].zipcode : '',disabled:false}),
              latitude: new FormControl({value:data[i].latitude!='' ? data[i].latitude : '',disabled:false}),
              longitude: new FormControl({value:data[i].longitude!='' ? data[i].longitude : '',disabled:false}),
            }));  

            this.getCountries(i); this.getStates(data[i].countryId,i,false); this.getCities(data[i].stateId,i,false);
          }       
        }

        this.eventForm.setValue({
          eventId: detail.eventId,
          corporateId: detail.corporateId,
          isPublic: false,
          statusId: detail.statusId,
          pictureUrl: detail.pictureUrl,
          IsCertificateRequired: detail.isCertificateRequired,
          title: detail.title,
          description: detail.description,
          publishDate: new Date(detail.publishDate),
          applicationDate: new Date(detail.applicationDate),
          startDate: new Date(detail.startDate),
          endDate: new Date(detail.endDate),
          reportingDeadline: new Date(detail.reportingDeadline),
          goalId: detail.goalId,
          eventContributionTypeId: detail.eventContributionTypeId,
          vacancy: detail.vacancy != null ? detail.vacancy : '',
          bufferVacancy: detail.bufferVacancy,
          hours: detail.hours,
          minutes:detail.minutes,
          cappedHours: detail.cappedHours,
          value: detail.value,
          valueType:this.selectedSkills.length > 0 ? 2 : 1,
          score:detail.score != null ? detail.score : 0,
          //skills: detail.skills,
          skillSets: this.selectedSkills,
          organiserName: '',
          organiserEmail: '',
          organiserMobileNumber: '',
          organiserNgoname: '',
          masterNgoId: detail.masterNgo!=null &&  detail.masterNgo.masterNgoId > 0 ? detail.masterNgo.masterNgoId : "",
          eventType:detail.eventType!=null ? detail.eventType : '',
          isSameDayEvent:detail.isSameDayEvent ? detail.isSameDayEvent : false,
          isWorldWide:detail.isWorldWide ? detail.isWorldWide : false,
          autoApprove:detail.autoApprove!=null ? detail.autoApprove : false,
          rewardDescription:(detail.rewardDescription!=null && detail.rewardDescription!='') ? detail.rewardDescription : '',
          rewardImage:(detail.rewardImage!=null && detail.rewardImage!='') ? detail.rewardImage : '',
          addresses:this.addresses.value,
          startTimeHour:detail.startTimeHour,
          startTimeMin:detail.startTimeMin,
          endTimeHour:detail.endTimeHour,
          endTimeMin:detail.endTimeMin,
          startTimeZone: detail.startTimeHour > 12 ? "pm" : "am",
          endTimeZone: detail.endTimeHour > 12 ? "pm" : "am",
          team: detail.team,
          submitFeedbackImage: detail.submitFeedbackImage
        });

        if(detail.startTimeHour > 12){
          this.eventForm.get("startTimeHour").setValue(parseInt(detail.startTimeHour)-12);
          this.startTimeZone = 12;
        }
        if(detail.endTimeHour > 12){
          this.eventForm.get("endTimeHour").setValue(parseInt(detail.endTimeHour)-12);
          this.endTimeZone = 12;
        }

        if (detail.eventContributionTypeId == 2) {
          this.eventForm.controls["cappedHours"].clearValidators();
          this.eventForm.controls["cappedHours"].disable();
          this.eventForm.controls["cappedHours"].setValue(0);
        }
        else {
          this.eventForm.controls["cappedHours"].enable();
          this.eventForm.controls["cappedHours"].setValidators(Validators.min(0));
        }
        if(detail.rewardDescription!=null && detail.rewardDescription!=''){
          this.openReward = true;
          this.eventForm.get("rewardDescription").setValidators(Validators.required);
        }

        this.setOptionsEnable = true;
        if(detail.statusId==1){
          if(_moment(detail.publishDate).format('YYYY-MM-DD') < _moment(Date.now()).format('YYYY-MM-DD')){          
            this.eventForm.get("publishDate").setValue(new Date(new Date().setFullYear(new Date(Date.now()).getFullYear(),new Date(Date.now()).getMonth(),new Date(Date.now()).getDate()))); 
          }     
        }       
        this.setOptions('publish',detail.publishDate);

        this.isWorldWideActivity(detail.isWorldWide);
      
        if(detail.vacancy!=null){
          this.setApplicationCriteria(false); this.IsOpenApplication = false;
       }
       else {
         this.setApplicationCriteria(true); this.IsOpenApplication = true;
       }
       
     //   let startDate = new Date(detail.startDate);
      //  this.startTimeHour = startDate.getHours();
       // this.startTimeMinute = startDate.getMinutes();
        // this.startTimeZone = 1;
        // if (this.startTimeHour > 12) {
        //   this.startTimeZone = 2;
        //   this.startTimeHour = this.startTimeHour - 12;
        // }
        // let endDate = new Date(detail.endDate);
        // this.endTimeHour = endDate.getHours();
        // this.endTimeMinute = endDate.getMinutes();
        // this.endTimeZone = 1;
        // if (this.endTimeHour > 12) {
        //   this.endTimeZone = 2;
        //   this.endTimeHour = this.endTimeHour - 12;
        // }
      }
    });
  } 

  selectContributionType(typeId) {
    if (typeId == 2) {
      this.eventForm.controls["cappedHours"].clearValidators();
      this.eventForm.controls["cappedHours"].disable();
      this.eventForm.controls["cappedHours"].setValue(0);
    }
    else {
      this.eventForm.controls["cappedHours"].enable();
      this.eventForm.controls["cappedHours"].setValidators(Validators.min(0));
    }
  }

  // selectSkill(skillId: any) {
  //   var s = this.skills.filter(a => a.skillId == skillId);
  //   var skill = s[0];
  //   var exist = this.skillSets.filter(a => a.skillId == skill.skillId);
  //   if (exist.length == 0) {
  //     this.skillSets.push(skill);
  //   }
  //   this.calculateAverageValue();
  // }

  onChangeSkill(skill: any) {    
    this.calculateAverageValue();
  }

  calculateAverageValue() {
    var value = 0;
    this.skills.forEach(e=>{
       this.selectedSkills.forEach(s=>{
         if(e.skillId==s.skillId) {        
             value += e.rate;
         }
       })
    })
  
      var avg = value / this.selectedSkills.length;
      avg = Math.round(avg);
     if(this.selectedSkills.length == 0){
        avg = 0;
     }
    
     this.eventForm.controls["value"].setValue(avg);   
   
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    onKeyDown(): boolean {

        if (this.eventForm.controls.valueType.value == 2) {
            return false;
        }

        return true;
    }
  
    
    setRewardOnOff(event){   
      if(!event.target.checked){
        this.eventForm.get("rewardDescription").clearValidators(); this.eventForm.get("rewardDescription").setErrors(null); 
      } else{
       this.eventForm.get("rewardDescription").setValidators(Validators.required);
      }

      this.openReward = event.target.checked;
   }
   
   uploadReward(file:File){
     
     if(file!=null){

       if(file.size <= 2097152){
           var formData = new FormData();
           formData.append("upload",file);

           this._service.uploadReward(formData).subscribe(response => {
             if(response.isSuccess){
                this.eventForm.get("rewardImage").setValue(response.rewardImage);
             }
             else{
               alert("File not uploaded");
             }
           })
       } 
       else{
            alert("File size exceeds 2 mb");
       }
     } 
     else{
          alert("Please select file");
     }
   }

   deleteRewardImage(){
     this.eventForm.get("rewardImage").setValue('');
   }

   getCountries(i:number){
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.addressCountriesList[i] = response.data;
    });
  }

  setControlsDisable(){
    var addressMappings = this.eventForm.get('addresses') as FormArray;
   
    for(var i=0;i<addressMappings.length; i++){
      var country = addressMappings.controls[i].get("countryId").value
      var state = addressMappings.controls[i].get("stateId").value
      var city =  addressMappings.controls[i].get("cityId").value

      if(country==''){
        addressMappings.controls[i].get("stateId").disable();           
        addressMappings.controls[i].get("cityId").disable();        
        addressMappings.controls[i].get("location").disable();           
        addressMappings.controls[i].get("area").disable();          
        addressMappings.controls[i].get("zipcode").disable();        
        addressMappings.controls[i].get("latitude").disable();      
        addressMappings.controls[i].get("longitude").disable();   
      }else if(state==''){
        addressMappings.controls[i].get("cityId").disable();  
        addressMappings.controls[i].get("location").disable();           
        addressMappings.controls[i].get("area").disable();          
        addressMappings.controls[i].get("zipcode").disable();        
        addressMappings.controls[i].get("latitude").disable();      
        addressMappings.controls[i].get("longitude").disable();   
      }else {
        addressMappings.controls[i].get("location").disable();           
        addressMappings.controls[i].get("area").disable();          
        addressMappings.controls[i].get("zipcode").disable();        
        addressMappings.controls[i].get("latitude").disable();      
        addressMappings.controls[i].get("longitude").disable();
      }
    }
  }

  ngOnInit() {
      var obj = { pageNumber: 1, PageSize: 500, CorporateId: this.corporateId };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
    this._service.getEventGoals(obj).subscribe(response => {
      this.eventGoals = response.list;
    });
    this._service.getEventContributionTypes(obj).subscribe(response => {
      this.eventContributionTypes = response.list;
    });
    this.masterService.getSkills(obj).subscribe(response => {
      if (response.isSuccess) {
        this.skills = response.list;
      }
    });
    this.createForm();
    if (this.eventId > 0) {
      this.getEventDetails(); this.setControlsDisable();
    }

    this.searchNGO();

    this.skillDropdownSettings = {
      singleSelection: false,
      idField:'skillId',
      textField: 'skill',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll:false
    };
  }
}
