import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { AuthenticationService } from '../../../shared/services/authentication.service'
import { AdminMasterService } from '../../../shared/services/master.services'
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})
export class DomainComponent implements OnInit {
  adminLoginId: any;
  corporateId: any;
  domains: any[]=[];
  domainName: string = '';
  domainList: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private service: AdminMasterService,
    private fb: FormBuilder,
    private toastr: ToastrManager
  ) {
    var auth = this.authentication.getToken();
    if (auth == null) {
        this.authentication.logout(); return;
    }
    this.adminLoginId = auth.access_token.adminLoginId;
    this.corporateId = auth.access_token.corporateId;
   }

  onCreateNewDomain(){
    let a = document.getElementById("addDomainBtn") as HTMLElement;
    a.click();
  }

  onClickAdd(){
    if(!(this.domainName.trim() == '')){
      this.addDomain(this.domainName); this.domainName = "";
      
    }else{
      this.toastr.errorToastr("Enter your domain"); 
    }
   
  }

  addDomain(domain:string){
    this.domains.push({"domainName":domain});
    this.saveDomains();
  }

  saveDomains(){
    var request = {"corporateDomains":this.domains,"corporateId":this.corporateId};
    console.log("request",request);
    this.service.addDomain(request).subscribe(response => {
      if(response.isSuccess){
        this.toastr.successToastr("Domain added successfully");
        var e = document.getElementById("modal-dismiss") as HTMLElement;
        e.click();
        this.getDomains();
      }
    },(err)=>{
      this.toastr.errorToastr("Some error occured"); 
    })

    this.domains = [];
  }

  getDomains(){
    var request = {"corporateId":this.corporateId}
    this.service.getDomain(request).subscribe(response => {
       if(response.isSuccess){
          this.domainList = response.domains;
       }
    },(err)=>{
      this.toastr.errorToastr("Some error occured");
    });
  }

  performActionOnDomain(domainId:number){
    var request = {"domainId":domainId}
    this.service.performActionOnDomain(request).subscribe(response => {
      if(response){
         this.getDomains();
      }
   },(err)=>{
     this.toastr.errorToastr("Some error occured");
   });
  }
  

  noWhiteSpace(event) {
    if (event.keyCode === 32) {
        return false;
    }
    return true;
  }

  ngOnInit() {
    this.getDomains();
  }

}
