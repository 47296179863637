import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { SurveyService } from '../../../shared/services/survey.service';
import { AdminMasterService } from '../../../shared/services/master.services';


@Component({
  selector: 'survey-detail',
  templateUrl: './survey-detail.component.html',
})

export class SurveyDetailComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  surveyId: number;
  volunteers: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  nameIcon: string = "sorting";
  genderIcon: string = "sorting";
  cityIcon: string = "sorting";
  stateIcon: string = "sorting";
  countryIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
  errorMessage: string;

  constructor(private _service: SurveyService, private authentication: AuthenticationService, private router: Router, private route: ActivatedRoute) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    router.events.subscribe((response) => {
      this.surveyId = route.snapshot.params['id'];
    });
  }



  getDetails() {
    var obj = { AdminLoginId: this.adminLoginId, CorporateId: this.corporateId, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn, SurveyId: this.surveyId };
    console.log(obj);
    this._service.getSurveyDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        this.volunteers = detail.surveyUsers;
        if(!this.volunteers){

          this.errorMessage = "No Volunteer Added";          
          }
        this.count = detail.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
          if (this.endOn > this.count) {
            this.endOn = this.count;
          }
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Name":
        if (this.nameIcon == "sorting") {
          this.nameIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.nameIcon == "sorting_asc") {
          this.nameIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.nameIcon == "sorting_desc") {
          this.nameIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.genderIcon = "sorting";
        this.cityIcon = "sorting";
        this.stateIcon = "sorting";
        this.countryIcon = "sorting";
        break;
      case "Gender":
        this.nameIcon = "sorting";
        if (this.genderIcon == "sorting") {
          this.genderIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.genderIcon == "sorting_asc") {
          this.genderIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.genderIcon == "sorting_desc") {
          this.genderIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.cityIcon = "sorting";
        this.stateIcon = "sorting";
        this.countryIcon = "sorting";
        break;
      case "City":
        this.nameIcon = "sorting";
        if (this.cityIcon == "sorting") {
          this.cityIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.cityIcon == "sorting_asc") {
          this.cityIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.cityIcon == "sorting_desc") {
          this.cityIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.genderIcon = "sorting";
        this.stateIcon = "sorting";
        this.countryIcon = "sorting";
        break;
      case "State":
        this.nameIcon = "sorting";
        if (this.stateIcon == "sorting") {
          this.stateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.stateIcon == "sorting_asc") {
          this.stateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.stateIcon == "sorting_desc") {
          this.stateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.cityIcon = "sorting";
        this.genderIcon = "sorting";
        this.countryIcon = "sorting";
        break;
      case "Country":
        this.nameIcon = "sorting";
        if (this.countryIcon == "sorting") {
          this.countryIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.countryIcon == "sorting_asc") {
          this.countryIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.countryIcon == "sorting_desc") {
          this.countryIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.cityIcon = "sorting";
        this.stateIcon = "sorting";
        this.genderIcon = "sorting";
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getDetails();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getDetails();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getDetails();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getDetails();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getDetails();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getDetails();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getDetails();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getDetails();
    }
  }

  performAction(volunteer: any, statusId: number, status: string) {
    var obj = { UserLoginId: volunteer.userLoginId, StatusId: statusId };
    this._service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        volunteer.statusId = statusId;
        volunteer.status = status;
      }
    });
  }


  ngOnInit() {
    this.getDetails();
  }

}
