import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { NgDatepickerModule, DatepickerOptions } from 'ng2-datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import * as frLocale from 'date-fns/locale/fr';

import { SuperadminRoutingModule } from './superadmin-routing.module';
import { EventListComponent } from './event/public/event-list.component';
import { EventPrivateListComponent } from './event/private/event-private-list.component';
import { EventPrivateCreateComponent } from './event/private/event-private-create.component';
import { EventDetailComponent } from './event/details/event-detail.component';

import { EventCreateComponent } from './event/public/event-create.component';
import {CampaignListComponent} from './campaign/campaign-list.component';
import {CampaignCreateComponent} from './campaign/campaign-create.component';
import { VolunteersListComponent } from './volunteers/volunteers-list.component';
import { VolunteersCreateComponent } from './volunteers/volunteers-create.component';
import { VolunteersDetailComponent } from './volunteers/volunteers-detail.component';

import { GroupCreateComponent} from './group/group-create.component';
import { GroupListComponent} from './group/group-list.component';
import { CampaignTaskListComponent} from './campaign/task/campaign-tasklist.component';
import {CampaignTaskCreateComponent } from './campaign/task/campaign-taskcreate.component';
import {CampaignTaskDetailComponent} from './campaign/task/campaign-taskdetail.component';
import {CampaignDetailComponent} from './campaign/campaign-detail.component';
import {AdminProfileComponent} from './profile/admin-profile.component';

import { SurveyListComponent } from './survey/surveyList.component';
import {SurveyCreateComponent } from './survey/create/survey-create.component';
import { SurveyDetailComponent } from './survey/detail/survey-detail.component';
import { SurveyUserDetailComponent } from './survey/response/survey-response.component';

import { FeedbackListComponent } from './feedback/feedback-list.component';
import { MediaListComponent } from './media/media-list.component';



import { UsersListComponent } from './users/users-list.component';
import { UsersCreateComponent } from './users/users-create.component';

import { WallPostListComponent } from './wallpost/wallpost-list.component';
import { WallPostCreateComponent} from './wallpost/wallpost-create.component';
import { WallPostDetailComponent} from './wallpost/wallpost-detail.component';

import { NGOCreateComponent} from './ngo/ngo-create.component';
import { NGOListComponent} from './ngo/ngo-list.component';

import { CompaniesCreateComponent} from './masters/companies-create.component';
import { CompaniesListComponent} from './masters/companies-list.component';
import { CountriesListComponent} from './masters/countries-list.component';
import { SupporterLogosComponent} from './masters/supporterlogos.component';



import { PushNotificationComponent} from './pushnotification/pushnotification.component';
import { ReportListComponent} from './report/report-list.component';
import { SkillComponent } from './masters/skill.component';
import { MediaSelectComponent } from './media/select-media/selectMedia.component';
import { ChartModule } from 'angular2-chartjs';
import { DefaultComponent } from './default/default.component';

import { LearningZoneListComponent} from './learningzone/learningzone-list.component';
import { LearningZoneCreateComponent} from './learningzone/learningzone-create.component';

import { EventDetailComponentWithoutPN } from './event/details/event-detailwithoutPN.component';
import { LearningZoneDetailComponent } from './learningzone/learningZone-detail.component';
import { CreateQuizComponent } from './quiz/create-quiz/create-quiz.component';
import { QuizListComponent } from './quiz/quiz-list/quiz-list.component';
import { UpdateQuizComponent } from './quiz/update-quiz/update-quiz.component';
import { QuestionListComponent } from './quiz/quiz-question/question-list/question-list.component';
import { CreateQuestionComponent } from './quiz/quiz-question/create-question/create-question.component';
import { UpdateQuestionComponent } from './quiz/quiz-question/update-question/update-question.component';
import { CurriculumListComponent } from './campaign/curriculum/curriculum-list.component';
import { CurriculumComponent } from './campaign/curriculum/curriculum.component';
import { CampaignPublicListComponent } from './campaign/campaign-list-public.component';
import { HoursDonatedComponent} from './hoursdonated/hours-donated.component';
import { BeneficiaryListComponent } from './beneficiary/beneficiary-list/beneficiary-list.component';
import { QuizUserComponent } from './quiz/quiz-user/quiz-user.component';

import { QuillModule } from 'ngx-quill';
import { CampaignBasedOnKindComponent } from './campaign/campaign-based-on-kind/campaign-based-on-kind.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { EditorconfigModule } from './../shared/models/editorconfig/editorconfig.module';
import { BirthdayListComponent } from './webusers/birthday-list.component';
import { ContactusListComponent } from './webusers/contactus-list.component';





@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SuperadminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    NgDatepickerModule,
    ChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    QuillModule.forRoot(),
    CKEditorModule,
    EditorconfigModule
  ],
  declarations: [EventListComponent, EventCreateComponent, CampaignListComponent, CampaignCreateComponent, VolunteersListComponent, EventPrivateListComponent, EventPrivateCreateComponent, VolunteersCreateComponent, GroupCreateComponent, GroupListComponent,
    CampaignTaskListComponent, EventDetailComponent, CampaignTaskCreateComponent, CampaignTaskDetailComponent, CampaignDetailComponent, AdminProfileComponent, SurveyListComponent, SurveyCreateComponent, SurveyDetailComponent, UsersListComponent, UsersCreateComponent, 
    SurveyUserDetailComponent, FeedbackListComponent, MediaListComponent, VolunteersDetailComponent,WallPostListComponent,WallPostCreateComponent,WallPostDetailComponent,NGOCreateComponent,NGOListComponent, CompaniesCreateComponent,CompaniesListComponent,CountriesListComponent,
    PushNotificationComponent, ReportListComponent, SkillComponent, MediaSelectComponent, DefaultComponent,SupporterLogosComponent,LearningZoneListComponent,LearningZoneDetailComponent,LearningZoneCreateComponent,EventDetailComponentWithoutPN,CreateQuizComponent, QuizListComponent, UpdateQuizComponent, QuestionListComponent, CreateQuestionComponent,CurriculumListComponent,CurriculumComponent,
      UpdateQuestionComponent, CampaignPublicListComponent, HoursDonatedComponent, BeneficiaryListComponent, QuizUserComponent, CampaignBasedOnKindComponent, BirthdayListComponent, ContactusListComponent]

})
export class SuperadminModule { }
