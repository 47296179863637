import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { AuthenticationService } from '../../../shared/services/authentication.service'
import { TestimonialService } from '../../../shared/services/testimonial.service'
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
//import { getDOM } from '@angular/platform-browser/src/dom/dom_adapter';


@Component({
  selector: 'app-testimonial-create',
  templateUrl: './testimonial-create.component.html',
  styleUrls: ['./testimonial-create.component.scss']
})
export class TestimonialCreateComponent implements OnInit {

  testimonialForm:FormGroup;
  adminLoginId:number;
  image:File;
  imageUrl:string;
  testimonialDetail:any;
  testimonialId:number;
  validateForm:boolean;
  fileUploadText:string;
  buttonText:string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private service: TestimonialService,
    private fb: FormBuilder,
    private toastr: ToastrManager) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
      this.adminLoginId = details.access_token.adminLoginId;
      this.testimonialId = this.route.snapshot.params['id'];
      console.log(this.testimonialId);
     }

     save(){
      this.validateForm = true;
      if(this.testimonialForm.valid) {
        var formData = new FormData();
        formData.append("Name",this.testimonialForm.value.Name);
        formData.append("Description",this.testimonialForm.value.Description);
        formData.append("Ratings",this.testimonialForm.value.Rating);
        formData.append("Link",this.testimonialForm.value.Link);
        formData.append("Uploaded",this.image);
        formData.append("AdminLoginId",this.adminLoginId.toString());
      
        if(this.testimonialId!=null && this.testimonialId!=0) {
           formData.append("TestimonialId",this.testimonialId.toString());
        }
  
        this.service.createTestimonial(formData).subscribe(response=> {
          if (response.isSuccess) {
            this.toastr.successToastr(response.message);
            this.router.navigate(['/admin/testimonial'])
          }
          else {
            this.toastr.errorToastr(response.message);
          }
         })
      }
      
     }

     whiteSpaceValidator(
      control: FormControl
  ): { [key: string]: any } | null {
      var val = control.value;
      for (var i = 0; i <= val.length - 1; i++) {
          val = val.replace("&nbsp;", "");
      }
      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }
  
     onSelectFile(event){
       
      var file = event.target.files[0];
      event.target.value = '';
      console.log(file);
      if(file.size > 2097152) {
        this.toastr.errorToastr("max upload limit is 2mb");
      }
     else{
      
       var fileReader = new FileReader();
       fileReader.onload = (event: any) => {
          this.imageUrl = event.target.result;        
       }
       fileReader.readAsDataURL(file);     
       this.image = file;
      this.fileUploadText = "Update Image";    
     }
    }

     numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  
    }

    keyDownHandler(event) {
      if (event.target.value.length > 0) {
        return true;
      } else {
        if (event.keyCode === 32) {
          return false;
        }
      }
    }

    onDelete(event){
      if(this.testimonialDetail!=null && this.testimonialDetail.avatarImageUrl!=null && this.testimonialDetail.avatarImageUrl!="") {
        this.service.deleteTestimonialImage({"AdminLoginId":this.adminLoginId,"TestimonialId":this.testimonialId}).subscribe(response => {
          if(response.isSuccess){
            this.toastr.successToastr("Image deleted successfully");
            this.fileUploadText = "Add Image";
            this.imageUrl = null;      
            this.testimonialDetail.avatarImageUrl = null;         
          }
          else{
            this.toastr.errorToastr("Unable to delete image");
          }
        })
      }
      else{
        this.image = null;
        this.imageUrl = null;
        this.fileUploadText = "Add Image";
      }
      
       
    }

     get f() {
      return this.testimonialForm.controls;
    }

    
  
  ngOnInit() {
    this.testimonialForm = this.fb.group({
      Name:['',[Validators.required,this.whiteSpaceValidator]],
      Description:['',[Validators.required,this.whiteSpaceValidator]],
        Link: ['', [Validators.pattern(/((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i)]],
        Rating: ['0']
    })
    this.fileUploadText = "Add Image";
    this.buttonText = "Save";

    if(this.testimonialId > 0) {
      this.service.getTestimonialDetail({"AdminLoginId":this.adminLoginId,"TestimonialId":this.testimonialId}).subscribe(response => {
        console.log(response);
        if (response.isSuccess) {
            
            this.testimonialDetail = response.data;
            this.testimonialForm.setValue({
               Name:this.testimonialDetail.name,
               Description:this.testimonialDetail.description,
               Link:this.testimonialDetail.youtubeLink,
               Rating:this.testimonialDetail.ratings
            })
  
            this.buttonText = "Update";
            this.imageUrl = this.testimonialDetail.avatarImageUrl;
            
            if(this.imageUrl!=null && this.imageUrl!=""){
              this.fileUploadText = "Update Image";
            }
            else{
              this.fileUploadText = "Add Image";
            }
        }
      });
    }
    
  }

}
