import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Router ,ActivatedRoute} from '@angular/router';
import { AdminMasterService } from '../../shared/services/master.services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import { getMonth } from 'ngx-bootstrap/chronos';
import { MONTH } from 'ngx-bootstrap/chronos/units/constants';


@Component({
  selector: 'volunteers-create',
  templateUrl: './volunteers-create.component.html',
})

export class VolunteersCreateComponent implements OnInit {


  volunteersForm: FormGroup;
  validateForm: boolean = false;
  adminLoginId: number;
  states: any[] = [];
  cities: any[] = [];
  errorMessage: string;
    countries: any[] = [];
    isSubmitEnable: boolean;
  volunteerDetail: any;
  userLoginId: any;
  DOB: DatepickerOptions = {
    //minDate: new Date((new Date().setFullYear((new Date(Date.now()).getFullYear() )))), // Minimal selectable date
    maxDate: new Date(Date.now()),
    minDate: new Date(Date.now()),
    minYear: getYear(new Date()) - 72, // minimum available and selectable year
    maxYear: getYear(new Date()) + 3,   
  
  };


  constructor(private masterService: AdminMasterService, private authentication: AuthenticationService, private _service: AdminVolunteerService, private router: Router,private route:ActivatedRoute, private _location: Location,private toaster:ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==2){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.userLoginId = this.route.snapshot.params["id"]; 
  }

  createForm() {
    this.volunteersForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      lastName: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      countryId: new FormControl(''),
      stateId: new FormControl(''),
      cityId: new FormControl(''),
      gender: new FormControl(''),
      //email: new FormControl({value:'',disabled:true}, [Validators.required,Validators.email,this.whiteSpaceValidator]),
      email: new FormControl({value:'',disabled:true}, [Validators.required,Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]),
      countryCode: new FormControl({value:'+91',disabled:false}, [Validators.maxLength(10)]),
      mobileNumber: new FormControl({value:'',disabled:false}, [Validators.maxLength(15)]),
      //profilePicture: new FormControl('', Validators.required),
      empID: new FormControl(),
      DOB: new FormControl(),
    });
  }

  save() {
    this.validateForm = true;
        if (this.volunteersForm.valid) {
            this.isSubmitEnable = false;
            //this.volunteersForm.get("email").enable();
            //this.volunteersForm.get("mobileNumber").enable();
            var admin = { "adminLoginId": this.adminLoginId }
            var val = this.volunteersForm.value;
            var user = {"userLoginId":this.userLoginId}
            this._service.addVolunteer({ ...admin, ...val, ...user }).subscribe(response => {
        if (response.isSuccess) {
          if(response.alreadyExist == true){
            this.toaster.errorToastr("Volunteer already Exist");
          }
          else{
            this.router.navigate(['/sa/user/volunteers']);
            }           
        }
        else {

            this.isSubmitEnable = true;
          if(response.responseMessage!=null && response.responseMessage!=''){
            this.toaster.errorToastr(response.responseMessage);
         }
        }
            }, 
        (err) => {
                     this.toaster.errorToastr("Some error occured"); this.isSubmitEnable = true;
       });

      
    }
  }

  getCities(stateId: any) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }


  getStates(countryId: any) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }


  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  getDetail() {
    var obj = { UserLoginId: this.userLoginId};
    this._service.getVolunteerDetail(obj).subscribe(response => {
     
      if (response.isSuccess) {
        this.volunteerDetail = response.data;
        this.volunteersForm.setValue({
          firstName: this.volunteerDetail.firstName,
          lastName: this.volunteerDetail.lastName,
          countryId: this.volunteerDetail.countryId > 0 ? this.volunteerDetail.countryId : '',
          stateId: this.volunteerDetail.stateId > 0 ? this.volunteerDetail.stateId : '',
          cityId: this.volunteerDetail.cityId > 0 ? this.volunteerDetail.cityId : '',
          gender: this.volunteerDetail.gender!=null && this.volunteerDetail.gender.trim()!='' ? this.volunteerDetail.gender : '',
          email: this.volunteerDetail.email,
          mobileNumber: this.volunteerDetail.mobileNumber,
          countryCode: this.volunteerDetail.countryCode,        
          empID: this.volunteerDetail.empID,
          //DOB: this.volunteerDetail.dob,
          DOB: this.volunteerDetail.dob ? this.volunteerDetail.dob : new Date((new Date().setFullYear((new Date(Date.now()).getFullYear())))),
        });
if(this.volunteerDetail.countryId > 0){
  this.getStates(this.volunteerDetail.countryId);
  if(this.volunteerDetail.stateId > 0){
    this.getCities(this.volunteerDetail.stateId);
  }
        
}
      
      }
    });
  }

// only number validation
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}

keyDownHandler(event) {
  if (event.target.value.length > 0) {
    return true;
  } else {
  
  }
} 

  backClicked() {
    this._location.back();
  }
  
  options: DatepickerOptions = {
    //minDate: new Date((new Date().setFullYear((new Date(Date.now()).getFullYear() )))), // Minimal selectable date
    maxDate: new Date(Date.now()),
    minYear: getYear(new Date()) - 72,
    maxYear: getYear(new Date()) + 3, 
  };
  
  
  setOptions(evnt: any) {
    this.DOB = {
      minDate: evnt, // Minimal selectable date
        maxDate: new Date(Date.now())        
    };
  }

  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
      this.createForm();
      if(this.userLoginId > 0){
        
        this.getDetail();       
       
      }else{
        this.volunteersForm.get("email").enable();
        this.volunteersForm.get("mobileNumber").enable();
        this.volunteersForm.get("countryCode").enable();
      }

      this.isSubmitEnable = true;
    
  }

}
