import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
    selector: 'create-list',
    templateUrl: './users-create.component.html',
  })

  export class UsersCreateComponent implements OnInit {

    adminLoginId: number;
    adminProfileUrl: string;
    adminProfileFile: any;
    fullname:string;
    personalInfoForm: FormGroup;
    validateForm: boolean = false;
    errorMessage:string

    


  constructor( private router: Router,private _service: AuthenticationService, private route: ActivatedRoute,private authentication: AuthenticationService,private masterService: AdminMasterService) { 
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    router.events.subscribe((response) => {
      this.adminLoginId = route.snapshot.params['id'];
    });
    
  }


    createForm() {
        this.personalInfoForm = new FormGroup({
        adminFirstName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
        adminLastName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
        adminProfilePicture:new FormControl(''),
        adminEmail: new FormControl('', [Validators.required,Validators.email, this.whiteSpaceValidator]),
        adminMobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      
      });   
    }

    whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
    {
        var val = control.value;
  
        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }

    UpdatePersonalProfile(){
      console.log("CALLED");
        this.validateForm = true;
        if(this.adminLoginId>0){
        if (this.personalInfoForm.valid) {
          this.personalInfoForm.value.adminLoginId = this.adminLoginId;
          this._service.updateAdminProfile(this.personalInfoForm.value).subscribe(response => {
            if (response.isSuccess) {
            this.router.navigate(['/sa/users']);
      
            }
          });
        }
    }
    else{
      this.validateForm = true;
        if (this.personalInfoForm.valid) {
          this._service.createUsers(this.personalInfoForm.value).subscribe(response =>{
            console.log(response);
            if(!response.data.isExist){
              if (response.isSuccess) {
                console.log("Create Success");
              this.router.navigate(['/sa/users']);

                   if (this.adminProfileFile != null && this.adminProfileFile != undefined) {
                       var obj = { adminLoginId:response.data.adminLoginId, file: this.adminProfileFile };
                       this._service.uploadProfile(obj).subscribe(response => {
                         if (response.isSuccess) {
                           console.log("Upload Success");
   
                           this.adminProfileUrl = response.data.profilePicture;
                         }
                       });
                     }
               }
            }
           
            else{
              if(response.data.isExist){
                this.errorMessage = "User Already Exist with this E-mail / Mobile";

              }else{

                this.errorMessage = "Some Error Occurred";

              }
            }
          });
        }
        else{

        }
        
    }
      }

      getProfile() {
        var obj = { adminloginId: this.adminLoginId};
        this._service.getAdminProfile(obj).subscribe(response => {
            if (response.isSuccess) {
              var detail = response.data;
              this.adminProfileUrl = detail.adminProfilePicture;
              this.fullname = detail.adminFirstName + " " + detail.adminLastName;
              this.personalInfoForm.setValue({
                adminEmail: detail.adminEmail,
                adminFirstName: detail.adminFirstName,
                adminLastName: detail.adminLastName,
                adminMobileNumber: detail.adminMobileNumber,
                adminProfilePicture: detail.adminProfilePicture,
                
              });
    
            }
             });
          }

          selectProfileUrl(event){
            var files = event.target.files;
            this.adminProfileFile = files[0];
              var reader = new FileReader();
              reader.onload = (e: any) => {
                this.adminProfileUrl = e.target.result;
              }
              reader.readAsDataURL(files[0]);
              if(this.adminLoginId>0){

                if (this.adminProfileFile != null && this.adminProfileFile != undefined) {
                  var obj = { adminLoginId:this.adminLoginId, file: this.adminProfileFile };
                  this._service.uploadProfile(obj).subscribe(response => {
                    if (response.isSuccess) {
                      this.adminProfileUrl = response.data.profilePicture;
                    }
                  });
                }
              }
    
          }
      
          
    ngOnInit() {

      this.createForm();

      if(this.adminLoginId>0){
        this.getProfile();
      }
     
      }
  }
