import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from "../../shared/services/authentication.service";
import { Location } from '@angular/common';


@Component({
    selector: 'ngo-list',
    templateUrl: './ngo-list.component.html',
  })

  export class NGOListComponent implements OnInit {

  ngolist:any[];
  pageNumber: number = 1;
  pageSize: number = 10;
  startFrom: number = 1;
  count: number;
  endOn: number = this.pageSize;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  numberOfPages: number;
  searchTerm:string = "";
 corporateId: number;
 adminLoginId: number;



    constructor( private _service: MasterNGOService, private authentication: AuthenticationService,private loc:Location) { 
      var details = this.authentication.getToken();
 if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==1){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;  
     
    
    }



    getNGOList(){
      var obj = { SearchTerm: this.searchTerm ,pagenumber:this.pageNumber , pagesize:this.pageSize, CorporateId: this.corporateId};
        this._service.NGOList(obj).subscribe(response => {
         if (response.isSuccess) {
         this.ngolist = response.list;
         this.count = response.totalCount;
         if (this.count == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.count <= this.pageSize) {
            this.endOn = this.count;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
            if (this.endOn > this.count) {
              this.endOn = this.count;
            }
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
            this.endOn = this.count;
        }
         }
     });
}
firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getNGOList();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getNGOList();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getNGOList();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getNGOList();
    }
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getNGOList();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getNGOList();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getNGOList();
}

deleteNgo(masterNgoId: number) {
    var obj = { masterNgoId:masterNgoId};
    console.log(obj);
    this._service.deleteNGO(obj).subscribe(response => {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getNGOList();
    });
  }
  
    ngOnInit() {

      // this.getNGOList();
     
      }

  }
