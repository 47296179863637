import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup,FormBuilder, Validators } from '@angular/forms';
import { AdminCampaignService } from '../../shared/services/campaigns.service';
import { AdminEventsService } from '../../shared/services/events.services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminMasterService } from '../../shared/services/master.services';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import * as _moment from 'moment';
import { EditorconfigModule } from '../../shared/models/editorconfig/editorconfig.module';
import 'quill-emoji/dist/quill-emoji.js';


@Component({
  selector: 'campaign-create',
  templateUrl: './campaign-create.component.html',
})

export class CampaignCreateComponent implements OnInit {
 
  corporateId: number;
  adminLoginId: number; 
  campiagnId:number;
  IsPublic = true;
  statusId = 2;
  IsSubmitting = false; 
  validateForm = false;
  file: any;
  imageUrl = '';
  campaignForm: FormGroup;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = []; 
  NGOs: any[] = [];
  selectedNGOs:any[] = [];
  listOfKinds: any[] = [];
  listOfGoals: any[] = [];
  corporates: any[] = []; 
  corporatesSelected: any[] = [];
  searchTerm = '';
  campaign:any;

  editorUrl:string = '';
  editorConfiguration:any;

  corporateDropdownSettings: {};
  ngoDropdownSettings: {};

  startDateOptions: DatepickerOptions = {
     minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) 
  };
  endDateOptions: DatepickerOptions = {
     minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) 
  };

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
   
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
   
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
   
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
   
      ['clean'],                                         // remove formatting button
      ['emoji'],
      ['link','image','video'],
                               // link and image, video
    ],
    "emoji-toolbar": true
  };

  constructor(
    private _service: AdminCampaignService, 
    private authentication: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private formBuilder:FormBuilder,
    private masterService: AdminMasterService,
    private ngoService: MasterNGOService,
    private editorConfig:EditorconfigModule) {

    var authToken = this.authentication.getToken();
    if(authToken == null){
      this.authentication.logout(); return;
    } else {
      this.adminLoginId = authToken.access_token.adminLoginId;
      this.corporateId = authToken.access_token.corporateId;

      var login = authToken.access_token;
      if(login.role==1){
        this.authentication.logout(); return;
      }    
    }
   
    this.campiagnId = this.route.snapshot.params["id"]; 
    this.IsPublic = this.route.snapshot.params["isPublic"]>0 ? true : false;  
    this.editorConfiguration = this.editorConfig.configuration;
    this.editorUrl = this.editorConfig.editorUrl;
  }

  createForm() 
  {
     this.campaignForm = this.formBuilder.group({
      title: ['', [Validators.required, this.whiteSpaceValidator]],
      description: ['', [Validators.required, this.whiteSpaceValidator]],
      startDate: [new Date(new Date().setDate(new Date(Date.now()).getDate())), Validators.required],
      endDate: [new Date(new Date().setDate(new Date(Date.now()).getDate())), Validators.required],
      ngos: [[], Validators.required],
      countryId: ['', Validators.required],
      stateId: '',
      cityId: '',
      pictureUrl: '',
      IsCertificateRequired: true,
      IsFeatured: false,
      GoalId: ['', Validators.required],
      MinimumVolunteerHours: [''],
      MinimumVolunteerMinutes: [''],
      score: ['', Validators.required],
      campaignType: ['', Validators.required],
      kindId: ['', Validators.required],
      team: false
    });
  }

  save() {
   
    this.validateForm = true; console.log("form",this.campaignForm)
    if (this.campaignForm.valid && this.isvalidTime) {

      let startDateLine = new Date(new Date(this.campaignForm.value.startDate).toUTCString());
      startDateLine.setHours(startDateLine.getHours() + 5);
      startDateLine.setMinutes(startDateLine.getHours() + 30);
      this.campaignForm.get("startDate").setValue(startDateLine);

      let endDateLine = new Date(new Date(this.campaignForm.value.endDate).toUTCString());
      endDateLine.setHours(endDateLine.getHours() + 5);
      endDateLine.setMinutes(endDateLine.getHours() + 30);
      this.campaignForm.get("endDate").setValue(endDateLine);

      if(this.imageUrl==null || this.imageUrl.trim()==''){
        this.campaignForm.get("pictureUrl").setValue('');
      }

      var formValue = this.campaignForm.value;
      var newProp = {
        "corporateId":this.corporateId,
        "isPublic": this.IsPublic,
        "adminLoginId": this.adminLoginId,
        "statusId": this.statusId,
        "campaignId": this.campiagnId,
        "imageUrl":this.imageUrl
      }
      
      this.IsSubmitting = true;
      this._service.createCampaigns({ ...formValue, ...newProp }).subscribe(response => {
        if (response.isSuccess) {       
          this.navigateBack();
        }
        else {
          this.IsSubmitting = false;
        }
      }, (err) => {
        this.IsSubmitting = false;
      });
    }
    else{
      this.IsSubmitting = false;
    }
  }

  getCampaignDetails() {
    this._service.getCampaignDetail({ "campaignId": this.campiagnId }).subscribe(response => {
      if (response.isSuccess) {

        this.IsPublic = response.data.isPublic;
        this.selectedNGOs = response.data.ngos;
        this.imageUrl = response.data.imageUrl;
        this.IsPublic = response.data.isPublic;

        if (response.data.countryId > 0) {
          this.getStates(response.data.countryId);
        }
        if (response.data.stateId > 0) {
          this.getCities(response.data.stateId);
        }
       
        this.campaignForm.setValue({
          title: response.data.title,
          description: response.data.description,
          startDate: new Date(response.data.startDate),
          endDate: new Date(response.data.endDate),      
          ngos: response.data.ngos,
          kindId: response.data.kindId > 0 ? response.data.kindId : '',
          countryId: response.data.countryId,
          stateId: response.data.stateId > 0 ? response.data.stateId : '',
          cityId: response.data.cityId > 0 ? response.data.cityId : '',
          pictureUrl: response.data.imageUrl,
          IsCertificateRequired: response.data.isCertificateRequired,
          IsFeatured: response.data.isFeatured != null ? response.data.isFeatured : false,
          score: response.data.score > 0 ? response.data.score : '',
          GoalId: response.data.goalId > 0 ? response.data.goalId : '',
          campaignType: response.data.campaignType > 0 ? response.data.campaignType : '',
          MinimumVolunteerHours: response.data.minimumVolunteerHours > 0 ? response.data.minimumVolunteerHours : '',
          MinimumVolunteerMinutes: response.data.minimumVolunteerMinutes > 0 ? response.data.minimumVolunteerMinutes : '',
          team:response.data.team
        });      
      }
    });
  }



  get isvalidTime(){   
    if(this.fc.MinimumVolunteerHours.value == '' && this.fc.MinimumVolunteerMinutes.value == '')
        return false;    
     return true;
  }

  get fc(){
    return this.campaignForm.controls;
  }

  selectImage(event) {
    var file = event.target.files[0]
    if (file != null) {
        if (file.size > 2097152) {
            alert("Upload file upto 2 mb only");
            return;
        }
        this.uploadImage(file);      
    }
    event.target.value = '';
  }

  uploadImage(file:any){
    if (file != null && file != undefined) {
      var imageData = new FormData();
      imageData.append("upload",file);
      this._service.uploadImage(imageData).subscribe(response => {
        if (response.isSuccess) {
          this.imageUrl = response.data.imageUrl;
        }
      });
    }
  }

  deleteImage(){
    this.imageUrl = ''; this.file = null;
  }
  
  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      var fromDate = _moment(f.value).format('YYYY-MM-DD');
      var toDate = _moment(t.value).format('YYYY-MM-DD');
      if (fromDate > toDate) {
        return {
          endDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  setOptions(controlName: string, evnt: any) {
    switch (controlName) {
      case 'start':
        this.endDateOptions = {
          minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()-1))
        };
        var dt = this.campaignForm.get("endDate").value;
        if(_moment(dt).format('YYYY-MM-DD') < _moment(evnt).format('YYYY-MM-DD')){
          this.campaignForm.get("endDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())));
        }
        break;
      default:
        break;
    }
  }

  draft() {
    this.statusId = 1;
  }
  publish(){
    this.statusId = 2;
  }

  selectValueTypeforCertificate(value: string) {
    switch (value) {
      case "1": this.campaignForm.get("IsCertificateRequired").setValue(true); break;
      case "2": this.campaignForm.get("IsCertificateRequired").setValue(false); break;
    }
  }

  onSelectFeatured(checked: boolean) {
    this.campaignForm.get("IsFeatured").setValue(checked);
  }
  canceltapped() {
    this.navigateBack();
  }

  navigateBack() {
    var routeParams = this.route.snapshot.url;
    var route = "/admin/" + routeParams[0].path + "/" + routeParams[1].path;
    this.router.navigateByUrl(route);
  }

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null {
    var val = control.value;
    for (var i = 0; i <= val.length - 1; i++) {
      val = val.replace("&nbsp;", "");
    }
    if (val.trim() == '') {
      return { 'hasWhiteSpace': true }
    }
    else {
      return null;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getCampaignKinds(){
    this._service.getKinds().subscribe(response => {
        if(response.isSuccess){
          this.listOfKinds = response.list;
        }
    });
  }

  getNGO() {
    var obj = { PageSize: 1000, PageNumber: 1 };
    this.ngoService.searchNGO(obj).subscribe(response => {
      if (response.isSuccess) {
        this.NGOs = response.list;    
      }
    });
  }

  getCountries(){
    var obj = { pageNumber: 1, PageSize: 500 };
      this.masterService.getCountries(obj).subscribe(response => {
        this.countries = response.data;
      });
  }

  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }

  getGoals(){
    this._service.getCampaignGoals({ pageNumber: 1, PageSize: 500 }).subscribe(response =>{
      this.listOfGoals = response.list;
    });
  }

  updateMultiselectDropdownSettings(){
    this.ngoDropdownSettings = {
      singleSelection: true,
      idField:'masterNgoId',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  ngOnInit() {
    
    this.createForm();   
    this.getCountries();
    this.getNGO(); 
    this.getGoals();
    this.getCampaignKinds();
    this.updateMultiselectDropdownSettings();
    
    if (this.campiagnId > 0) {
        this.getCampaignDetails();
    } 
  }

}
