import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { AdminMasterService } from '../../../shared/services/master.services';
import * as _moment from 'moment';
import { EditorconfigModule } from '../../../shared/models/editorconfig/editorconfig.module';
import 'quill-emoji/dist/quill-emoji.js';


@Component({
  selector: 'campaign-taskcreate',
  templateUrl: './campaign-taskcreate.component.html',
})

export class CampaignTaskCreateComponent implements OnInit {


  adminLoginId: number;
  campaigntaskForm: FormGroup;
  validateForm: boolean = false;
  campaignId: number;
  statusId: number = 2;
  taskId: number;
  file: any;
  imageUrl: string;
  eventGoals: any[] = [];
  

  campaignDetail: any;
    startDateOptions: DatepickerOptions = {
        minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
    };
    endDateOptions: DatepickerOptions = {
        minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
    };

  skillSets: any[] = [];
  skills: any[] = [];
  showSkills: boolean = false;
  isSubmitEnabled:boolean = true;
  skillDropdownSettings: {};
  maxHours:any[] = [0,1,2,3,4,5,6,7,8,9,10,11,12];
  maxMinutes:any[] = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,
    31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59];
  startTimeZone:number=0;
  endTimeZone:number = 0;
  invalidEndTime: boolean = false;
  setOptionsEnabled: boolean = true;
  editorUrl:string = '';
  editorConfiguration:any;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
   
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
   
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
   
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
   
      ['clean'],                                         // remove formatting button
   
      ['emoji'],
      ['link','image','video'],                        // link and image, video
    ],
    "emoji-toolbar": true
  };

    constructor(private _service: AdminCampaignService, private route: ActivatedRoute, private authentication: AuthenticationService, private router: Router, private eventService: AdminEventsService, private masterService: AdminMasterService, private location: Location,private editorConfig:EditorconfigModule) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==2){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.campaignId = route.snapshot.params['id'];
      this.taskId = route.snapshot.params['taskid'];
    });

    this.editorConfiguration = this.editorConfig.configuration;
    this.editorUrl = this.editorConfig.editorUrl;
  }

    whiteSpaceValidator(
        control: FormControl
    ): { [key: string]: any } | null {
        var val = control.value;

        for (var i = 0; i <= val.length - 1; i++) {
            val = val.replace("&nbsp;", "");
            
        }
        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }
      return true;
  
  }


  selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }

  // selectValueType(type: number) {
  //   if (type == 1) {
  //     this.showSkills = false;
  //   }
  //   else {
  //     this.showSkills = true;
  //   }
  // }

    selectValueType(type: number) {

        this.campaigntaskForm.get("skillSets").setValue([]);
        this.campaigntaskForm.get("value").setValue('');

    if (type == 1) {
      this.showSkills = false;
      this.campaigntaskForm.get("skillSets").clearValidators();
      this.campaigntaskForm.get("skillSets").setErrors(null);
    }
    else {
      this.showSkills = true;
      this.campaigntaskForm.get("skillSets").setValidators(Validators.required);
    }
  }

  createForm() {

    this.campaigntaskForm = new FormGroup({
        title: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
        description: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      startDate: new FormControl(new Date(), Validators.required),
      endDate: new FormControl(new Date(), Validators.required),
    //  maximumHours: new FormControl('', Validators.required),
    //  minutes: new FormControl('', Validators.required),
      pictureUrl: new FormControl(''),
    //  goalId: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
      valueType: new FormControl(1),
      autoApprove: new FormControl(false),
      skillSets: new FormControl([]),
      startTimeHour:new FormControl(0),
      endTimeHour:new FormControl(0),
      startTimeMin:new FormControl(0),
      endTimeMin:new FormControl(0),
      startTimeZone:new FormControl("am"),
      endTimeZone:new FormControl("am"),
      submitFeedbackImage:new FormControl(false)
    });
  }
  onSelectEndTimeZone(v){
    switch(v){
      case "pm": this.endTimeZone = 12; break;
      case "am": this.endTimeZone = 0; break;
    }
    
    this.validateTimeInput();
  }

  onSelectStartTimeZone(v){
    switch(v){
      case "pm": this.startTimeZone = 12; break;
      case "am": this.startTimeZone = 0; break;
    }

    this.validateTimeInput();   
  }

  validateTimeInput(){
   
    this.invalidEndTime = false;
    var stTimeHr = this.campaigntaskForm.get("startTimeHour").value;
    var endTimeHr = this.campaigntaskForm.get("endTimeHour").value;
    var stTimeMin = this.campaigntaskForm.get("startTimeMin").value;
    var endTimeMin = this.campaigntaskForm.get("endTimeMin").value;
 
    if(stTimeHr == 0){
      this.campaigntaskForm.get("startTimeZone").setValue("am");
    }
    if(endTimeHr == 0){
      this.campaigntaskForm.get("endTimeZone").setValue("am");
    }

    stTimeHr = parseInt(stTimeHr) + this.startTimeZone;
    endTimeHr = parseInt(endTimeHr) + this.endTimeZone;

    var startDate = this.campaigntaskForm.get("startDate").value;
    var endDate = this.campaigntaskForm.get("endDate").value;

    if(_moment(startDate).format('YYYY-MM-DD') == _moment(endDate).format('YYYY-MM-DD')){
     
      var startTimeMinutes = (parseInt(stTimeHr)*60) + parseInt(stTimeMin);
      var endTimeMinutes = (parseInt(endTimeHr)*60) + parseInt(endTimeMin);
      
   
      if(endTimeMinutes < startTimeMinutes){
       
         this.invalidEndTime = true;
      }
      else{
         this.invalidEndTime = false;
      }
    }  
  }

    

    setOptions(controlName: string, evnt: any) {

      if(this.setOptionsEnabled){

      
        switch (controlName) {
            case 'start':
              var cmEndDt = this.campaignDetail.endDate;
                this.endDateOptions = {
                    minDate: new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate()-1)),
                    maxDate: new Date(new Date().setFullYear(new Date(cmEndDt).getFullYear(),new Date(cmEndDt).getMonth(),new Date(cmEndDt).getDate()))
                  
                };
                var dt = this.campaigntaskForm.get("endDate").value;
                if(_moment(dt).format('YYYY-MM-DD') < _moment(evnt).format('YYYY-MM-DD')){
                  this.campaigntaskForm.get("endDate").setValue(new Date(new Date().setFullYear(new Date(evnt).getFullYear(),new Date(evnt).getMonth(),new Date(evnt).getDate())))
                }
               
                break;
                
            default:
                break;
        }

        this.validateTimeInput(); 
      }
    }



  save() {
    this.validateForm = true;

    this.validateTimeInput();
    console.log("form",this.campaigntaskForm);
   // if (this.campaigntaskForm.valid && !this.invalidEndTime)
    if (!this.invalidEndTime) {
      this.isSubmitEnabled = false;
      this.setOptionsEnabled = false;
      // this.campaigntaskForm.value.value = parseFloat(this.campaigntaskForm.value.value).toFixed(2);
      
        this.campaigntaskForm.get("skillSets").setValue(this.skillSets);

        if(this.imageUrl==null || this.imageUrl.trim()==''){
          this.campaigntaskForm.get("pictureUrl").setValue('');
        }
     
        let startDate = new Date(this.campaigntaskForm.value.startDate).toUTCString();
        let startDateLine = new Date(startDate);
        startDateLine.setHours(startDateLine.getHours() + 5);
        startDateLine.setMinutes(startDateLine.getHours() + 30);
        this.campaigntaskForm.get("startDate").setValue(startDateLine);
   
        let endDate = new Date(this.campaigntaskForm.value.endDate).toUTCString();
        let endDateLine = new Date(endDate);
        endDateLine.setHours(endDateLine.getHours() + 5);
        endDateLine.setMinutes(endDateLine.getHours() + 30);
        this.campaigntaskForm.get("endDate").setValue(endDateLine);
  
        var endtime = this.campaigntaskForm.get("endTimeHour").value;
        var newEndTime = parseInt(endtime) + this.endTimeZone;            
        this.campaigntaskForm.get("endTimeHour").setValue(newEndTime);

        var startTime = this.campaigntaskForm.get("startTimeHour").value;
        var newStartTime = parseInt(startTime) + this.startTimeZone;
        this.campaigntaskForm.get("startTimeHour").setValue(newStartTime);
      //let html = this.campaigntaskForm.value.description;

      //var oParser = new DOMParser();
      //var oDOM = oParser.parseFromString(html, "text/html");
      //var text = oDOM.body.innerText;
      //this.campaigntaskForm.value.description = text;
      var extra = {"CampaignId":this.campaignId,"TaskId":this.taskId,"StatusId":this.statusId}
      var form = this.campaigntaskForm.value;
      var req = {...extra,...form}
      this._service.createSuperAdminTask(req).subscribe(response => {
        if (response.isSuccess) {
          if (this.file != null && this.file != undefined) {
            // upload image
            var obj = { campaignId: response.data.campaignId, file: this.file, TaskId: response.data.taskId };
            this._service.UploadTaskImage(obj).subscribe(response => {
              if (response.isSuccess) {
                this.imageUrl = response.data.imageUrl;
              }
            });
            }
            this.navigateBack();
          //this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);
        }
        else{
          this.isSubmitEnabled = true; this.setOptionsEnabled = true;
        }
      },(err)=>{
        this.isSubmitEnabled = true; this.setOptionsEnabled = true;
      });
    }else{
      this.isSubmitEnabled = true; this.setOptionsEnabled = true;
    }
  }


    navigateBack() {
        this.location.back();
    }
  saveAsDraft() {
    this.statusId = 1;

  }
  getTaskDetails() {
    var obj = { taskId: this.taskId, campaignId: this.campaignId };
    this._service.getGetTaskDetail(obj).subscribe(response => {
      if (response.isSuccess) {
        this.setOptionsEnabled = false;
        var detail = response.data;
        this.imageUrl = detail.imageUrl;
        this.skillSets = detail.skillSets;
        this.campaigntaskForm.setValue({
          title: detail.title,
          description: detail.description,
          startDate: new Date(new Date().setFullYear(new Date(detail.startDate).getFullYear(),new Date(detail.startDate).getMonth(),new Date(detail.startDate).getDate())),
          endDate:new Date(new Date().setFullYear(new Date(detail.endDate).getFullYear(),new Date(detail.endDate).getMonth(),new Date(detail.endDate).getDate())),
        //  maximumHours: detail.maximumHours,
        //  minutes:detail.minutes,
          pictureUrl: detail.imageUrl,
       //   goalId: detail.goalId,
          value: detail.value,
          valueType: this.skillSets.length > 0 ? 2 : 1,
          skillSets: this.skillSets,
          autoApprove: detail.autoApprove != null ? detail.autoApprove : false,
          startTimeHour:detail.startTimeHour,
          startTimeMin:detail.startTimeMin,
          endTimeHour:detail.endTimeHour,
          endTimeMin:detail.endTimeMin,
          startTimeZone: detail.startTimeHour > 12 ? "pm" : "am",
          endTimeZone: detail.endTimeHour > 12 ? "pm" : "am",
          submitFeedbackImage:detail.submitFeedbackImage
        });
        if(detail.startTimeHour > 12){
          this.campaigntaskForm.get("startTimeHour").setValue(parseInt(detail.startTimeHour)-12);
          this.startTimeZone = 12;
        }
        if(detail.endTimeHour > 12){
          this.campaigntaskForm.get("endTimeHour").setValue(parseInt(detail.endTimeHour)-12);
          this.endTimeZone = 12;
        }
        if (this.skillSets && this.skillSets.length > 0) {
          this.showSkills = true;
        }
        this.setOptionsEnabled = true;
        this.setDate();
      }
    });
  }

  onChangeSkill(skill: any) {    
    this.calculateAverageValue();
  }

  calculateAverageValue() {
    var value = 0;
    this.skillSets = this.campaigntaskForm.controls.skillSets.value;
    this.skills.forEach(e=>{
       this.skillSets.forEach(s=>{
         if(e.skillId==s.skillId) {        
             value += e.rate;
         }
       })
    })
     var avg = value / this.skillSets.length;
     avg = Math.round(avg);
     if(this.skillSets.length == 0){
        avg = 0;
     }
    
     this.campaigntaskForm.controls["value"].setValue(avg);   
   
  }

    onKeyDown(): boolean {

        if (this.showSkills) {
            return false;
        }

        return true;
    }


  canceltapped() {
    this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);
  }

  deleteImage() {
    this.imageUrl = null;
  }

  setDate(){

    var taskStDt = this.campaigntaskForm.get("startDate").value; 
    var taskEndDt = this.campaigntaskForm.get("endDate").value;
    var cmStDt = this.campaignDetail.startDate;
    var cmEndDt = this.campaignDetail.endDate;

    this.startDateOptions = {
      minDate: new Date(new Date().setFullYear(new Date(cmStDt).getFullYear(),new Date(cmStDt).getMonth(),new Date(cmStDt).getDate()-1)),
      maxDate: new Date(new Date().setFullYear(new Date(cmEndDt).getFullYear(),new Date(cmEndDt).getMonth(),new Date(cmEndDt).getDate()))
    };

    if(_moment(taskStDt).format('YYYY-MM-DD') < _moment(cmStDt).format('YYYY-MM-DD')){
      if(!(this.taskId > 0)){
        this.campaigntaskForm.get("startDate").setValue(new Date(new Date().setFullYear(new Date(cmStDt).getFullYear(),new Date(cmStDt).getMonth(),new Date(cmStDt).getDate())));
      }
     
      this.startDateOptions = {
        minDate: new Date(new Date().setFullYear(new Date(cmStDt).getFullYear(),new Date(cmStDt).getMonth(),new Date(cmStDt).getDate()-1)),
        maxDate: new Date(new Date().setFullYear(new Date(cmEndDt).getFullYear(),new Date(cmEndDt).getMonth(),new Date(cmEndDt).getDate()))
      };

    }

    taskStDt = this.campaigntaskForm.get("startDate").value; 
    if(_moment(taskStDt).format('YYYY-MM-DD') < _moment(Date.now()).format('YYYY-MM-DD')){

      if(!(this.taskId > 0)){
          this.campaigntaskForm.get("startDate").setValue(new Date(new Date().setFullYear(new Date(Date.now()).getFullYear(),new Date(Date.now()).getMonth(),new Date(Date.now()).getDate())));
      }
      this.startDateOptions = {
        minDate: new Date(new Date().setFullYear(new Date(Date.now()).getFullYear(),new Date(Date.now()).getMonth(),new Date(Date.now()).getDate()-1)),
        maxDate: new Date(new Date().setFullYear(new Date(cmEndDt).getFullYear(),new Date(cmEndDt).getMonth(),new Date(cmEndDt).getDate()))
      };
    }

    if(_moment(taskEndDt).format('YYYY-MM-DD') < _moment(taskStDt).format('YYYY-MM-DD')){

      if(!(this.taskId > 0)){
        this.campaigntaskForm.get("endDate").setValue(new Date(new Date().setFullYear(new Date(taskStDt).getFullYear(),new Date(taskStDt).getMonth(),new Date(taskStDt).getDate())));
      }
   
    }
   
    this.endDateOptions = {
      minDate: new Date(new Date().setFullYear(new Date(taskStDt).getFullYear(),new Date(taskStDt).getMonth(),new Date(taskStDt).getDate()-1)),
      maxDate: new Date(new Date().setFullYear(new Date(cmEndDt).getFullYear(),new Date(cmEndDt).getMonth(),new Date(cmEndDt).getDate()))
    };
  
  }

  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.eventService.getEventGoals(obj).subscribe(response => {
      this.eventGoals = response.list;
    });
    this.masterService.getSkills(obj).subscribe(response => {
      if (response.isSuccess) {
        this.skills = response.list;
      }
    });

    this.createForm();
    
    if (this.campaignId > 0) {
      var o = { "CampaignId": this.campaignId };
      this._service.getCampaignDetail(o).subscribe(response => {
        if (response.isSuccess) {    
          this.campaignDetail = response.data;  
          this.setDate();
          if (this.taskId > 0) {
            this.getTaskDetails();
          }
        }
      })
    }
     
   
    
    this.skillDropdownSettings = {
      singleSelection: false,
      idField:'skillId',
      textField: 'skill',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll:false
    }

  }

}
