import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminMasterService } from '../../shared/services/master.services';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'group-create',
  templateUrl: './group-create.component.html',
})

export class GroupCreateComponent implements OnInit {

  adminLoginId: number;
  groupForm: FormGroup;
  validateForm: boolean = false;
  groupId: number;
    cities: any="";
    states: any="";
    countryId: any = "";
    citiesStateWise: any[]=[];
    volunteerForm: FormGroup;
    countries: any;
    validateVolunteersForm: boolean = false;
  statesCountryWise: any[]=[];

    constructor(private _service: AdminVolunteerService, private authentication: AuthenticationService, private masterService: AdminMasterService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private toaster: ToastrManager) {
        var details = this.authentication.getToken();
        if (details == null) {
            this.authentication.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this.authentication.logout(); return;
            }
        }
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.groupId = route.snapshot.params['id'];
    });
  }

  createForm() {
    this.groupForm = new FormGroup({
      groupId: new FormControl(''),
      adminLoginId: new FormControl(''),
      groupName: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      volunteers: new FormArray([])
    });
  }

  get volunteers(): FormArray {
    return this.groupForm.get("volunteers") as FormArray;
  }

  add(data?: any) {
    this.createVolunteerForm();
    this.volunteers.push(new FormGroup({
      userLoginId: new FormControl(0),
      email: new FormControl('', Validators.required),
      mobileNumber: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      isNew: new FormControl(true),
      volunteerSearchTerm: new FormControl(''),
      searchedVolunteers: new FormControl([])
    }));
    console.log(this.volunteers);
  }

  remove(index: number) {
    this.volunteers.removeAt(index)
  }

  save() {
    this.validateForm = true;
    if (this.groupForm.valid) {
      this.groupForm.value.adminLoginId = this.adminLoginId;
      if (this.groupForm.value.volunteers.length > 0) {
        this._service.addGroup(this.groupForm.value).subscribe(response => {
          if (response.isSuccess) {
            this.router.navigate(['/sa/masters/groups']);
          }
          else if (response.alreadyExist) {
            this.toaster.errorToastr("Group is already exist with this name");
          }
          else {
            this.toaster.errorToastr("Some error occured");
          }
        });
      }
      else {
        alert("Please add a volunteer first.");
      }
    }
  }

  searchVolunteer(volunteer: FormGroup) {
    if (volunteer.controls.volunteerSearchTerm.value != '' && volunteer.controls.volunteerSearchTerm.value != null) {
      var obj = { SearchTerm: volunteer.controls.volunteerSearchTerm.value };
      console.log(obj);
      this._service.searchVolunteer(obj).subscribe(response => {
        console.log(response);
        if (response.isSuccess) {
          volunteer.controls.searchedVolunteers.setValue({ data: response.list });// = response.list;
          console.log(volunteer);
        }
      });
    }
    else {
      volunteer.controls.searchedVolunteers.setValue({ data: [] }); // = [];
    }
  }


  getDetails() {
    var obj = { GroupId: this.groupId };
    this._service.groupDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var details = response.data;
        this.groupForm.setValue({
          adminLoginId: '',
          groupId: '',
          groupName: '',
          volunteers: []
        });
        for (var i = 0; i < details.volunteers.length; i++) {
          this.volunteers.push(new FormGroup({
            userLoginId: new FormControl(details.volunteers[i].userLoginId),
            firstName: new FormControl(details.volunteers[i].firstName),
            lastName: new FormControl(details.volunteers[i].lastName),
            email: new FormControl(details.volunteers[i].email),
            mobileNumber: new FormControl(details.volunteers[i].mobileNumber),
            isNew: new FormControl(false),
            volunteerSearchTerm: new FormControl(''),
            searchedVolunteers: new FormControl([{ data: [] }])
          }));
        }
        console.log(this.volunteers);
        this.groupForm.setValue({
          adminLoginId: this.adminLoginId,
          groupId: details.groupId,
          groupName: details.groupName,
          volunteers: this.volunteers.value
        });

        console.log(this.groupForm.controls.volunteers);
      }
    });
  }



  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
    }

    noWhiteSpace(event) {
        if (event.keyCode === 32) {
            return false;
        }
        return true;
    }

    whiteSpaceValidator(control: FormControl): { [key: string]: any } | null {
        var val = control.value;

        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  //checkIfBoxEmpty() {
  //  if (volunteer.controls.volunteerSearchTerm.value == '' || volunteer.controls.volunteerSearchTerm.value == null) {
  //    this.searchedVolunteers = [];
  //  }
  //}

  selectVolunteer(volunteer: any, i: number) {
   //if (!volunteer.isPartOfGroup) {
      var exist = this.volunteers.value.filter(a => a.userLoginId == volunteer.userLoginId);
      if (exist.length == 0) {
        this.volunteers.controls[i].setValue({
          userLoginId: volunteer.userLoginId,
          email: volunteer.email,
          mobileNumber: volunteer.mobileNumber,
          firstName: volunteer.firstName,
          lastName: volunteer.lastName,
          isNew: false,
          volunteerSearchTerm: '',
          searchedVolunteers: { data: [] }
        });
      }
      else {
        this.volunteers.controls.pop(); 
        let a = document.getElementById("popupVolunteerExist") as HTMLElement;
        a.click();
      }
    //}
    //else {
    //  alert("Volunteer is already a part of another group");
    //}
    }

    createVolunteerForm() {
        this.volunteerForm = this.fb.group({
            firstName: ['', [Validators.required, this.whiteSpaceValidator]],
            lastName: ['', [Validators.required, this.whiteSpaceValidator]],
            countryId: [this.countryId, Validators.required],
            stateId: ['', Validators.required],
            cityId: ['', Validators.required],
            gender: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
            mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        });
    }
    resetForm() {
        this.volunteerForm.get("firstName").setValue('');
        this.volunteerForm.get("lastName").setValue('');
        this.volunteerForm.get("stateId").setValue('');
        this.volunteerForm.get("cityId").setValue('');
        this.volunteerForm.get("gender").setValue('');
        this.volunteerForm.get("email").setValue('');
        this.volunteerForm.get("mobileNumber").setValue('');

        this.validateForm = false;
    }

    createVolunteer() {

        var isExist = this.checkExisting(this.volunteerForm.get("email").value, this.volunteerForm.get("mobileNumber").value);
        if (isExist) {
            this.toaster.errorToastr("Volunteer already Exist"); this.resetForm();
            return;
        }
        if (this.volunteerForm.valid) {

            var val = this.volunteerForm.value;
            var data = {
                "adminLoginId": this.adminLoginId
            }
            var obj = { ...data, ...val }
            this._service.addVolunteer(obj).subscribe(response => {
                if (response.isSuccess) {
                   
                        this.toaster.successToastr("Volunteer created successfully");
                        this.volunteers.push(new FormGroup({
                            userLoginId: new FormControl(response.data.userLoginId),
                            email: new FormControl(response.data.email),
                            mobileNumber: new FormControl(response.data.mobileNumber),
                            firstName: new FormControl(response.data.firstName),
                            lastName: new FormControl(response.data.lastName),
                            isNew: new FormControl(false),
                            volunteerSearchTerm: new FormControl(''),
                            searchedVolunteers: new FormControl([])
                        }))
                }
                else {

                    if (response.responseMessage != null && response.responseMessage != '') {
                        this.toaster.errorToastr(response.responseMessage);
                    }
                }
                this.resetForm();
            }, (err) => {
                this.toaster.errorToastr("Some error occured");
                    this.resetForm();
            });

        }
    }

    getCities(stateId: any) {
      var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
      this.masterService.getCities(obj).subscribe(response => {
        this.cities = response.data;
      });
    }
  
  
    getStates(countryId: any) {
      var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
      this.masterService.getStates(obj).subscribe(response => {
        this.states = response.data;
      });
    }

    onCancel() {
        this.resetForm();
    }

    checkExisting(email: string, mobileNumber: string): boolean {

        var isExist = false;

        for (var i = 0; i < this.volunteers.length; i++)
        {
            var frm = this.volunteers.at(i);
            
            if (frm.get("email").value.toLowerCase() == email.toLowerCase() && frm.get("mobileNumber").value == mobileNumber)
            {
                isExist = true;
            }
        }
      
        return isExist;
    }

  ngOnInit() {
      this.createForm();
    
      var obj = { pageNumber: 1, PageSize: 500 };
      this.masterService.getCountries(obj).subscribe(response => {
          this.countries = response.data;
      });

      this.createVolunteerForm();
    if (this.groupId > 0) {
      this.getDetails();
    }
  }
}
