import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { WallPostService } from '../../shared/services/wallpost.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl,SafeHtml } from '@angular/platform-browser';
import { ToastrManager } from 'ng6-toastr-notifications';



@Component({
  selector: 'wallpost-detail',
  templateUrl: './wallpost-detail.component.html',
  styleUrls: ['./wallPostDetail.scss']

})

export class WallPostDetailComponent implements OnInit {


  adminLoginId: number;
  corporateId: number;
  wallPostId: number;
  imageUrl: string;
  wallPostCommentList: any;
    wallpost: any;
    wallpostComment: string = "";
  safeSrc: SafeResourceUrl;
    hasWhiteSpaces: boolean;
    htmlContent:SafeHtml;

    constructor(private authentication: AuthenticationService, private _service: WallPostService, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer, private toaster: ToastrManager) {
        var details = this.authentication.getToken();
        if (details == null) {
            this.authentication.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 1) {
                this.authentication.logout(); return;
            }
        }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;


    router.events.subscribe((response) => {
      this.wallPostId = route.snapshot.params['id'];
    });
  }

 addWallPostComment(){
    
     var requestObj = { "adminLoginId": this.adminLoginId, "wallPostId": this.wallPostId, "comment":this.wallpostComment}
     this._service.callWallpostComment(requestObj)
    .subscribe(res => {
      console.log(res);
      if(res.isSuccess){
          this.getWallPost();
          this.wallpostComment = '';
      }
      else{
        this.toaster.errorToastr("Some error occured");
      }
    },(err)=>{
      this.toaster.errorToastr(err.errorMessage);
    })
   }

  getWallPost() {

    var obj = { wallPostId: this.wallPostId };
    this._service.getCommentBasedonWallpostId(obj).subscribe(response => {
      if (response.isSuccess) {
          this.wallPostCommentList = response.list;
          this.wallpost = response.data.wallPostDetails;
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.wallpost.description); // console.log(this.wallpost);

          // if (this.wallpost.videoUrl != null && this.wallpost.videoUrl != '') {
          //   //  this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.wallpost.videoUrl);
          // }
        
       
      }
    });
    }

    checkWhiteSpaces() {
        this.hasWhiteSpaces = false;
        if (this.wallpostComment.trim() == '') {
            this.hasWhiteSpaces = true;
        }
    }

  playVideo(event){
    console.log(event);
    let opner = window.open(event, '_blank', 'height=450,width=700');

   }


  deleteWallPostComment(commentId: number) {
    var obj = { wallPostId: this.wallPostId, commentId: commentId };
    this._service.deleteWallpostComment(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getWallPost();
      }
    });

  }
  ngOnInit() {
    this.getWallPost();
  }

}



