import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { MasterNGOService } from '../../../shared/services/masterNGO.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'event-private-list',
  templateUrl: './event-private-list.component.html',
})

export class EventPrivateListComponent implements OnInit {

  adminLoginId: number;
  events: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  titleIcon: string = "sorting";
  startDateIcon: string = "sorting";
  applicantIcon: string = "sorting";
  stateIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
  skillId: any=0;
  filtersOn: boolean;
  skills: any;
  ngoList: any;
  ngoId: number = 0;
  stateId: number = 0;
  countries: any;
  countryId: any;
  stateList: any;
  

  constructor(private _service: AdminEventsService, private authentication: AuthenticationService,private master:AdminMasterService,private ngo:MasterNGOService,private toaster:ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==2){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    var pageNo = localStorage.getItem("event_page");
    if(pageNo!=null){
      this.pageNumber = parseInt(pageNo);
    }
  }



    getEvents() {
        var obj = { AdminLoginId: this.adminLoginId, IsPublic: false, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, MasterNgoId: this.ngoId, SkillId: this.skillId, IsAscending: this.isAscending, SortBy: this.sortColumn, Address: { StateId: this.stateId } };
    console.log(obj);
    this._service.getSuperAdminEvents(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response.list);
        this.events = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }  else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        if(this.pageNumber > 1){
          this.firstPageClass = "";
          this.previousPageClass = "";
        }
      
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Title":
        if (this.titleIcon == "sorting") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.titleIcon == "sorting_asc") {
          this.titleIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.titleIcon == "sorting_desc") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.startDateIcon = "sorting";
        this.stateIcon = "sorting";
        this.applicantIcon = "sorting";
        break;
      case "StartDate":
        this.titleIcon = "sorting";
        if (this.startDateIcon == "sorting") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.startDateIcon == "sorting_asc") {
          this.startDateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.startDateIcon == "sorting_desc") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.stateIcon = "sorting";
        this.applicantIcon = "sorting";
        break;
      case "State":
        this.titleIcon = "sorting";
        this.startDateIcon = "sorting";
        if (this.stateIcon == "sorting") {
          this.stateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.stateIcon == "sorting_asc") {
          this.stateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.stateIcon == "sorting_desc") {
          this.stateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.applicantIcon = "sorting";
        break;
      case "Applicants":
        this.titleIcon = "sorting";
        this.startDateIcon = "sorting";
        if (this.applicantIcon == "sorting") {
          this.applicantIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.applicantIcon == "sorting_asc") {
          this.applicantIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.applicantIcon == "sorting_desc") {
          this.applicantIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.stateIcon = "sorting";
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getEvents();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getEvents();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = ""
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getEvents();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getEvents();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getEvents();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getEvents();
    }
  }

  performAction(evnt: any, statusId: number, status: string) {
    var obj = { EventId: evnt.eventId, StatusId: statusId };
    this._service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        evnt.statusId = statusId;
        evnt.status = status;
        if(evnt.statusId == 2){
          this.toaster.successToastr("Activity published successfully");
        }else if(evnt.statusId == 7){
          this.toaster.successToastr("Activity closed successfully");
        }else{
          this.toaster.successToastr("Activity revoked successfully");
        }     
      }else{
        if(evnt.statusId == 2){
          this.toaster.errorToastr("Activity not published");
        }else if(evnt.statusId == 7){
          this.toaster.errorToastr("Activity not closed");
        }
        else if(evnt.statusId == 1){
          this.toaster.errorToastr("Future Date Activity can not published");
        }
        else{
          this.toaster.errorToastr("Activity not revoked");
        }
     }
    },(err)=>{
      this.toaster.errorToastr("Some error occured");
    });
  }

  deleteEvent(evnt: any, index: number) {
    var obj = { EventId: evnt.eventId };
    this._service.deleteEvent(obj).subscribe(response => {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getEvents();
    });
  }

    downloadCsvFile() {
        var list = this.events;
        if (list != null && list.length > 0) {
            var obj = { AdminLoginId: this.adminLoginId, IsPublic: false, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
            this._service.getSuperAdminEventCsv(obj).subscribe(response => {
                console.log(response);
                if (response.isSuccess) {
                    let elem = document.createElement("a");
                    elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                    elem.click();
                }
            });
        }
        else {
            alert("Event list is empty");
        }
    }

    ClearFilters(){
      this.skillId = this.ngoId = this.stateId = 0; 
      this.getEvents();
      this.filtersOn = false;
      this.nextPageClass = this.lastPageClass = "";     this.pageNumber=1; this.pageSize=10;
    }

    ApplyFilters(){    
      this.getEvents();  
      if(this.ngoId==0 && this.skillId==0 && this.stateId==0) {
        this.filtersOn = false;
      }
      else{
        this.filtersOn = true;
      }         
    }

    DuplicateActivity(eventId:any){
      this._service.duplicateActivity({"AdminLoginId":this.adminLoginId,"EventId":eventId}).subscribe(response => {
            if(response.isSuccess){
              this.getEvents();
            }
      },(err)=>{
        
      });
   }

   addPaginationInfo(){
    localStorage.removeItem("event_page");
    localStorage.setItem("event_page",this.pageNumber.toString());
  }

  ngOnInit() {
    this.getEvents();

    
    this.master.getCountries({PageSize: 100, PageNumber: 1}).subscribe(response => {
      if (response.isSuccess) {
        this.countries = response.data;
        for (var i = 0; i < this.countries.length; i++) {
          this.countryId = this.countries[i].countryId;
        }
      }
    });

    this.master.getStates({"CountryId":this.countryId,"PageSize":1000,"PageNumber":1}).subscribe(response => {
      if(response.isSuccess){
        this.stateList = response.data;
      }
    });


    this.ngo.searchNGO({ SearchTerm:'', PageSize: 1000, PageNumber: 1 }).subscribe(response=> {
      if(response.isSuccess){      
         this.ngoList = response.list;
      }
    })

    this.master.getSkills({"pageNumber":1,"pageSize":500}).subscribe(response => {
      console.log(response);
      if(response.isSuccess){
        this.skills = response.list;       
      }
    })
  }
}
