import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { SurveyService } from '../../../shared/services/survey.service';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { ReportService } from '../../../shared/services/report.service';
import { DomSanitizer, SafeResourceUrl,SafeHtml } from '@angular/platform-browser';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'event-detail',
  templateUrl: './event-detail.component.html',
})

export class EventDetailComponent implements OnInit {

  adminLoginId: any;
  corporateId: any;
  eventId: any;
  details: any;
  volunteerSearchTerm: string;
  isAddNewVolunteer: boolean = false;
  isAddNewGroup: boolean = false;
  searchedVolunteers: any[] = [];
  surveys: any[] = [];
  pushmessage: string;
  pushmsgValidation: boolean = false;
  pushmsgSendSuccess: boolean = false;
  pushemail: string;
  pushemailValidation: boolean = false;
  pushemailSendSuccess: boolean = false;
  selectedVolunteer: any;
  comment: string;
  originalDesc: string;
  isShort: boolean = false;
  feedbacks: any[] = [];
  userFeedbackComment:string;
  userId: any;
  rating: any;
  feedbackDesc: any;
  adminFeedback: any;
  addresses: any;
  showReadMoreButton = false;
  feedbackMedia: any;
  activeTab: string = "detail";
  listVolunteers: boolean = false;
  detail: boolean = false;
  karmaMsg: string;
  htmlContent:SafeHtml;
  safeSrc: SafeResourceUrl;
  ngoDescription: SafeHtml;
  isSuperadminEvents:boolean;
  rejectedGroupId: any;
  pushTitle: any;
  pushTitleValidation: boolean = false;
  isPushNotification: boolean = true;
  isEmail: boolean = false;

  constructor(private _service: AdminEventsService, private router: Router, private route: ActivatedRoute, private volunteerService: AdminVolunteerService, private authentication: AuthenticationService, private surveyService: SurveyService,private feedback:FeedbackService,private reportService:ReportService,private sanitizer: DomSanitizer,private toaster: ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==1){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    router.events.subscribe((response) => {
      this.eventId = route.snapshot.params['id'];
      this.activeTab = route.snapshot.params['volunteer'] === "vol" ? "volunteers" : "detail";
    });
  }

  getDetails() {
    var obj = { EventId: this.eventId, CorporateId: this.corporateId };
    this._service.getDetails(obj).subscribe(response => {
      if (response.isSuccess) {    
        this.details = response.data;
        this.addresses = response.data.addresses;
        this.isSuperadminEvents = response.data.isSuperadminEvents;
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.details.description);
        this.ngoDescription = this.sanitizer.bypassSecurityTrustHtml(this.details.organiserDescription);
      }
    });
  }

  selectVolunteerForAction(volunteer: any) {
    this.selectedVolunteer = volunteer;
    this.comment = '';
  }


  addVolunteer() {
    this.isAddNewVolunteer = true;
  }

  searchVolunteer() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { SearchTerm: this.volunteerSearchTerm, CorporateId: this.corporateId };
      this.volunteerService.searchVolunteer(obj).subscribe(response => {
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }

  performActionOnKarmaPoints(userId,checked){    
    var obj = {"userLoginId":userId, "eventId":this.eventId, "isKarmaPointsApproved":checked}
    this._service.actionOnKarmaPoints(obj).subscribe(response => {    
        if(response){
          this.getFeedbacks();
          if(checked){
            this.karmaMsg = "karma points for the volunteer have been approved";
          }
          else{
            this.karmaMsg = "karma points for the volunteer have been disapproved";
          }    
          
          let a = document.getElementById("karmaPopupButton") as HTMLElement
          a.click();
        }
    },(err)=>{
      alert("Some error occured");
    })
  }

  selectVolunteer(volunteer: any, i: number): boolean {
    var isAllowed = true;
    // if (this.details.domains) {
    //   var fullDomain = volunteer.email.split('@')[1];
    //   var domain = fullDomain.split('.')[0];
    //   isAllowed = this.details.domains.includes(domain);
    // }
    if (isAllowed) {
      if (this.details.volunteers == null) {
        this.details.volunteers = [];
      }
      volunteer.statusId = 2;
      volunteer.status = "Approved";
      if (this.details.volunteers.length > 0) {
        var exist = this.details.volunteers.filter(a => a.userLoginId == volunteer.userLoginId);
        if (exist.length > 0) {
          this.searchedVolunteers = [];
          this.volunteerSearchTerm = '';
          this.isAddNewVolunteer = false;
          return this.isAddNewVolunteer;
        }
      }
      var obj = { EventId: this.eventId, Volunteer: volunteer };
      this._service.addEventUsers(obj).subscribe(response => {
        if (response.isSuccess) {
            this.details.volunteers.push(volunteer);
        }
        // else {
        //   var a = document.getElementById("errorModal").click();
        //   //alert("Vacancies limit exceeded.");
        // }
        else{
          this.toaster.errorToastr(response.responseMessage);
      }
      });
    }
    // else {
    //   alert("Volunteer's domain does not match.");
    // }
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewVolunteer = false;
  }

  addGroup() {
    this.isAddNewGroup = true;
  }

  searchGroup() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { SearchTerm: this.volunteerSearchTerm };
      this.volunteerService.searchGroup(obj).subscribe(response => {
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }

  selectGroup(group: any, i: number): boolean {
    var isAllowed = true;
    // if (this.details.domains) {
    //   for (var i = 0; i < group.volunteers.length; i++) {
    //     var fullDomain = group.volunteers[i].email.split('@')[1];
    //     var domain = fullDomain.split('.')[0];
    //     isAllowed = this.details.domains.includes(domain);
    //     if (!isAllowed)
    //       break;
    //   }
    // }
    if (isAllowed) {
      if (this.details.volunteers == null) {
        this.details.volunteers = [];
      }
      group.statusId = 2;
      group.status = "Approved";
      if (this.details.volunteers.length > 0) {
        var exist = this.details.volunteers.filter(a => a.groupId == group.groupId);
        if (exist.length > 0) {
          this.searchedVolunteers = [];
          this.volunteerSearchTerm = '';
          this.isAddNewGroup = false;
          return this.isAddNewGroup;
        }
      }
      var obj = { EventId: this.eventId, Volunteer: group };
      this._service.addEventUsers(obj).subscribe(response => {
        if (response.isSuccess) {
          // this.details.volunteers.push(group);
          this.getDetails();
          this.details.groups.push(group);
          this.details.groupCount += 1;
        }
        else{
          this.toaster.errorToastr(response.responseMessage);
      }
      });
    }
    // else {
    //   alert("Group contains other domain volunteers.");
    // }
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewGroup = false;
  }

  performActionOnUser(statusId: number, status: string, volunteer: any) {
    var obj = { EventId: this.eventId,AdminLoginId:this.adminLoginId, UserLoginId: volunteer.userLoginId, GroupId: volunteer.groupId, StatusId: statusId, Comment: this.comment };
    this._service.performActionOnUser(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getDetails();
      }
    });
  }

  onPushNotificationSelect(){
    this.isPushNotification = true; this.isEmail = false;
  }

  onEmailSelect(){
    this.isEmail = true; this.isPushNotification = false;
  }


  
  RejectGroup(groupId:any){
    this.rejectedGroupId = groupId;
  }

  performActionOnGroup(statusId: any, groupId: any) {
    var obj = { EventId: this.eventId,AdminLoginId:this.adminLoginId, GroupId: groupId, Status: statusId, Comment:this.comment };
    this._service.performActionOnGroup(obj).subscribe(response => {
      if (response) {
        this.getDetails();
        this.comment = '';
      }
    });
  }

  getSurveys() {
    var obj = { AdminLoginId: this.adminLoginId, EventId: this.eventId, PageNumber: 1, PageSize: 100 };
    console.log(obj);
    this.surveyService.getAllSurveys(obj).subscribe(response => {
      if (response.isSuccess) {
        this.surveys = response.list;
      }
    });
  }

  performActionOnSurvey(survey: any, statusId: number, status: string) {
    var obj = { SurveyId: survey.surveyId, StatusId: statusId };
    this.surveyService.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        survey.statusId = statusId;
        survey.status = status;
      }
    });
  }

  deleteSurvey(survey: any, index: number) {
    var obj = { SurveyId: survey.surveyId };
    this.surveyService.deleteSurvey(obj).subscribe(response => {
      this.getSurveys();
    });
  }

  sendPushNotification() {

      this.pushmsgValidation = false; this.pushemailValidation = false;

      if(this.isPushNotification){
        if (this.pushmessage == undefined || this.pushmessage == null || this.pushmessage.trim() == "") {
          this.pushmsgValidation = true;
        }
        else{
          var obj = { EventId: this.eventId, Message: this.pushmessage };

          this._service.pushNotificationforEventUser(obj).subscribe(response => {
            if (response.isSuccess) {
    
              this.toaster.successToastr("Notification Sent Successfully");
              this.pushmessage = '';
            } else {
              this.toaster.errorToastr("Notification Not Sent");
            }
    
          }, (err) => {
            this.toaster.errorToastr("Notification Not Sent");
          });
        }          
      }
      else{

        if (this.pushemail == undefined || this.pushemail == null || this.pushemail.trim() == "") {
          this.pushemailValidation = true;
        }
        else{
          var reqobj = { EventId: this.eventId, Content: this.pushemail };

          this._service.emailNotificationforEventUser(reqobj).subscribe(response => {
            if (response) {
              this.toaster.successToastr("Mail Sent Successfully");
              this.pushemail = '';
            } else {
              this.toaster.errorToastr("Mail Not Sent");
            }
    
          }, (err) => {
            this.toaster.errorToastr("Mail Not Sent");
          });
        }
       
      }    
  }

  
  
  getFeedbacks(){
    var obj = { AdminLoginId:this.adminLoginId,CorporateId:this.corporateId, EventId: this.eventId, PageSize: 500, PageNumber: 0 };
    this.feedback.getEventFeedback(obj).subscribe(response => {
      if (response.isSuccess) {
        this.feedbacks = response.list;
      }
    });
  }

  onClickReport(userId:any){
    this.userId = userId;
  }

  createReverseFeedback(){
    var req = {"eventId":this.eventId,"adminLoginId":this.adminLoginId,"userLoginId":this.userId,"rating":this.rating,"comment":this.feedbackDesc}
    this.feedback.submitReverseFeedback(req).subscribe(response => {
        if(response){
           alert("Feedback submit successfully");
           this.getFeedbacks();
        }
    });
  }

  GetAdminFeedback(userId:any){
      var req = {"eventId":this.eventId,"adminLoginId":this.adminLoginId,"userLoginId":userId}
      this.feedback.getAdminFeedback(req).subscribe(response => {
          if(response.isSuccess){
              this.adminFeedback = response.data;          
              var e = document.getElementById("showAdminFeedback") as HTMLElement;
              e.click();
          }
          else{
            alert("Some error occured");
          }
      });
  }

  getContent(desc:string){
    if(desc!=null){
      this.originalDesc = desc;
      if(desc.length > 100){
        this.details.organiserDescription = desc.substring(0,99); this.isShort = true; this.showReadMoreButton = true; 
      }
      else{
        this.details.organiserDescription = desc; this.showReadMoreButton = false;
      }    
    }
    
}

getTrimmedContent(content:string){
  if(content.length == 0){
    return content = "-"
  }
  else if(content.length > 10){
    return content.substring(0,5) + "...";
  }else{
    return content;
  }
  
}

modifyTimeHr(e){
  if(e > 12){
     var t = parseInt(e)-12;
     if(t < 10)
     return "0"+t.toString();
  else 
    return t.toString();   
  }
  else {
    if(e < 10)
       return "0"+e.toString();
    else 
      return e.toString();
  }
}

modifyTimeMin(t){
    if(t < 10)
    return "0"+t.toString();
 else 
   return t.toString();   
}


viewMedia(media:any){
  this.feedbackMedia = media;
}

setReadMoreOn(){
if(this.isShort){
  this.details.organiserDescription = this.originalDesc; this.isShort = false;
}
else{
  this.isShort = true; this.getContent(this.details.organiserDescription);
}
}

onClickEdit(){
  var redirect = "admin/events/public/edit/"+this.eventId.toString();
  if(!this.details.isPublic){
   redirect = "admin/events/private/edit/"+this.eventId.toString();
  }
  this.router.navigateByUrl(redirect);
}


viewContent(content:string){
  this.userFeedbackComment = content;
}

  setPushMessage() {
    this.pushmsgSendSuccess = false;
    this.pushmsgValidation = false;
  }

  setTabActive(tabname){
    switch(tabname){
      case "detail" : this.detail = true; break;
      case "volunteers" :  this.listVolunteers = true; break;
    }
  }

  generateReport() {
    var req = { "eventId": this.eventId,"corporateId":this.corporateId }
    this.reportService.generateVolunteerReport(req).subscribe(response => {
      if(response.isSuccess){
        let elem = document.createElement("a");
        elem.target = "_self"; elem.href = response.url; elem.setAttribute("download", response.url);
        elem.click();
      }
    })
  }

  ngOnInit() {
    this.getDetails();
    this.setTabActive(this.activeTab)
  }

}
