import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators,FormBuilder, FormArray } from '@angular/forms';
import { AuthenticationService } from "../../shared/services/authentication.service";
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
//import { forEach } from '@angular/router/src/utils/collection';
//import { ThrowStmt } from '@angular/compiler';
import 'quill-emoji/dist/quill-emoji.js';
import { EditorconfigModule } from './../../shared/models/editorconfig/editorconfig.module';


@Component({
  selector: 'ngo-create',
  templateUrl: './ngo-create.component.html',
})

export class NGOCreateComponent implements OnInit {


  NGOForm: FormGroup;
  validateForm: boolean = false;
  masterNgoId: any;
  errorMessage: string;
  selectedThematicAreas: any[] = [];
  thematicAreas: any[] = [];
    groupThematicAreas: any[] = [];
    isSubmitting: boolean = false;
   goals:any[]=[];
  listOfGoals: any;
  countries: any;
  states: any;
  cities: any;
  imageFile: File;
  imageFileUrl: any=null;
  invalidFirstGoal: boolean = false;
  invalidSecondGoal: boolean = false;
  invalidThirdGoal: boolean = false;

  
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
   
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
   
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
   
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
   
      ['clean'],                                         // remove formatting button
   
      ['emoji'],
      ['link','image','video'],                         // link and image, video
    ],
    "emoji-toolbar": true
  };

  editorUrl:string = '';
  editorConfiguration:any;
  

    constructor(private router: Router, private _service: MasterNGOService, private route: ActivatedRoute, private masterService: AdminMasterService, private authentication: AuthenticationService,private toaster:ToastrManager,private editorConfig:EditorconfigModule) {
        var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.authentication.logout(); return;
          }
      }
  
      this.masterNgoId = this.route.snapshot.params['id'];
      this.editorConfiguration = this.editorConfig.configuration;
      this.editorUrl = this.editorConfig.editorUrl;

  }

  createForm() {
    this.NGOForm = new FormGroup({
        Name: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
        OwnerName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
        Email: new FormControl('', [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]),
        MobileNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
        ),
        Description:new FormControl('',[Validators.required,this.whiteSpaceValidator]),
        Fcra: new FormControl(false,Validators.required),
        FcraNumber: new FormControl('',Validators.maxLength(10)),
        WebsiteLink:new FormControl('',Validators.pattern(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/)),
        Designation: new FormControl('',[Validators.required, this.whiteSpaceValidator]),
        NgoContactNumber: new FormControl('',[Validators.required, this.whiteSpaceValidator]),
        CountryId: new FormControl('',Validators.required),
        StateId: new FormControl('',Validators.required),
        CityId: new FormControl('',Validators.required),
        FirstPriorityGoal: new FormControl('',Validators.required),
        SecondPriorityGoal: new FormControl({value:'',disabled:true}),
        ThirdPriorityGoal: new FormControl({value:'',disabled:true}),
        Location: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
        Logo:new FormControl('')
      //ThematicAreas: new FormControl('')
    });
  }

  save() {
    this.validateForm = true;
     console.log(this.NGOForm)
      if (this.NGOForm.valid && !this.invalidFirstGoal && !this.invalidSecondGoal && !this.invalidThirdGoal) {
          this.isSubmitting = true;

          var selectedGoals = [];
          selectedGoals.push({GoalId:parseInt(this.NGOForm.get("FirstPriorityGoal").value),Priority:1});
          selectedGoals.push({GoalId:parseInt(this.NGOForm.get("SecondPriorityGoal").value),Priority:2});
          selectedGoals.push({GoalId:parseInt(this.NGOForm.get("ThirdPriorityGoal").value),Priority:3});
          var newObj = {"Goals":selectedGoals}
      // this.NGOForm.controls["ThematicAreas"].setValue(this.selectedThematicAreas);
      if (this.masterNgoId > 0) {
        var id = {"masterNgoId":parseInt(this.masterNgoId)};
        if(!this.NGOForm.get('Fcra').value){
            this.NGOForm.get('FcraNumber').setValue('');
        }
      
        this.NGOForm.get("CountryId").setValue(this.NGOForm.get("CountryId").value.toString());
        this.NGOForm.get("StateId").setValue(this.NGOForm.get("StateId").value.toString());
        this.NGOForm.get("CityId").setValue(this.NGOForm.get("CityId").value.toString());

        var val = this.NGOForm.value;              
        this._service.updateNGO({...val,...newObj,...id}).subscribe(response => {
          if (response.isSuccess) {
              this.router.navigate(['/sa/ngo']);
          }
          if (response.isExist) {
            this.errorMessage = 'NGO with this name or email already exist.'
            }
            this.isSubmitting = false;
        }, (err) => {
            this.isSubmitting = false;
        });
      }
      else {
        if(!this.NGOForm.get('Fcra').value){
          this.NGOForm.get('FcraNumber').setValue('');         
        }
        var val = this.NGOForm.value;       
      
        this._service.createNGO({...val,...newObj}).subscribe(response => {      
          if (response.isSuccess) {         
              this.router.navigate(['/sa/ngo']);
          }
          if (response.isExist) {
            this.errorMessage = 'NGO with this email already exist.'
            }
            this.isSubmitting = false;
        }, (err) => {
            this.isSubmitting = false;
        });
      }

    }
  }
  getNGODetail() {

    var obj = { masterNgoId: parseInt(this.masterNgoId) };
    this._service.getNGODetail(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        this.selectedThematicAreas = [];
        //if (detail.ngoThematicAreaMapping) {
        //  for (var i = 0; i < detail.ngoThematicAreaMapping.length; i++) {
        //    if (detail.ngoThematicAreaMapping[i].isDeleted != true) {
        //      this.selectedThematicAreas.push(detail.ngoThematicAreaMapping[i].thematicArea);
        //    }
        //  }
        //}

        this.NGOForm.setValue({
          Name: detail.name,
          OwnerName: detail.ownerName,
          Email: detail.email,
          MobileNumber: detail.mobileNumber,
          Description:detail.description,
          Location: detail.location,
          Fcra: detail.fcra!=null ? detail.fcra : false,
          Logo:detail.logo,
          FcraNumber:detail.fcraNumber,
          WebsiteLink:detail.websiteLink,
          Designation: detail.designation,
          NgoContactNumber: detail.ngoContactNumber,
          CountryId: detail.address!=null ? detail.address.countryId : '',
          StateId: detail.address!=null ? (detail.address.stateId!=null ? detail.address.stateId : '') : '',
          CityId:  detail.address!=null ? (detail.address.cityId!=null ? detail.address.cityId : '') : '',
          FirstPriorityGoal: detail.goals!=null && detail.goals.length > 0 ? this.filterGoalByPriority(1,detail.goals) : '',
          SecondPriorityGoal: detail.goals!=null && detail.goals.length > 1 ? this.filterGoalByPriority(2,detail.goals) : '',
          ThirdPriorityGoal: detail.goals!=null && detail.goals.length > 2 ? this.filterGoalByPriority(3,detail.goals) : '',
         
         // ThematicAreas: this.selectedThematicAreas
        });

       
        if(detail.address!=null && detail.address.stateId!=null){
          this.getStates(detail.address.countryId);
        } if(detail.address!=null && detail.address.cityId!=null){
          this.getCities(detail.address.stateId);
        }
        this.NGOForm.controls.SecondPriorityGoal.enable();

        if(detail.goals!=null){
          if(detail.goals.length > 1){
            this.NGOForm.controls.ThirdPriorityGoal.enable();
          }
        }

        this.imageFileUrl = detail.logo;
      }
    });
    }

    noWhiteSpace(event) {
        if (event.keyCode === 32) {
            return false;
        }
        return true;
    }

    whiteSpaceValidator(control: FormControl): { [key: string]: any } | null {
        var val = control.value;

        if (val!=null && val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }

  selectThematicArea(id: number) {
    var thematicArea = this.thematicAreas.filter(a => a.thematicAreaId == id);
    this.selectedThematicAreas.push(thematicArea[0]);
  }

  deleteThematicArea(index: number) {
    this.selectedThematicAreas.splice(index, 1);
  }

  filterGoalByPriority(priority:number,data:any[]){
     for(var i=0;i<data.length;i++){
       if(data[i].priority > 0){
        if(priority == data[i].priority){
          return data[i].goalId;
        }
       }else{
         return '';
       }      
     }
  }
  
  deleteImage(){
    this.imageFileUrl = ''; this.imageFile = null; this.NGOForm.get("Logo").setValue('');
  }
  onSelectFcra(evt){
    if(!evt){
      this.NGOForm.controls.Fcra.setValue(false);
      this.NGOForm.get('FcraNumber').clearValidators();
      this.NGOForm.get('FcraNumber').setErrors(null);
    }
    else if(evt){
      this.NGOForm.controls.Fcra.setValue(true);
      this.NGOForm.get('FcraNumber').setValidators(Validators.required);      
    }
    else{
      this.NGOForm.controls.Fcra.setValue(false);  
      this.NGOForm.get('FcraNumber').clearValidators();
      this.NGOForm.get('FcraNumber').setErrors(null);
    }
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    };

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    onSelectingImageFile(event){
      var file = event.target.files[0];
      if (file != null) {
          if (file.size > 1048576) {
              this.toaster.errorToastr("Upload image upto 1 mb only");
              return;
          }
          this.imageFile = file;
          var reader = new FileReader();
          reader.onload = (e: any) => {
              this.imageFileUrl = e.target.result;
          }
          reader.readAsDataURL(file);
          this.uploadLogo();
      }
      event.target.value = ''; 
  }

  uploadLogo(){
    if(this.imageFile!=null && this.imageFile!=undefined){
      var ob = {file:this.imageFile}
      this._service.uploadImage(ob).subscribe(response => {
        if(response.isSuccess){
           this.imageFileUrl = response.url; this.NGOForm.get("Logo").setValue(response.url);
        }
      },(err)=>{
        this.toaster.errorToastr("Some error occurred");
      });
    }
  }
    getGoals(){
      this.masterService.getGoals().subscribe(response => {
          if(response.isSuccess){
             this.listOfGoals = response.list;
          }
      });
    }

    SetFirstPriorityGoal(goalId:any){

      if(goalId==''){
         this.NGOForm.controls.SecondPriorityGoal.setValue('');
         this.NGOForm.controls.ThirdPriorityGoal.setValue('');        
         this.NGOForm.controls.SecondPriorityGoal.disable();
         this.NGOForm.controls.ThirdPriorityGoal.disable();        
      }
      else{
        if(!this.validateGoalsSelection()){
           return;
        }   
        this.NGOForm.controls.SecondPriorityGoal.enable();      
        if(this.NGOForm.get("ThirdPriorityGoal").value!=''){
          this.NGOForm.controls.ThirdPriorityGoal.enable();
        }       
      }    
    }

    SetSecondPriorityGoal(goalId:any){

      if(goalId==''){
        this.NGOForm.controls.ThirdPriorityGoal.setValue('');
        this.NGOForm.controls.ThirdPriorityGoal.disable();
      }
      else{
        if(!this.validateGoalsSelection()){
           return;
        }
        this.NGOForm.controls.ThirdPriorityGoal.enable();     
      }      
    }

    SetThirdPriorityGoal(goalId:any){      
        this.validateGoalsSelection();
    }

    validateGoalsSelection(){
      var firstGoalId = this.NGOForm.controls.FirstPriorityGoal.value;
      var secondGoalId = this.NGOForm.controls.SecondPriorityGoal.value;
      var thirdGoalId = this.NGOForm.controls.ThirdPriorityGoal.value;
      
      this.invalidFirstGoal = this.invalidSecondGoal = this.invalidThirdGoal = false;
      var retrn = true;
      if(firstGoalId!='' && secondGoalId!=''){
        if(firstGoalId==secondGoalId){
          this.invalidFirstGoal = this.invalidSecondGoal = true; retrn = false;
        }
      }
      if(firstGoalId!='' && secondGoalId!=''){
        if(firstGoalId==thirdGoalId){
          this.invalidFirstGoal = this.invalidThirdGoal = true; retrn = false;
        }
      }
      if(secondGoalId!='' && thirdGoalId!=''){
        if(secondGoalId==thirdGoalId){
          this.invalidThirdGoal = this.invalidSecondGoal = true; retrn = false;
        }   
      }
    

      return retrn;
    }

    getStates(countryId: any) {
      var obj = { pageNumber: 1, PageSize: 500, CountryId: parseInt(countryId) };
      this.masterService.getStates(obj).subscribe(response => {
        this.states = response.data;
      });
    }
    getCities(stateId: any) {
      var obj = { PageNumber: 1, PageSize: 500, stateId: parseInt(stateId) };
      this.masterService.getCities(obj).subscribe(response => {
        this.cities = response.data;
      });
    }

  ngOnInit() {
    if (this.masterNgoId > 0) {
      this.getNGODetail();
    }
    this.createForm();
    this.NGOForm.controls.Fcra.setValue(false);
    
    let obj = {};
    //this.masterService.getThematicAreas(obj).subscribe(response => {
    //  if (response.isSuccess) {
    //    this.thematicAreas = response.list;
    //    var groupthematicAreas = this.groupBy(this.thematicAreas, "category");
    //    var areas = [];
    //    for (var c in groupthematicAreas) {
    //      if (groupthematicAreas[c].length > 0) {
    //        var area = { category: c, areas: groupthematicAreas[c] };
    //        areas.push(area);
    //      }
    //    }
    //    this.groupThematicAreas = areas;
    //  }
    //});
    this.getGoals();

    this.masterService.getCountries({ pageNumber: 1, PageSize: 500 }).subscribe(response => {
      this.countries = response.data;
    });
    
  }

}
