import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';




@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.component.html',
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
    errorMessage: string;
    successMessage: string;
  loginForm: FormGroup;
  validateForm: boolean = false;
  constructor( private router: Router,private _service: AuthenticationService, private _toaster: ToastrManager) { }

  createForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),

    });
  }

    forget() {
        this.errorMessage = ''; this.successMessage = '';
    this.validateForm = true;
    console.log(this.loginForm.value);
    if(this.loginForm.value.email == '' || this.loginForm.value.email == null || this.loginForm.value.email == "" ){
      this._toaster.errorToastr("Email can't be blank");
      return;

    }
    if (this.loginForm.valid) {
     
      var obj = this.loginForm.value;
      this._service.forgotPassword(obj).subscribe(response => {
          if (response.isSuccess) {
              this.successMessage = "Reset link is provided on your e-mail address";
           this.loginForm.reset();
        }
        else{
             this.errorMessage = "The username your entered is incorrect";

             }
         });
      }
    }

  ngOnInit() {
    this.createForm();
  }
}
