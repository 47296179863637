import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'admin-profile',
  templateUrl: './admin-profile.component.html',
})

export class AdminProfileComponent implements OnInit {
  adminLoginId: number;
  corporateId: number;
  personalInfoForm: FormGroup;
  corporateInfoForm: FormGroup;
  validateForm: boolean = false;
  validateCorporateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  corporateimageUrl: string;
  corprateLogofile: any;

  corporateLicenseUrl: string;
  corprateLicensefile: any;

  adminProfileUrl: string;
  adminProfileFile: any;
  fullname: string;
  errorMessage: string = "";
  Email: string;
  uploadedLicense: any[] = [];
  options: DatepickerOptions = {
    maxDate: new Date(Date.now()), // Minimal selectable date

  };
  ngolist: any;
  count: number=0;
  bannerImageUrl:string = '';
  successMessage: any;








  constructor(private router: Router, private _service: AuthenticationService, private route: ActivatedRoute, private authentication: AuthenticationService, private masterService: AdminMasterService,private masterNgo: MasterNGOService,private toaster:ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==1){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;




  }


  createForm() {
    this.personalInfoForm = new FormGroup({
      adminEmail: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      adminFirstName: new FormControl('',[ Validators.required,this.whiteSpaceValidator]),
      adminLastName: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      adminMobileNumber: new FormControl('', [Validators.required,Validators.minLength(10),this.whiteSpaceValidator]),
      adminProfilePicture: new FormControl(''),

    });
  }
  createForm2() {

    this.corporateInfoForm = new FormGroup({
      location: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      area: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      zipcode: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      cityId: new FormControl('', Validators.required),
      stateId: new FormControl('', Validators.required),
      countryId: new FormControl('', Validators.required),
      corporateName: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      // corporateRegistrationDate: new FormControl('', Validators.required),
      corporateRegistrationYear: new FormControl('', Validators.required),
      corporateEmail: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      corporateMobileNumber: new FormControl('', [Validators.required,Validators.minLength(10),this.whiteSpaceValidator]),
      corporateLogo: new FormControl(''),
      licenses: new FormControl(''),

    });
  }
  getProfile() {
    var obj = { adminloginId: this.adminLoginId, corporateId: this.corporateId };
    this._service.getAdminProfile(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        var detail = response.data;
        this.fullname = detail.adminFirstName + " " + detail.adminLastName;
        if(detail.bannerImageUrl!=null && detail.bannerImageUrl!='' && detail.bannerImageUrl!=undefined){
          this.bannerImageUrl =  detail.bannerImageUrl;
        }
        
        this.Email = detail.adminEmail;
        this.personalInfoForm.setValue({
          adminEmail: detail.adminEmail,
          adminFirstName: detail.adminFirstName,
          adminLastName: detail.adminLastName,
          adminMobileNumber: detail.adminMobileNumber,
          adminProfilePicture: detail.adminProfilePicture,
        


        });
        if(detail.address){
this.corporateInfoForm.setValue({
          location: detail.address.location,
          area: detail.address.area,
          zipcode: detail.address.zipcode,
          cityId: detail.address.cityId,
          stateId: detail.address.stateId,
          countryId: detail.address.countryId,
          corporateName: detail.corporateName,
         // corporateRegistrationDate: detail.corporateRegistrationDate,
         corporateRegistrationYear:detail.registrationYear,
          corporateEmail: detail.corporateEmail,
          corporateMobileNumber: detail.corporateMobileNumber,
          corporateLogo: detail.corporateLogo,
          licenses: detail.licenses,

        });
         this.getStates(detail.address.countryId);
        this.getCities(detail.address.stateId);
        }
        
       
        this.corporateimageUrl = detail.corporateLogo;
        this.adminProfileUrl = detail.adminProfilePicture;
        this.uploadedLicense = detail.licenses;


      }
    });
  }

  UpdatePersonalProfile() {
    this.validateForm = true; this.errorMessage = this.successMessage = "";
    if (this.personalInfoForm.valid) {
      this.personalInfoForm.value.corporateId = this.corporateId;
      this.personalInfoForm.value.adminLoginId = this.adminLoginId;
      this._service.updateAdminProfile(this.personalInfoForm.value).subscribe(response => {
        if (response.isSuccess) {
          console.log(response);

          var details = this.authentication.getToken();
          details.access_token.username = this.personalInfoForm.value.adminFirstName + ' ' +this.personalInfoForm.value.adminLastName;
          this.authentication.setToken(details);
          
          this.toaster.successToastr("Personal Info Updated Successfully");
          this.getProfile()
        }else{
          this.toaster.errorToastr("Personal Info Not Updated");
        }
      },(err)=>{
        this.toaster.errorToastr("Some Error Occured");
      });
    }
  }
  
  UpdateCorporateProfile() {
    this.validateCorporateForm = true;  this.errorMessage = this.successMessage = "";
    if (this.corporateInfoForm.valid) {
      this.corporateInfoForm.value.corporateId = this.corporateId;
      this.corporateInfoForm.value.adminLoginId = this.adminLoginId;
      this._service.updateCorporateAdminProfile(this.corporateInfoForm.value).subscribe(response => {
        if (response.isSuccess) {
          this.toaster.successToastr("Corporate Info Updated Successfully");
          if (this.corprateLogofile != null && this.corprateLogofile != undefined) {
            // upload image
            var obj = { CorporateId: this.corporateId, file: this.corprateLogofile };
            this._service.addCorporateLogo(obj).subscribe(response => {
              if (response.isSuccess) {
                this.corporateimageUrl = response.data.logo;
                var details = this.authentication.getToken();
                details.access_token.corporateLogo = this.corporateimageUrl;
                this.authentication.setToken(details);
                
              }
            });
          }
          else if (this.corprateLicensefile != null && this.corprateLicensefile != undefined) {
            // upload license

            var obj = { CorporateId: this.corporateId, file: this.corprateLicensefile };
            this._service.addCorporateLicense(obj).subscribe(response => {
              if (response.isSuccess) {
                this.uploadedLicense = response.data.licenses;
              }
            });
          }
          else {
          }


          //this.getProfile()
        }else{
          this.toaster.errorToastr("Corporate Info Not Updated");
        }
      },(err)=>{
        this.toaster.errorToastr("Some Error Occurred");
      });
    }
  }

  UpdateCorporateSlug(updatedSlug:string,currentSlug:string){

    this.errorMessage = this.successMessage = "";
 
    var obj = {"currentSlug":currentSlug,"newSlug":updatedSlug};  
  
    this.authentication.updateCorporateSlug(obj).subscribe(response => {
        if(!response.isSuccess){
          this.errorMessage = "Slug Not Updated";
        }
    },(err)=>{
      this.errorMessage = "Some Error Occurred";
    });
  }

  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }

  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }


  selectImage(event) {
    var files = event.target.files;
    this.corprateLogofile = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.corporateimageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }
  selectCorporateLicense(event) {
    var files = event.target.files;
    if(this.corprateLicensefile == null){
      this.corprateLicensefile = [];
    }
    if(this.uploadedLicense == null){
      this.uploadedLicense = [];
    }
    for(var i = 0; i < files.length; i++){
      this.corprateLicensefile.push(files[i]);
      let file = {fileName: files[i].name};
      this.uploadedLicense.push(file);
    }
    
    
    
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.corporateLicenseUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }
  selectProfileUrl(event) {
    var files = event.target.files;
    this.adminProfileFile = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.adminProfileUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

    if (this.adminProfileFile != null && this.adminProfileFile != undefined) {
      // upload image
      var obj = { adminLoginId: this.adminLoginId, file: this.adminProfileFile };
      this._service.uploadProfile(obj).subscribe(response => {
        if (response.isSuccess) {
          this.adminProfileUrl = response.data.profilePicture;
          var details = this.authentication.getToken();
          details.access_token.profilePicture = this.adminProfileUrl;
          this.authentication.setToken(details);
          this.toaster.successToastr("Profile Image Updated");
        }
      });
    }

  }

  selectBannerImage(event){
    var file = event.target.files[0];   
    this.uploadBanner(file);
  }
  
  uploadBanner(bannerImage:any){
   
    var request = {"adminLoginId":this.adminLoginId,"file":bannerImage }
    this._service.uploadBanner(request).subscribe(response => {
       if(response.isSuccess){
          this.bannerImageUrl = response.bannerImageUrl;
       }
    },(err)=>{
      this.toaster.errorToastr("Banner image not uploaded");
    });
  }
  
  deleteLicense(corporateLicenseId: number) {
    var obj = { CorporateLicenseId: corporateLicenseId };
    this._service.deletecompanyLicense(obj).subscribe(response => {
      this.getProfile();
    });
  }

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
  }

  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;
      if (val!=null && val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  getNGOList(){
    var obj = { SearchTerm: "" ,pagenumber:1 , pagesize:500, CorporateId: this.corporateId};
      this.masterNgo.NGOList(obj).subscribe(response => {
       if (response.isSuccess) {
         this.ngolist = response.list;
         this.count = response.count;
       }
   });
}

  ngOnInit() {
    this.createForm();
    this.createForm2();
    this.getProfile();
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
  }




}

