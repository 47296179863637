import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { AuthenticationService } from '../../../shared/services/authentication.service'
import { VolunteerManagerService } from '../../../shared/services/volunteer-manager.service'
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-create-vm',
  templateUrl: './create-vm.component.html',
  styleUrls: ['./create-vm.component.scss']
})
export class CreateVmComponent implements OnInit {

  volunteerManagerForm: FormGroup;
  validateForm: boolean;
  volunteerManager: any;
  Roles: any;
  volunteerManagerId: number;
  corporateId: number;
  adminLoginId: number;
  errorMessage: string;
  checkRole: boolean = false;
  buttonText:string;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private service: VolunteerManagerService,
    private fb: FormBuilder,
    private toastr: ToastrManager
  ) {
      var auth = this.authentication.getToken();
      if (auth == null) {
          this.authentication.logout(); return;
      } else {
          var login = auth.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = auth.access_token.adminLoginId;
    this.corporateId = auth.access_token.corporateId;
    this.volunteerManagerId = route.snapshot.params['id'];

  }

  ngOnInit() {

    this.getUserRoles();

    this.volunteerManagerForm = this.fb.group({
      FirstName: ['', [Validators.required, Validators.maxLength(40)]],
      LastName: ['', [Validators.maxLength(40)]],
        Email: ['', [Validators.required, Validators.maxLength(40), Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      MobileNumber: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(8)]],
      UserRole: ['', Validators.required]
    })
    this.buttonText = "Save";

    if (this.volunteerManagerId > 0) {

      this.service.detailVMService({ volunteerManagerId: this.volunteerManagerId }).subscribe(response => {
        if (response.isSuccess) {
          this.volunteerManager = response.data;
          this.volunteerManagerForm.setValue({
            FirstName: this.volunteerManager.firstName,
            LastName: this.volunteerManager.lastName,
            Email: this.volunteerManager.email,
            MobileNumber: this.volunteerManager.mobileNumber,
            UserRole: this.volunteerManager.roleId
          })
          this.buttonText = "Update";
        }
      })
    }

  }

  save(val) {
    this.validateForm = true;

    if (this.volunteerManagerForm.valid) {
  
      var pair = { "AdminLoginId": this.adminLoginId,"volunteerManagerId":this.volunteerManagerId };
      var obj = { ...val, ...pair };
      console.log("payload", obj)
      this.service.createVMService(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.showSuccess(response.message);
          this.router.navigate(['/admin/user-manager'])

        }
        else {
          if(response.responseMessage!=null && response.responseMessage!='')
             this.showError(response.responseMessage);
        }
      },(err)=>{
        this.showError("Some error occured");
      })
    }

  }

  validateRole(event) {
    console.log('value',event.target.value)
    if (event.target.value != "") {
      this.checkRole = true;
    }else{
      this.checkRole = false;
    }
  }
  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
  }

  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  get f() {
    return this.volunteerManagerForm.controls;
  }

  getUserRoles() {
    this.service.getRoles({ AdminLoginId: this.adminLoginId }).subscribe(response => {
      if (response.isSuccess) {
        this.Roles = response.userRoles;
      }
    })
  }
  showSuccess(message) {
    this.toastr.successToastr(message);
  }

  showError(message) {
    this.toastr.errorToastr(message);
  }


}
