import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'admin-profile',
  templateUrl: './admin-profile.component.html',
})

export class AdminProfileComponent implements OnInit {
  adminLoginId: number;
  personalInfoForm: FormGroup;
  corporateInfoForm: FormGroup;
  validateForm: boolean = false;
  validateCorporateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  corporateimageUrl: string;
  corprateLogofile: any;

  corporateLicenseUrl: string;
  corprateLicensefile: any;

  adminProfileUrl: string;
  adminProfileFile: any;
  fullname: string;
  errorMessage: string;
  Email:string;
  bannerImage: any;
  bannerImageUrl:string = '';



  constructor(private router: Router, private _service: AuthenticationService, private route: ActivatedRoute, private authentication: AuthenticationService, private masterService: AdminMasterService) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==2){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
  }


  createForm() {
    this.personalInfoForm = new FormGroup({
      adminEmail: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      adminFirstName: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      adminLastName: new FormControl(''),
      adminMobileNumber: new FormControl('', [Validators.required,Validators.minLength(10)]),
      adminProfilePicture: new FormControl(''),

    });
  }

  getProfile() {
    var obj = { adminloginId: this.adminLoginId };
    this._service.getSuperAdminProfile(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        if(detail.bannerImageUrl!=null && detail.bannerImageUrl!='' && detail.bannerImageUrl!=undefined){
          this.bannerImageUrl =  detail.bannerImageUrl;
        }
        this.fullname = (detail.adminFirstName!=null ? detail.adminFirstName : "") + " " + (detail.adminLastName!=null ? detail.adminLastName : "");
        this.personalInfoForm.setValue({
          adminEmail: detail.adminEmail!=null ? detail.adminEmail : "",
          adminFirstName: detail.adminFirstName!=null ? detail.adminFirstName : "",
          adminLastName: detail.adminLastName!=null ? detail.adminLastName : "",
          adminMobileNumber: detail.adminMobileNumber!=null ? detail.adminMobileNumber : "",
          adminProfilePicture: detail.adminProfilePicture!=null ? detail.adminProfilePicture : "",

        });
        this.adminProfileUrl = detail.adminProfilePicture;
        this.Email = detail.adminEmail;
      }
    });
  }

  UpdatePersonalProfile() {
    this.validateForm = true;
    if (this.personalInfoForm.valid) {
      this.personalInfoForm.value.adminLoginId = this.adminLoginId;
      this._service.updateSuperAdminProfile(this.personalInfoForm.value).subscribe(response => {
        if (response.isSuccess) {
          var details = this.authentication.getToken();
          details.access_token.username = this.personalInfoForm.value.adminFirstName + ' ' +this.personalInfoForm.value.adminLastName;
          this.authentication.setToken(details);
          console.log(details);
          this.errorMessage = "Personal Info Updated Successfully";
          this.getProfile()
        }
      });
    }
  }


  selectProfileUrl(event) {
    var files = event.target.files;
    this.adminProfileFile = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.adminProfileUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

    if (this.adminProfileFile != null && this.adminProfileFile != undefined) {
      // upload image
      var obj = { adminLoginId: this.adminLoginId, file: this.adminProfileFile };
      this._service.uploadProfile(obj).subscribe(response => {
        if (response.isSuccess) {
          this.adminProfileUrl = response.data.profilePicture;
          var details = this.authentication.getToken();
          details.access_token.profilePicture = this.adminProfileUrl;
          this.authentication.setToken(details);
        }
      });
    }
  }

  selectBannerImage(event){
    var file = event.target.files[0];
    this.uploadBanner(file);
  }
  
  uploadBanner(bannerImage:any){
    console.log("files",bannerImage);
    var request = {"adminLoginId":this.adminLoginId,"file":bannerImage }
    this._service.uploadBanner(request).subscribe(response => {
       if(response.isSuccess){
          this.bannerImageUrl = response.bannerImageUrl;
       }
    },(err)=>{
      this.errorMessage = "Banner image not uploaded";
    });
  }
  

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
  }

  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  ngOnInit() {
    this.createForm();
    this.getProfile();
  }
}

