import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss']
})
export class BeneficiaryListComponent implements OnInit {
  adminLoginId: any;
  pageNumber: number = 1;
  pageSize: number =  10;
  beneficiaries: any;
  count: any;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  nextPageClass: string;
  lastPageClass: string;
  numberOfPages: number;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  searchTerm: string;

  constructor(
    private service: AdminVolunteerService,
    private authentication: AuthenticationService, 
    private toaster:ToastrManager
    ) 
  {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    }
    else{
      this.adminLoginId = details.access_token.adminLoginId;
    }
  
  }

  getBeneficiaries(){
    var request = { "pageNumber":this.pageNumber , "pageSize":this.pageSize }
    this.service.getAllBeneficiaries(request).subscribe(response => {
      if(response.isSuccess){
         this.beneficiaries = response.list;
         this.count = response.count;
         if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
          if (this.endOn > this.count) {
            this.endOn = this.count;
          }
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    })
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getBeneficiaries();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getBeneficiaries();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getBeneficiaries();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getBeneficiaries();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getBeneficiaries();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getBeneficiaries();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getBeneficiaries();
    }
  }

  performAction(beneficiary)
  {
    var request = { 
      "AdminLoginId":this.adminLoginId,
      "BeneficiaryId" :beneficiary.beneficiaryId ,
      "UserLoginId":beneficiary.createdBy , 
      "CurriculumId":beneficiary.curriculumId 
    };
    this.service.deleteBeneficiaries(request).subscribe(response =>{
       if(response.isSuccess){
         this.toaster.successToastr("Beneficiary deleted successfully");
         this.getBeneficiaries();
       }
       else{
         this.toaster.errorToastr("Some error occured");
       }
    },(err)=>{
      this.toaster.errorToastr("Some error occured");
    })
  }
  ngOnInit() {
    this.getBeneficiaries();
  }

}
