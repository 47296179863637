import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminCampaignService } from '../../shared/services/campaigns.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as _moment from 'moment';

@Component({
  selector: 'campaign-list',
  templateUrl: './campaign-list.component.html',
})

export class CampaignListComponent implements OnInit{

  adminLoginId: number;
  corporateId: number;
  campaigns: any[];
  pageNumber: number = 1;
  pageSize: number = 10;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  searchTerm: string;
  campaignId:number; 
  titleIcon: string = "sorting";
  startDateIcon: string = "sorting";
  endDateIcon: string = "sorting";
  countryIcon: string = "sorting";
  volunteersIcon: string = "sorting";
  groupsIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
    curriculumTitle: any = '';
    errorMessage: any;
    showCurriculumModal: boolean;
    successMessage: string;
    isVolunteer: boolean = true;
    isBeneficiary: boolean = false;
    invalidEntry: boolean = false;
    campaignStatus: any;
  isBoth: boolean = false;
  updateCampaignId: any;
 



    constructor(private _service: AdminCampaignService, private authentication: AuthenticationService, private route: ActivatedRoute, private router: Router, private toaster: ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==1){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    var pageNo = localStorage.getItem("campaign_page");
    if(pageNo!=null){
      this.pageNumber = parseInt(pageNo);
    }
   
  }

  getCampaigns() {
    var obj = { AdminLoginId: this.adminLoginId, CorporateId: this.corporateId, IsPublic: false, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, SortBy: this.sortColumn, IsAscending: this.isAscending};
    console.log(obj);
    this._service.getAllCampaigns(obj).subscribe(response => {
      if (response.isSuccess) {
        this.campaigns = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }

        if(this.pageNumber > 1){
          this.firstPageClass = "";
          this.previousPageClass = "";
        }
        
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getCampaigns();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getCampaigns();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getCampaigns();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getCampaigns();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaigns();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaigns();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getCampaigns();
    }
  }

  deleteCampaign(campaignId: number,) {
    console.log(campaignId);
    var obj = { campaignId:campaignId };
    this._service.deleteCampaign(obj).subscribe(response => {
      if (response.isSuccess) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      this.pageNumber = 1;
      this.getCampaigns();
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Title":
      if (this.titleIcon == "sorting") {
        this.titleIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.titleIcon == "sorting_asc") {
        this.titleIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.titleIcon == "sorting_desc") {
        this.titleIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.countryIcon = "sorting";
      this.groupsIcon = "sorting";
      break;
    case "StartDate":
      if (this.startDateIcon == "sorting") {
        this.startDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.startDateIcon == "sorting_asc") {
        this.startDateIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.startDateIcon == "sorting_desc") {
        this.startDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.endDateIcon = "sorting";
      this.countryIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.groupsIcon = "sorting";
      break;

      case "EndDate":
      if (this.endDateIcon == "sorting") {
        this.endDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.endDateIcon == "sorting_asc") {
        this.endDateIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.endDateIcon == "sorting_desc") {
        this.endDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.countryIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.groupsIcon = "sorting";
      break;

      case "Country":
      if (this.countryIcon == "sorting") {
        this.countryIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.countryIcon == "sorting_asc") {
        this.countryIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.countryIcon == "sorting_desc") {
        this.countryIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.groupsIcon = "sorting";
      break;


      case "Groups":
      if (this.groupsIcon == "sorting") {
        this.groupsIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.groupsIcon == "sorting_asc") {
        this.groupsIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.groupsIcon == "sorting_desc") {
        this.groupsIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.volunteersIcon = "sorting";
      break;


      case "Volunteers":
      if (this.volunteersIcon == "sorting") {
        this.volunteersIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.volunteersIcon == "sorting_asc") {
        this.volunteersIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.volunteersIcon == "sorting_desc") {
        this.volunteersIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.groupsIcon = "sorting";
      this.countryIcon = "sorting";
      break;
    }
    
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getCampaigns();
  }

  performAction(campaign: any, statusId: number) {
    var obj = { campaignId: campaign.campaignId, StatusId: statusId };
   
    this._service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        this.toaster.successToastr("Opportunity published successfully");
        this.getCampaigns();
      } 
      else {
          if (statusId == 6) {
            this.toaster.errorToastr("This opportunity can't be revoked");
          }else if(statusId == 2){
              this.toaster.errorToastr("This opportunity can't be published as start date is less than current date");
              return;          
          }else{
            this.toaster.errorToastr("This opportunity is not published");
          }
      }
    },(err)=>{
      this.toaster.errorToastr("Some error occured");
    });
  }

    downloadCsvFile() {
        var list = this.campaigns;
        if (list != null && list.length > 0) {
            var obj = { AdminLoginId: this.adminLoginId, CorporateId: this.corporateId, IsPublic: false, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
            this._service.getCampaignCsv(obj).subscribe(response => {
                console.log(response);
                if (response.isSuccess) {
                    let elem = document.createElement("a");
                    elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                    elem.click();
                }
            });
        }
        else {
            alert("opportunity list is empty");
        }
    }

    onClickAddCurriculum(e) {
        this.campaignId = e.campaignId;
        this.campaignStatus = e.statusId;
        this.curriculumTitle = "";
        this.errorMessage = "";
        this.successMessage = "";
    }
    onSelectVolunteer() {
        this.isVolunteer = true;
        this.isBeneficiary = false;
        this.isBoth = false;
        
    }
    onSelectBeneficiary() {
        this.isBeneficiary = true;
        this.isVolunteer = false;
        this.isBoth = false;
    }
    onSelectBoth(){
      this.isBeneficiary = false;
      this.isVolunteer = false;
      this.isBoth = true;
    }
    createCurriculum() {

        if (this.curriculumTitle == null || this.curriculumTitle == '') {
            this.errorMessage = "Enter curriculum name";
            return;
        }

        var request = { "campaignId": this.campaignId, "title": this.curriculumTitle, "isBeneficiary": this.isBeneficiary, "isUser": this.isVolunteer,"isBoth":this.isBoth }

        this._service.createCurriculum(request)
            .subscribe(res => {
                if (res.isSuccess) {
                    this.addPaginationInfo();
                    this.toaster.successToastr("Curriculum created successfully");
                    this.router.navigate(['/admin/campaigns/private/curriculum', this.campaignId, this.campaignStatus]);
               
                 //   this.curriculumTitle = '';
                } else {
                    this.toaster.errorToastr("Some error occured");
                }
                this.clear();
            }, (err) => {
                    this.toaster.errorToastr("Some error occured");
                    this.clear();
            });

    }
    checkError() {
        this.invalidEntry = false;
        if (this.curriculumTitle.trim() == '') {
            this.invalidEntry = true;
        }
    }

    clear() {
        this.curriculumTitle = "";
        this.isVolunteer = true;
        this.isBeneficiary = false;
    }

    DuplicateCampaign(campaignId){
      this._service.duplicateCampaign({"CampaignId":campaignId}).subscribe(response => {
          if(response.isSuccess){
            this.getCampaigns();
          }
      })
   }

   OnUpdate(campaignId:any){ 
     
    this.updateCampaignId = campaignId;
    var a = document.getElementById('updateBtn') as HTMLElement;
    console.log(a);
    a.click();
  }

  addPaginationInfo(){
    localStorage.removeItem("campaign_page");
    localStorage.setItem("campaign_page",this.pageNumber.toString());
}


  ngOnInit() {
    this.getCampaigns();
  }

}
