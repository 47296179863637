import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from "../../shared/services/authentication.service";
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
//import { forEach } from '@angular/router/src/utils/collection';
import { ThrowStmt } from '@angular/compiler';
import { EditorconfigModule } from '../../shared/models/editorconfig/editorconfig.module';
import 'quill-emoji/dist/quill-emoji.js';


@Component({
    selector: 'ngo-create',
    templateUrl: './ngo-create.component.html',
})

export class NGOCreateComponent implements OnInit {


    NGOForm: FormGroup;
    validateForm: boolean = false;
    masterNgoId: number;
    errorMessage: string;
    corporateId: number;
    adminLoginId: number;
    isSubmitting: boolean = false;
   goals:any[]=[];
  listOfGoals: any;
  countries: any;
  states: any;
  cities: any;
  imageFile: any;
  imageFileUrl: any=null;
  invalidFirstGoal: boolean = false;
  invalidSecondGoal: boolean = false;
  invalidThirdGoal: boolean = false;
  editorUrl:string = '';
  editorConfiguration:any;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
   
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
   
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
   
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
   
      ['clean'],                                         // remove formatting button
      ['emoji'],
      ['link','image','video'],
                               // link and image, video
    ],
    "emoji-toolbar": true
  };

    constructor(private router: Router, private _service: MasterNGOService, private route: ActivatedRoute,private masterService: AdminMasterService, private authentication: AuthenticationService,private toaster:ToastrManager, private editorConfig:EditorconfigModule) {

        var details = this.authentication.getToken();
        if (details == null) {
            this.authentication.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 1) {
                this.authentication.logout(); return;
            }
        }
        this.adminLoginId = details.access_token.adminLoginId;
        this.corporateId = details.access_token.corporateId;
        this.editorConfiguration = this.editorConfig.configuration;
        this.editorUrl = this.editorConfig.editorUrl;
        router.events.subscribe((response) => {
            this.masterNgoId = route.snapshot.params['id'];
        });


    }

    createForm() {
        this.NGOForm = new FormGroup({
            CorporateId: new FormControl(this.corporateId),
            Name: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
            OwnerName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
            Email: new FormControl('', [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]),
            MobileNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
            Description:new FormControl('',[Validators.required,this.whiteSpaceValidator]),
            Fcra: new FormControl(false,Validators.required),
        Designation: new FormControl('',[Validators.required, this.whiteSpaceValidator]),
        FcraNumber: new FormControl('',Validators.maxLength(10)),
        WebsiteLink:new FormControl('',Validators.pattern(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/)),
        NgoContactNumber: new FormControl('',[Validators.required, this.whiteSpaceValidator]),
        CountryId: new FormControl('',Validators.required),
        StateId: new FormControl('',Validators.required),
        CityId: new FormControl('',Validators.required),
        FirstPriorityGoal: new FormControl('',Validators.required),
        SecondPriorityGoal: new FormControl({value:'',disabled:true}),
        ThirdPriorityGoal: new FormControl({value:'',disabled:true}),
        Location: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
        Logo:new FormControl('')
        });
    }

    save() {
        this.validateForm = true;

        if (this.NGOForm.valid) {

            this.isSubmitting = true;
            var selectedGoals = [];
            selectedGoals.push({GoalId:this.NGOForm.get("FirstPriorityGoal").value,Priority:1});
            selectedGoals.push({GoalId:this.NGOForm.get("SecondPriorityGoal").value,Priority:2});
            selectedGoals.push({GoalId:this.NGOForm.get("ThirdPriorityGoal").value,Priority:3});
            var newObj = {"Goals":selectedGoals}

            if (this.masterNgoId > 0) {
                this.NGOForm.value.masterNgoId = this.masterNgoId;
                if(!this.NGOForm.get('Fcra').value){
                  this.NGOForm.get('FcraNumber').setValue('');
                }
        var val = this.NGOForm.value;

                this._service.updateNGO({...val,...newObj}).subscribe(response => {
                    if (response.isSuccess) {
                        this.router.navigate(['/admin/ngo']);
                    }
                    if (response.isExist) {
                        this.errorMessage = 'NGO with this name or email already exist.'
                    }

                        this.isSubmitting = false;

                }, (err) => {
                        this.isSubmitting = false;
                });
            }
            else {
              if(!this.NGOForm.get('Fcra').value){
                this.NGOForm.get('FcraNumber').setValue('');
              }
        var val = this.NGOForm.value;
                this._service.createNGO({...val,...newObj}).subscribe(response => {
                
                    if (response.isSuccess) {
                  
                        this.router.navigate(['/admin/ngo']);
                    }
                    if (response.isExist) {
                        this.errorMessage = 'NGO with this name or email already exist.'
                    }

                    this.isSubmitting = false;
                }, (err) => {
                    this.isSubmitting = false;
                });
            }

        }
    }


    getNGODetail() {

        var obj = { masterNgoId: this.masterNgoId };
        this._service.getNGODetail(obj).subscribe(response => {
            if (response.isSuccess) {
                var detail = response.data;
                this.NGOForm.setValue({
                    CorporateId: this.corporateId,
                    Name: detail.name,
                    OwnerName: detail.ownerName,
                    Email: detail.email,
                    MobileNumber: detail.mobileNumber,
                    Description:detail.description,
 Location: detail.location,
          Fcra: detail.fcra!=null ? detail.fcra : false,
          FcraNumber:detail.fcraNumber,
          Logo:detail.logo,
          WebsiteLink:detail.websiteLink,
          Designation: detail.designation,
          NgoContactNumber: detail.ngoContactNumber,
          CountryId: detail.address!=null ? detail.address.countryId : '',
          StateId: detail.address!=null ? (detail.address.stateId!=null ? detail.address.stateId : '') : '',
          CityId:  detail.address!=null ? (detail.address.cityId!=null ? detail.address.cityId : '') : '',
          FirstPriorityGoal: detail.goals!=null && detail.goals.length > 0 ? this.filterGoalByPriority(1,detail.goals) : '',
          SecondPriorityGoal: detail.goals!=null && detail.goals.length > 1 ? this.filterGoalByPriority(2,detail.goals) : '',
          ThirdPriorityGoal: detail.goals!=null && detail.goals.length > 2 ? this.filterGoalByPriority(3,detail.goals) : '',
                });
    if(detail.address!=null && detail.address.stateId!=null){
          this.getStates(detail.address.countryId);
        } if(detail.address!=null && detail.address.cityId!=null){
          this.getCities(detail.address.stateId);
        }
        this.NGOForm.controls.SecondPriorityGoal.enable();

        if(detail.goals!=null){
          if(detail.goals.length > 1){
            this.NGOForm.controls.ThirdPriorityGoal.enable();
          }
        }

        this.imageFileUrl = detail.logo;
            }
        });
    }


    keyDownHandler(event) {
        if (event.target.value.length > 0) {
            return true;
        } else {
            if (event.keyCode === 32) {
                return false;
            }
        }
    }

    // only number validation
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    noWhiteSpace(event) {
        if (event.keyCode === 32) {
            return false;
        }
        return true;
    }

    whiteSpaceValidator(control: FormControl): { [key: string]: any } | null {
        var val = control.value;

        if (val!=null && val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }

   onSelectingImageFile(event){
      var file = event.target.files[0];
      if (file != null) {
          if (file.size > 1048576) {
              this.toaster.errorToastr("Upload image upto 1 mb only");
              return;
          }
          this.imageFile = file;
          var reader = new FileReader();
          reader.onload = (e: any) => {
              this.imageFileUrl = e.target.result;
          }
          reader.readAsDataURL(file);
      }
      event.target.value = ''; 
      this.uploadLogo();
  }

  uploadLogo(){
    if(this.imageFile!=null && this.imageFile!=undefined){
      var ob = {file:this.imageFile}
      this._service.uploadImage(ob).subscribe(response => {
        if(response.isSuccess){
           this.imageFileUrl = response.url; this.NGOForm.get("Logo").setValue(response.url);
        }
      },(err)=>{
        this.toaster.errorToastr("Some error occurred");
      });
    }
  }

  deleteImage(){
    this.imageFileUrl = ''; this.imageFile = null; this.NGOForm.get("Logo").setValue('');
  }

    getGoals(){
      this.masterService.getGoals().subscribe(response => {
          if(response.isSuccess){
             this.listOfGoals = response.list;
          }
      });
    }

    filterGoalByPriority(priority:number,data:any[]){
      for(var i=0;i<data.length;i++){
        if(priority == data[i].priority){
          return data[i].goalId;
        }
      }
   }

    onSelectFcra(evt){
      if(evt == 0){
        this.NGOForm.controls.Fcra.setValue(false);
       
        this.NGOForm.get('FcraNumber').clearValidators();
        this.NGOForm.get('FcraNumber').setErrors(null);
      }
      else if(evt == 1){
        this.NGOForm.controls.Fcra.setValue(true);
        this.NGOForm.get('FcraNumber').setValidators(Validators.required);      
      }
      else{
        this.NGOForm.controls.Fcra.setValue(false);
      
        this.NGOForm.get('FcraNumber').clearValidators();
        this.NGOForm.get('FcraNumber').setErrors(null);
      }
    }

 
    SetFirstPriorityGoal(goalId:any){

      if(goalId==''){
         this.NGOForm.controls.SecondPriorityGoal.setValue('');
         this.NGOForm.controls.ThirdPriorityGoal.setValue('');        
         this.NGOForm.controls.SecondPriorityGoal.disable();
         this.NGOForm.controls.ThirdPriorityGoal.disable();        
      }
      else{
        if(!this.validateGoalsSelection()){
           return;
        }   
        this.NGOForm.controls.SecondPriorityGoal.enable();      
        if(this.NGOForm.get("ThirdPriorityGoal").value!=''){
          this.NGOForm.controls.ThirdPriorityGoal.enable();
        }       
      }    
    }

    SetSecondPriorityGoal(goalId:any){

      if(goalId==''){
        this.NGOForm.controls.ThirdPriorityGoal.setValue('');
        this.NGOForm.controls.ThirdPriorityGoal.disable();
      }
      else{
        if(!this.validateGoalsSelection()){
           return;
        }
        this.NGOForm.controls.ThirdPriorityGoal.enable();     
      }      
    }

    SetThirdPriorityGoal(goalId:any){      
        this.validateGoalsSelection();
    }

    validateGoalsSelection(){
      var firstGoalId = this.NGOForm.controls.FirstPriorityGoal.value;
      var secondGoalId = this.NGOForm.controls.SecondPriorityGoal.value;
      var thirdGoalId = this.NGOForm.controls.ThirdPriorityGoal.value;
      
      this.invalidFirstGoal = this.invalidSecondGoal = this.invalidThirdGoal = false;
      var retrn = true;
      if(firstGoalId!='' && secondGoalId!=''){
        if(firstGoalId==secondGoalId){
          this.invalidFirstGoal = this.invalidSecondGoal = true; retrn = false;
        }
      }
      if(firstGoalId!='' && secondGoalId!=''){
        if(firstGoalId==thirdGoalId){
          this.invalidFirstGoal = this.invalidThirdGoal = true; retrn = false;
        }
      }
      if(secondGoalId!='' && thirdGoalId!=''){
        if(secondGoalId==thirdGoalId){
          this.invalidThirdGoal = this.invalidSecondGoal = true; retrn = false;
        }   
      }
    

      return retrn;
    }

    getStates(countryId: number) {
      var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
      this.masterService.getStates(obj).subscribe(response => {
        this.states = response.data;
      });
    }
    getCities(stateId: number) {
      var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
      this.masterService.getCities(obj).subscribe(response => {
        this.cities = response.data;
      });
    }
    ngOnInit() {
        if (this.masterNgoId > 0) {
            this.getNGODetail();
        }
        this.createForm();
  this.NGOForm.controls.Fcra.setValue(false);
  this.getGoals();

    this.masterService.getCountries({ pageNumber: 1, PageSize: 500 }).subscribe(response => {
      this.countries = response.data;
    });
    


    }

}
