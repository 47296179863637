import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { FormBuilder, FormControl, FormGroup, Validators,FormArray } from '@angular/forms';
import { QuizService } from '../../../../shared/services/quiz.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { Options } from 'selenium-webdriver/chrome';
import { window } from 'rxjs/operators';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})
export class CreateQuestionComponent implements OnInit {
  quizId: any;
  adminLoginId: any;
  form: FormGroup;
  imageFile:File;
  imageFileUrl:string;
  audioFile:File;
    audioFileUrl: string;
    mediaImageFile: File;
    mediaAudioFile: File;
    mediaImageUrl: string;
    mediaAudioUrl: string;
  options:any[];
  optionMedia:any[]=[];
  optionMediaUrl:any[]=[];
  IsOp1Correct:boolean;
  IsOp2Correct:boolean;
  IsOp3Correct:boolean;
  IsOp4Correct:boolean;
  validateForm: boolean;
  corporateId: any;
    hintRequired: boolean = false;
    mediaType: number = 0;

    imageOption1:File;
    imageOption2:File;
    imageOption3:File;
    imageOption4:File;

    imageOptionUrl1:string | ArrayBuffer;
    imageOptionUrl2:string | ArrayBuffer;
    imageOptionUrl3:string | ArrayBuffer;
    imageOptionUrl4:string | ArrayBuffer;
   
    

  constructor(
    private router:Router,
      private activeRoute: ActivatedRoute,
      private location: Location,
    private fb:FormBuilder,
    private service:QuizService,
    private auth:AuthenticationService,
    private toaster:ToastrManager
    ) { 
      var details = this.auth.getToken();
    
      if (details == null) {
          this.auth.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.auth.logout(); return;
          }
      }
      this.adminLoginId = details.access_token.adminLoginId;
      this.corporateId = details.access_token.corporateId;
      this.quizId = this.activeRoute.snapshot.params['id'];
    }


  onSelectionChanged(event){
    this.IsOp1Correct = this.IsOp2Correct = this.IsOp3Correct = this.IsOp4Correct = false;
    var selection = event.target.value;
    if(selection == "1"){
      this.IsOp1Correct = true;
    }
    if(selection == "2"){
      this.IsOp2Correct = true;
    }
    if(selection == "3"){
      this.IsOp3Correct = true;
    }
    if(selection == "4"){
      this.IsOp4Correct = true;
    }
  }

  onHintSelected(){
    if(!this.hintRequired){
      this.hintRequired = true;
    }
    else{
      this.hintRequired = false;
    }
   }

  onSelectingImageFile(event){
      var file = event.target.files[0];
      if (file != null) {
          if (file.size > 2097152) {
              this.toaster.errorToastr("Upload image upto 2 mb only");
              return;
          }
          this.imageFile = file;
          var reader = new FileReader();
          reader.onload = (e: any) => {
              this.imageFileUrl = e.target.result;
          }
          reader.readAsDataURL(file);
      }
      event.target.value = ''; 
  }

  onSelectingImageOption(event,i:number){
  
    var file = event.target.files[0];
    
    if (file != null) {
        if (file.size > 2097152) {
            this.toaster.errorToastr("Upload image upto 2 mb only");
            return;
        }       
       
        var imageFile = file; 
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.optionMediaUrl[i] = e.target.result; this.optionMedia[i] = imageFile;
        }
       
        reader.readAsDataURL(file);     
        
    }
    event.target.value = ''; 
}

onDeletingImageOption(i:number){
  this.optionMediaUrl[i] = ''; this.optionMedia[i] = null;
} 

onDeletingOption(i:number){
  this.option.removeAt(i);  
  this.optionMediaUrl.slice(i,1); this.optionMedia.slice(i,1);
}

onSelectingOptionType(op:any,type:any){
   var ctrlId = "option"+op.toString();
   var ctrl = this.form.get(ctrlId);

   if(type == 1){
    ctrl.setValidators(Validators.required);
   }
   else{
     ctrl.clearValidators(); ctrl.setErrors(null);
   }
}

  onSelectingAudioFile(event){
      var file = event.target.files[0]
      if (file != null) {
          if (file.size > 2097152) {
              this.toaster.errorToastr("Upload file upto 2 mb only");
              return;
          }
          this.audioFile = file;
         
          var reader = new FileReader();
          reader.readAsDataURL(file);
          this.audioFileUrl = this.getFilenameFromURL(file.name);
      }
      event.target.value = '';
  }

    onDeleteMediaImage() {
        this.imageFileUrl = ''; this.imageFile = null;
    }
    onDeleteMediaAudio() {
        this.audioFileUrl = ''; this.audioFile = null;
    }
    onSelectingMediaImageFile(event) {
        var file = event.target.files[0];
        if (file != null) {
            if (file.size > 2097152) {
                this.toaster.errorToastr("Upload image upto 2 mb only");
                return;
            }

            var reader = new FileReader();
            reader.onload = (e: any) => {
                this.mediaImageFile = file; this.mediaImageUrl = e.target.result;
                console.log(this.mediaImageUrl)
            }
            reader.readAsDataURL(file);
        }
        event.target.value = '';
    }

    onSelectingMediaAudioFile(event) {
        var file = event.target.files[0]

        if (file != null) {
            if (file.size > 2097152) {
                this.toaster.errorToastr("Upload file upto 2 mb only");
                return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);

            this.mediaAudioFile = file; this.mediaAudioUrl = this.getFilenameFromURL(file.name);
        }
        event.target.value = '';
    }

    onDeleteMediaImageFile() {
        this.mediaImageFile = null; this.mediaImageUrl = '';
    }

    onDeleteMediaAudioFile() {
        this.mediaAudioFile = null; this.mediaAudioUrl = '';
    }

    onSelectingMediaType(event) {
        this.mediaType = event.target.value;
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

    validateMediaOptions(){
      var isAllMediaProvided = true;
      for(var i=0; i<this.option.length; i++){
           if(this.option.controls[i].get("type").value == 2){
              if(this.optionMedia[i]==null || this.optionMedia[i]==undefined){
                  isAllMediaProvided = false;
              }
           }
      }

      if(!isAllMediaProvided){
          this.toaster.errorToastr("Please provide all media options"); return false;
      }
      else{
          return true;
      }
  }



  save(){
      this.validateForm = true;

      if(this.option.length < 2){
        this.toaster.errorToastr("At least 2 options are mandatory");
        return;
      }

      if(!this.validateMediaOptions()) return false; if(!this.validateOptions()) return false;

      if (this.mediaType == 1 && (this.mediaImageFile == null || this.mediaImageFile == undefined)) {
          this.toaster.errorToastr("Upload Media Image");
          return;
      }
      if (this.mediaType == 4 && (this.mediaAudioFile == null || this.mediaAudioFile == undefined)) {
          this.toaster.errorToastr("Upload Media Audio");
          return;
      }

   

   
    if(this.hintRequired && this.f.hintType.value == "1" && this.f.hint.value.trim()==''){
      this.toaster.errorToastr("Enter Hint");
      return;
    }
    if(this.hintRequired && this.f.hintType.value == "2" && (this.imageFile == null || this.imageFile == undefined)){
        this.toaster.errorToastr("Upload Hint Image");
        return;
    }
    if(this.hintRequired && this.f.hintType.value == "3" && (this.audioFile == null || this.audioFile == undefined)){
      this.toaster.errorToastr("Select Audio file");
      return;
    }
    
    if(this.form.valid){
      this.options = this.option.value;

      var formData = new FormData();
      formData.append("AdminLoginId",this.adminLoginId);
      formData.append("CorporateId",this.corporateId);
      formData.append("QuizId",this.quizId);
      formData.append("Question",this.f.question.value);
      formData.append("Options",JSON.stringify(this.options));
      formData.append("Hint",this.f.hint.value);
      formData.append("HintType",this.f.hintType.value);
        formData.append("Marks", this.f.maximumMarks.value);
        if(this.mediaType > 0){
          formData.append("MediaType", this.mediaType.toString());
      }
      for(var i=0;i<this.optionMedia.length;i++){
        var name = "uploadop"+(i+1).toString();
        formData.append(name,this.optionMedia[i])
    }

      if(this.hintRequired){
        formData.append("HintRequired","1");
      }
      else{
        formData.append("HintRequired","0");
      }

      if(this.f.hintType.value == "2"){
          formData.append("UploadedHint",this.imageFile);
      }
      if(this.f.hintType.value == "3"){
          formData.append("UploadedHint",this.audioFile);
      }

        if (this.mediaType == 1) {
            formData.append("Media", this.mediaImageFile);
        }
        if (this.mediaType == 4) {
            formData.append("Media", this.mediaAudioFile);
        }

      
      this.service.callQuestionAddService(formData)
      .subscribe(res => {
        if(res.isSuccess){
          this.toaster.successToastr("Question created successfully");
            this.navigateBack();
        }
      })
    }
  }

    getFilenameFromURL(url) {
        var filename = url.substring(url.lastIndexOf('/') + 1);
        return filename;
    }

    navigateBack() {
        this.location.back();
  }

  get f() {
    return this.form.controls;
  }

  onSelectCorrectOption(i:number,val){
      
    for(var j=0; j<this.option.controls.length;j++){
      if(j==i){
        this.option.controls[j].get("isCorrect").setValue(val);
      
      }
      else{
        this.option.controls[j].get("isCorrect").setValue(false)
      }     
    }     
  }

  onSelectOptionType(i){    
    if(this.option.controls[i].get("type").value == 1){                     
        var ctrl = this.option.controls[i].get("optionText");    
        ctrl.setValidators(Validators.required)         
        if(ctrl.value == '' || ctrl.value == null){
           ctrl.setErrors({"required":true});
        }  
    }
    else{          
        this.option.controls[i].get("optionText").clearValidators();    
        this.option.controls[i].get("optionText").setErrors(null);
    }        
 }

validateOptions(){
   var isCorrectOptionSelected = false;
   for(var i=0; i<this.option.length; i++){
       if(this.option.controls[i].get("isCorrect").value)
          isCorrectOptionSelected = true;
   }

   if(!isCorrectOptionSelected){
       this.toaster.errorToastr("Choose correct option");  return false; 
   }else{
       return true;
   }
}

get option() : FormArray {
    return this.form.get('option') as FormArray;
}

addNewOption(){
    this.option.push(new FormGroup({           
         optionId:new FormControl(0),
         optionText: new FormControl('',Validators.required),
         isCorrect: new FormControl(false),
         isDeleted: new FormControl(false),
         type: new FormControl(1)
      }))

    this.optionMedia.push(null); this.optionMediaUrl.push('');
    
}

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }


  ngOnInit() {
    this.form = this.fb.group({
        question:["",[Validators.required,this.whiteSpaceValidator]],
        maximumMarks:["",[Validators.required, this.whiteSpaceValidator]],
        hint:[""],
        hintType:[""],
        isHintPublished:[""],
        option:new FormArray([])
     });

     this.addNewOption();
        this.addNewOption();
  }
}
