import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Pipe } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LearningZoneService } from '../../shared/services/learningzone.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'learningZone-detail',
  templateUrl: './learningZone-detail.component.html',
})

export class LearningZoneDetailComponent implements OnInit {
  adminLoginId: number;
  corporateId: any;
  learningZoneId: any;
  learningZoneDetail: any;
  safeSrc: SafeResourceUrl;
  documentName: any;


    constructor(private authentication: AuthenticationService, private service: LearningZoneService, private router: Router, private route: ActivatedRoute, private location: Location,private sanitizer: DomSanitizer) {
        var details = this.authentication.getToken();
        if (details == null) {
            this.authentication.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 1) {
                this.authentication.logout(); return;
            }
        }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
        this.learningZoneId = this.route.snapshot.params['learningZoneId'];
  }

  getLearningZoneDetail(){
    var obj = {"learningZoneId":this.learningZoneId}
    this.service.getLearningZoneDetail(obj)
    .subscribe(res => {
      console.log(res);
      if(res.isSuccess){
         this.learningZoneDetail = res.data;
        
         if(this.learningZoneDetail.mediaType == 2) {
          this.safeSrc = this.learningZoneDetail.youtubeVideoUrl!=null && this.learningZoneDetail.youtubeVideoUrl!='' ? this.sanitizer.bypassSecurityTrustResourceUrl(this.learningZoneDetail.youtubeVideoUrl) : this.sanitizer.bypassSecurityTrustResourceUrl(null);
        }
        else if(this.learningZoneDetail.mediaType == 3){
          this.documentName = this.getFilenameFromURL(this.learningZoneDetail.documentUrl);
          this.safeSrc = this.learningZoneDetail.documentUrl!=null && this.learningZoneDetail.documentUrl!='' ? this.sanitizer.bypassSecurityTrustResourceUrl(this.learningZoneDetail.documentUrl) : this.sanitizer.bypassSecurityTrustResourceUrl(null);
        }         
         
      }
    })
  }

  getFilenameFromURL(url) {
    var filename = url.substring(url.lastIndexOf('/') + 1);
    return filename;
}

playVideo(event){
  console.log(event);
  let opner = window.open(event, '_blank', 'height=450,width=700');

    }

    navigateBack() {
        this.location.back();
    }

  ngOnInit() {
    this.getLearningZoneDetail();

  }

}

//@Pipe({ name: 'safeHtml' })
//export class Safe {
//    constructor(private sanitizer: DomSanitizer) { }

//    transform(style) {
//        return this.sanitizer.bypassSecurityTrustHtml(style);
//    }
//}
