import { Component, OnInit } from '@angular/core';
import { VolunteerManagerService } from '../../../shared/services/volunteer-manager.service'
import { AuthenticationService } from '../../../shared/services/authentication.service'
import { Observable } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-vm-list',
  templateUrl: './vm-list.component.html',
  styleUrls: ['./vm-list.component.scss']
})
export class VmListComponent implements OnInit {

    adminLoginId: number;
    corporateId: number;
    volunteersManagers: any[];
    UserRoles:any;
    pageNumber: number = 1;
    pageSize: number = 10;
    searchTerm: string;
    count: number = 0;
    startFrom: number = 1;
    endOn: number = this.pageSize;
    numberOfPages: number;
    lastPageClass: string;
    firstPageClass: string = "disabled";
    previousPageClass: string = "disabled";
    nextPageClass: string;
    isAscending: boolean = false;
    sortColumn: string;
    selectedVolunteer: any;
    comment: string;
    roleId:number;
    isSearchEnabled:boolean=false;

  constructor(private service:VolunteerManagerService,private authentication:AuthenticationService,private toaster:ToastrManager) {

      var auth = this.authentication.getToken();
      if (auth == null) {
          this.authentication.logout(); return;
      } else {
          var login = auth.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = auth.access_token.adminLoginId;
   }

    ngOnInit() {
        this.getVolunteersManager();
       
    }

    getVolunteersManager() {
        var obj = { AdminLoginId: this.adminLoginId, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn, CorporateId: this.corporateId, RoleId:this.roleId };
        this.service.getVMListService(obj).subscribe(response => {
            console.log(response);

            if (response.isSuccess) {
                this.volunteersManagers = response.list;
                this.count = response.count;
                if (this.count == 0) {
                    this.startFrom = 0;
                }
                else {
                    this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
                }
                if (this.count <= this.pageSize) {
                    this.endOn = this.count;
                    this.nextPageClass = "disabled";
                    this.lastPageClass = "disabled";
                }
                else {
                    this.endOn = this.pageNumber * this.pageSize;
                    if (this.endOn > this.count) {
                        this.endOn = this.count;
                    }
                }
                var pages = this.count / this.pageSize;
                this.numberOfPages = 0;
                for (var i = 0; i < pages; i++) {
                    this.numberOfPages = i + 1;
                }
                if (this.endOn > this.count) {
                    this.endOn = this.count;
                }
            }
        });
    }

    selectvolunteer(volunteer: any) {
        this.selectedVolunteer = volunteer;
        this.comment = '';
    }

    checkIfBoxEmpty() {
        if (this.searchTerm == '' || this.searchTerm == null) {
            this.pageNumber = 1;
            this.firstPageClass = "disabled";
            this.previousPageClass = "disabled";
            this.nextPageClass = "";
            this.lastPageClass = "";
            this.getVolunteersManager();
        }
    }

    firstPage() {
        if (this.pageNumber > 1) {
            this.firstPageClass = "disabled";
            this.previousPageClass = "disabled";
            this.nextPageClass = "";
            this.lastPageClass = "";
            this.pageNumber = 1;
            this.getVolunteersManager();
        }
    }

    lastPage() {
        if (this.pageNumber < this.numberOfPages) {
            this.firstPageClass = "";
            this.previousPageClass = "";
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
            this.pageNumber = this.numberOfPages;
            this.getVolunteersManager();
        }
    }

    previousPage() {
        if (this.pageNumber > 1) {
            this.pageNumber = this.pageNumber - 1;
            if (this.pageNumber == 1) {
                this.firstPageClass = "disabled";
                this.previousPageClass = "disabled";
                this.nextPageClass = "";
                this.lastPageClass = "";
            }
            this.getVolunteersManager();
        }
    }

    nextPage() {
        if (this.pageNumber < this.numberOfPages) {
            this.pageNumber = this.pageNumber + 1;
            this.firstPageClass = "";
            this.previousPageClass = "";
            if (this.pageNumber == this.numberOfPages) {
                this.nextPageClass = "disabled";
                this.lastPageClass = "disabled";
            }
            this.getVolunteersManager();
        }
    }

    keyDownHandler(event) {
        if (event.target.value.length > 0) {
          return true;
        } else {
          if (event.keyCode === 32) {
            return false;
          }
        }
      }

    removeSearch(){
        this.searchTerm = '';
        this.isSearchEnabled = false;
        this.getVolunteersManager();
    }
    applySearch() {
        if(this.searchTerm == undefined || this.searchTerm == null || this.searchTerm.trim() == ''){
           this.toaster.errorToastr("Enter search term");
        }
        else{
            
            this.getFilteredList();
            this.isSearchEnabled = true;
        }        
    }

    getFilteredList(){
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getVolunteersManager();
    }

    changePageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getVolunteersManager();
        
      }
    getUserRoles() {
        this.service.getRoles({ AdminLoginId: this.adminLoginId }).subscribe(response => {
          if (response.isSuccess) {
            this.UserRoles = response.userRoles;
          }
        })
      }

    performAction(vm: any) {
        var obj = { VolunteerManagerId: vm.volunteerManagerId};
        this.service.performAction(obj).subscribe(response => {
            if (response.isSuccess) {
                this.getVolunteersManager();
            }
        });
    }
}
