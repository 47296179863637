import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VolunteermanagerProfileComponent } from './profile/volunteermanager-profile/volunteermanager-profile.component';
import { VolunteerCreateComponent } from './volunteer/volunteer-create/volunteer-create.component';
import { VolunteerListComponent } from './volunteer/volunteer-list/volunteer-list.component';
import { VolunteerDetailComponent } from './volunteer/volunteer-detail/volunteer-detail.component';

const routes: Routes = [

  {
    path:'',
    data:{
      title:'volunteermanager',
      status:false
    },
    children: [
      
      {
        path:'volunteer/list',
        component: VolunteerListComponent
      },
      {
        path:'volunteer/create',
        component: VolunteerCreateComponent
      },
      {
        path:'volunteer/update/:id',
        component: VolunteerCreateComponent
      },
      {
        path:'volunteer/detail',
        component: VolunteerDetailComponent
      },
      {
        path:'profile',
        component:VolunteermanagerProfileComponent
      }
      
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VolunteermanagerRoutingModule { }
