import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { AdminMasterService } from './../../shared/services/master.services';
import { AuthenticationService } from './../../shared/services/authentication.service';





@Component({
  selector: 'supporterlogos',
  templateUrl: './supporterlogos.component.html',
})

export class SupporterLogosComponent implements OnInit   {


  errorMessage: string;
  sponserimageUrl1: string;
  sponserimageUrl2: string;

  file1: any;
  file2: any;
  adminLoginId: number;
  logos: any[] = [];
  mediaForm: FormGroup;


  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute) {
    router.events.subscribe((response) => {
        var details = this._service.getToken();
     
        if (details == null) {
            this._service.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this._service.logout(); return;
            }
        }
        if (details != null) {
            this.adminLoginId = details.access_token.adminLoginId;
        }
      
    });

  }

  selectImage1(event) {
    var files = event.target.files;
    this.file1 = files[0];
    //if (this.file.size <= 2 * 1024 * 1024) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.sponserimageUrl1 = e.target.result;
    }
    reader.readAsDataURL(files[0]);
    this.addSupporterLogos(1, this.file1);
  }
  

  selectImage2(event) {
    var files = event.target.files;
    this.file2 = files[0];
    //if (this.file.size <= 2 * 1024 * 1024) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.sponserimageUrl2 = e.target.result;
    }
    reader.readAsDataURL(files[0]);
        this.addSupporterLogos(2, this.file2);

  }
  addSupporterLogos( mediaId: number, mediaFile : any){
      if (mediaFile != null && mediaFile != undefined) {
      // upload image
      var obj = { SponsoredMediaId:mediaId, file: mediaFile };
      this.masterService.addsupporterlogo(obj).subscribe(response => {
        if (response.isSuccess) {
          this.errorMessage = "Logo Updated Successfully";
          this.getSupporterLogos()
        }
        else{
          this.errorMessage = "Some Error Encounter";

        }
      });
    }

  }

   getSupporterLogos() {
    var obj = { };
    this.masterService.getsupporterlogo(obj).subscribe(response => {
      if (response.isSuccess) {
        this.logos = response.list;
         this.sponserimageUrl1 = this.logos[0].sponsoredUrl1;
         this.sponserimageUrl2 = this.logos[1].sponsoredUrl1;

      }
    });
  }

   createForm() {
    this.mediaForm = new FormGroup({
      SponsoredMediaId: new FormControl(''),
    });
  }

  ngOnInit() {
    this.getSupporterLogos()
    this.createForm(); 
  }


}
