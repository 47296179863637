import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VolunteerManagerService } from '../../../shared/services/volunteer-manager.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-vm-detail',
  templateUrl: './vm-detail.component.html',
  styleUrls: ['./vm-detail.component.scss']
})
export class VmDetailComponent implements OnInit {

  volunteerManagerId: number;
  volunteerManager: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
  private authentication: AuthenticationService,
  private service: VolunteerManagerService,) { 
      var auth = this.authentication.getToken();
      if (auth == null) {
          this.authentication.logout(); return;
      } else {
          var login = auth.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    router.events.subscribe((response) => {
      this.volunteerManagerId = route.snapshot.params['id'];
  });


  }

  ngOnInit() {
    if(this.volunteerManagerId!=0){
      this.service.detailVMService({VolunteerManagerId:this.volunteerManagerId}).subscribe(response => {
        if(response.isSuccess){
          this.volunteerManager = response.data;
        }
      })
    }
  }

}
