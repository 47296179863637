import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, AnimationPlayer, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'skill-list',
  templateUrl: './skill.component.html',
})

export class SkillComponent implements OnInit {

  adminLoginId: number;
  skills: any[] = [];
  skillName: string;
  rate: any;
  selectedSkill: any;
  selectedSkillId: any = 0;
  errorMessage: string = '';
  isSubmitting: boolean = false;


  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute,private toaster:ToastrManager) {
    router.events.subscribe((response) => {
        var details = this._service.getToken();
        if (details == null) {
            this._service.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this._service.logout(); return;
            }
        }
        if (details != null) {
            this.adminLoginId = details.access_token.adminLoginId;
        }
        
    });

  }

  getSkills() {
    var obj = { PageNumber: 1, PageSize: 10 };
    this.masterService.getSkills(obj).subscribe(response => {
      if (response.isSuccess) {
        this.skills = response.list;
      }
    });
  }

  updateSkill() {
    var obj = { SkillId: parseInt(this.selectedSkillId), Skill: this.skillName, Rate: parseInt(this.rate) };
   
if (this.skillName == null || this.skillName == undefined || this.skillName.trim()=='') {
   this.toaster.errorToastr('Please enter skill name');
   return;
  }
 if(this.rate == null && this.rate == undefined){
    this.toaster.errorToastr('Please enter rate');
    return;
      }

      this.isSubmitting = true;
   
    this.masterService.updateSkill(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getSkills();
        this.selectedSkill = null
        this.selectedSkillId = 0;
        this.skillName =  '';
        this.rate = '';
        this.toaster.successToastr(response.responseMessage);
      }
      else{
        if(response.responseMessage!=null && response.responseMessage!=''){
          this.toaster.errorToastr(response.responseMessage);
        }
        }
        this.isSubmitting = false;
    }, (err) => {
            this.isSubmitting = false;
    });
   
  }

  edit(skill: any) {
    this.selectedSkill = skill;
    this.selectedSkillId = skill.skillId;
    this.skillName = skill.skill;
    this.rate = skill.rate;
  }

  deleteSkill(skill: any) {
    this.masterService.deleteSkill(skill).subscribe(response => {
      if (response.isSuccess) {
        this.getSkills();
        this.selectedSkill = null
        this.selectedSkillId = 0;
        this.toaster.successToastr("Skill deleted successfully")
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

    }

    keyDownHandler(event) {
        if (event.target.value.length > 0) {
            return true;
        } else {
            if (event.keyCode === 32) {
                return false;
            }
        }
    }

    onkeyDown(evt) {
        if (evt.target.value.length == 20) {
            return false;
        }
        return true;
    }

  ngOnInit() {
    this.getSkills()
  }

}

