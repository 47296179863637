import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';




@Component({
  selector: 'volunteers-detail',
  templateUrl: './volunteers-detail.component.html',
})

export class VolunteersDetailComponent implements OnInit {


    userLoginId: number;
    detailForm: FormGroup;
    //DETAIL 
    volunteerDetail: any;
    volunteerEvent: any;
    volunteerTask: any;
    //TOTAL COUNTS
    totalVolunteerEvent:number;
    totalVolunteerCampaigns:number;
    totalVolunteertasks:number;
    volunteerCampaign: any[] = [];;
    adminProfileUrl: string;
    bannerImageUrl: string;
    //EVENT VARIABLES
    eventpageNumber: number = 1;
    eventpageSize: number = 500;
    eventstartFrom: number = 1;
    eventcount: number;
    eventendOn: number = this.eventpageSize;
    eventlastPageClass: string;
    eventfirstPageClass: string = "disabled";
    eventpreviousPageClass: string = "disabled";
    eventnextPageClass: string;
    eventnumberOfPages: number;

  // CAMPAIGN VARIABLES
  campaignpageNumber: number = 1;
  campaignpageSize: number = 500;
  campaignstartFrom: number = 1;
  campaigncount: number;
  campaignendOn: number = this.eventpageSize;
  campaignlastPageClass: string;
  campaignfirstPageClass: string = "disabled";
  campaignpreviousPageClass: string = "disabled";
  campaignnextPageClass: string;
  campaignnumberOfPages: number;
    safeSrc: SafeResourceUrl;
    popUpHeading: string;

  
  
    constructor(private authentication: AuthenticationService, private _service: AdminVolunteerService, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
      var details = this.authentication.getToken();
      if(details == null){
        this.authentication.logout(); return;
      } else {
        var login = details.access_token;
        if(login.role==2){
          this.authentication.logout(); return;
        }
      }
      this.userLoginId = details.access_token.adminLoginId;

      router.events.subscribe((response) => {
        this.userLoginId = route.snapshot.params['id'];
      });

  }

    personalData() {
      document.getElementById("user_events").className = "tab-pane";
      document.getElementById("personal").className = "tab-pane active";
      document.getElementById("tasks").className = "tab-pane";
    }

// GETTING PERSONAL INFORMATION
    getDetail() {
      var obj = { UserLoginId: this.userLoginId};
      this._service.getVolunteerDetail(obj).subscribe(response => {
       
        if (response.isSuccess) {
          this.volunteerDetail = response.data;
            this.adminProfileUrl = response.data.profilePicture;
            this.bannerImageUrl = response.data.bannerImageUrl;
          this.totalVolunteerEvent = response.data.totalEvents;
          this.totalVolunteerCampaigns = response.data.totalCampaigns;
          this.totalVolunteertasks = parseInt(response.data.totalEvents) + parseInt(response.data.totalCampaigns)
         // this.totalVolunteertasks = response.data.totalTasks;
          if (response.isSuccess) {
            if (this.eventcount == 0) {
              this.eventstartFrom = 0;
            }
            else {
              this.eventstartFrom = ((this.eventpageNumber - 1) * this.eventpageSize) + 1;
            }
            if (this.eventcount <= this.eventpageSize) {
              this.eventendOn = this.eventcount;
              this.eventnextPageClass = "disabled";
              this.eventlastPageClass = "disabled";
            }
            else {
              this.eventendOn = this.eventpageNumber * this.eventpageSize;
            }
            var pages = this.eventcount / this.eventpageSize;
            this.eventnumberOfPages = 0;
            for (var i = 0; i < pages; i++) {
              this.eventnumberOfPages = i + 1;
            }
          }

        }
      });
    }


    // CALLING EVENT DETAIL API 

    getEventDetail() {
      document.getElementById("user_events").className = "tab-pane active";
      document.getElementById("personal").className = "tab-pane";
      document.getElementById("tasks").className = "tab-pane";
      var obj = { UserLoginId:this.userLoginId ,PageNumber: this.eventpageNumber, PageSize: this.eventpageSize};
      this._service.getVolunteerEventDetail(obj).subscribe(response => {

          if (response.isSuccess) {
            this.volunteerEvent = response.list;
            this.eventcount = response.count;
            if (this.eventcount == 0) {
              this.eventstartFrom = 0;
            }
            else {
              this.eventstartFrom = ((this.eventpageNumber - 1) * this.eventpageSize) + 1;
            }
            if (this.eventcount <= this.eventpageSize) {
              this.eventendOn = this.eventcount;
              this.eventnextPageClass = "disabled";
              this.eventlastPageClass = "disabled";
            }
            else {
              this.eventendOn = this.eventpageNumber * this.eventpageSize;
            }
            var pages = this.eventcount / this.eventpageSize;
            this.eventnumberOfPages = 0;
            for (var i = 0; i < pages; i++) {
              this.eventnumberOfPages = i + 1;
            }
          }

        
      });
    }
 

    eventFirstPage() {
      if (this.eventpageNumber > 1) {
        this.eventfirstPageClass = "disabled";
        this.eventpreviousPageClass = "disabled";
        this.eventnextPageClass = "";
        this.eventlastPageClass = "";
        this.eventpageNumber = 1;
        this.getEventDetail();
      }
    }
  
    eventLastPage() {
      if (this.eventpageNumber < this.eventnumberOfPages) {
        this.eventfirstPageClass = "";
        this.eventpreviousPageClass = "";
        this.eventnextPageClass = "disabled";
        this.eventlastPageClass = "disabled";
        this.eventpageNumber = this.eventnumberOfPages;
        this.getEventDetail();
      }
    }
  
    eventPreviousPage() {
      if (this.eventpageNumber > 1) {
        this.eventpageNumber = this.eventpageNumber - 1;
        if (this.eventpageNumber == 1) {
          this.eventfirstPageClass = "disabled";
          this.eventpreviousPageClass = "disabled";
          this.eventnextPageClass = "";
          this.eventlastPageClass = "";
        }
        this.getEventDetail();
      }
    }
  
    eventNextPage() {
      if (this.eventpageNumber < this.eventnumberOfPages) {
        this.eventpageNumber = this.eventpageNumber + 1;
        if (this.eventpageNumber == this.eventnumberOfPages) {
          this.eventfirstPageClass = "";
          this.eventpreviousPageClass = "";
          this.eventnextPageClass = "disabled";
          this.eventlastPageClass = "disabled";
        }
        this.getEventDetail();
      }
    }


    // changePageSize(pageSize: number) {
    //   this.eventpageSize = pageSize;
    //   this.eventpageNumber = 1;
    //   this.eventfirstPageClass = "disabled";
    //   this.eventpreviousPageClass = "disabled";
    //   this.eventnextPageClass = "";
    //   this.eventlastPageClass = "";
    //   this.getEventDetail();
    // }
  

    // CAMPAIGN API CALLING 


    getCampaignDetail() {
      document.getElementById("user_events").className = "tab-pane";
      document.getElementById("personal").className = "tab-pane";
      document.getElementById("tasks").className = "tab-pane active";
      var obj = { UserLoginId:this.userLoginId ,PageNumber: this.eventpageNumber, PageSize: this.eventpageSize};
      this._service.getVolunteerCampaignDetail(obj).subscribe(response => {
          if (response.isSuccess) {
            this.volunteerCampaign = response.list;
            this.volunteerTask = response.list.tasks;
            this.campaigncount = response.count;
              if (this.campaigncount == 0) {
                  this.campaignstartFrom = 0;
            }
              else {
                  this.campaignstartFrom = ((this.campaignpageNumber - 1) * this.campaignpageSize) + 1;
              }
              if (this.campaigncount <= this.campaignpageSize) {
                  this.campaignendOn = this.campaigncount;
                  this.campaignnextPageClass = "disabled";
                  this.campaignlastPageClass = "disabled";
            }
              else {
                  this.campaignendOn = this.campaignpageNumber * this.campaignpageSize;
            }
              var pages = this.campaigncount / this.campaignpageSize;
              this.campaignnumberOfPages = 0;
            for (var i = 0; i < pages; i++) {
                this.campaignnumberOfPages = i + 1;
            }
          }

        
      });
    }

    CampaignFirstPage() {
      if (this.campaignpageNumber > 1) {
        this.campaignfirstPageClass = "disabled";
        this.campaignpreviousPageClass = "disabled";
        this.campaignnextPageClass = "";
        this.campaignlastPageClass = "";
        this.campaignpageNumber = 1;
        this.getCampaignDetail();
      }
    }
  
    campaignLastPage() {
      if (this.campaignpageNumber < this.campaignnumberOfPages) {
        this.campaignfirstPageClass = "";
        this.campaignpreviousPageClass = "";
        this.campaignnextPageClass = "disabled";
        this.campaignlastPageClass = "disabled";
        this.campaignpageNumber = this.campaignnumberOfPages;
        this.getCampaignDetail();
      }
    }
  
    campaignPreviousPage() {
      if (this.campaignpageNumber > 1) {
        this.campaignpageNumber = this.campaignpageNumber - 1;
        if (this.campaignpageNumber == 1) {
          this.campaignfirstPageClass = "disabled";
          this.campaignpreviousPageClass = "disabled";
          this.campaignnextPageClass = "";
          this.campaignlastPageClass = "";
        }
        this.getCampaignDetail();
      }
    }
  
    campaignNextPage() {
      if (this.campaignpageNumber < this.campaignnumberOfPages) {
        this.campaignpageNumber = this.campaignpageNumber + 1;
        if (this.campaignpageNumber == this.campaignnumberOfPages) {
          this.campaignfirstPageClass = "";
          this.campaignpreviousPageClass = "";
          this.campaignnextPageClass = "disabled";
          this.campaignlastPageClass = "disabled";
        }
        this.getCampaignDetail();
      }
    }

    viewDocument(url:string){
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.popUpHeading = this.getFilenameFromURL(url);
    }

    getFilenameFromURL(url) {
      if(url.length > 0){
        var filename = url.substring(url.lastIndexOf('/') + 1);
        return filename;
      }
    }

    ngOnInit() {
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.getDetail();
      }


}
