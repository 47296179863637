import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { WallPostService } from '../../shared/services/wallpost.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import 'quill-emoji/dist/quill-emoji.js';
import { EditorconfigModule } from './../../shared/models/editorconfig/editorconfig.module';

@Component({
  selector: 'wallpost-create',
  templateUrl: './wallpost-create.component.html',
})

export class WallPostCreateComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  commentForm: FormGroup;
  validateForm: boolean = false;
  statusId: number = 2;
  campiagnId: number;
  imageFile: any;
  thumbnailImageFile:any;
  imageUrl: string;
  thumbnailImageUrl: string;
  isVideoSelected:boolean = false;
  postType:number = 1;
  showError:boolean = false;
  showImageDeleteButton: boolean;
  showThumbnailImageDeleteButton: boolean;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
   
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
   
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
   
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
   
      ['clean'],                                         // remove formatting button
      ['emoji'],
      ['link'],
                               // link and image, video
    ],
    "emoji-toolbar": true
  };

  editorConfiguration: { toolbar: { name: string; items: string[]; }[]; };
  editorUrl: string;

  constructor(private authentication: AuthenticationService, private _service: WallPostService, private router: Router,private route: ActivatedRoute,private toaster:ToastrManager,private editorconfig:EditorconfigModule ) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.editorConfiguration = this.editorconfig.configuration;
    this.editorUrl = this.editorconfig.editorUrl;
  }

  createForm() {
    this.commentForm = new FormGroup({
      description: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      videoUrl: new FormControl('')
    });

  }

  deleteImage(){
    if(this.postType==1) {
      this.imageFile = null; this.imageUrl = null;
      this.showImageDeleteButton = false;
    }
    else if (this.postType==2){
      this.thumbnailImageFile = null; this.thumbnailImageUrl = null;
      this.showThumbnailImageDeleteButton = false;
    }
    else{

    }  
  }

  selectImage(event) {
      var file = event.target.files[0];

      if (file != null) {
          if (file.size > 2097152) {
              this.toaster.errorToastr("Upload image upto 2 mb only");
              return;
          }
          this.imageFile = file;

          var reader = new FileReader();
          reader.onload = (e: any) => {
              this.imageUrl = e.target.result;
          }
          reader.readAsDataURL(file);
          this.showImageDeleteButton = true;
      }
  
    event.target.value = '';

  }

    selectThumbnailImage(event) {

      var file = event.target.files[0];

      if (file != null) {
          if (file.size > 2097152) {
              this.toaster.errorToastr("Upload image upto 2 mb only");
              return;
          }
          this.thumbnailImageFile = file;
          var reader = new FileReader();
          reader.onload = (e: any) => {
              this.thumbnailImageUrl = e.target.result;
          }
          reader.readAsDataURL(file);
          this.showThumbnailImageDeleteButton = true;
      }
 
      event.target.value = null;
    }

keyDownHandler(event) {
  if (event.target.value.length > 0) {
    return true;
  } else {
    if (event.keyCode === 32) {
      return false;
    }
  }
}

onChangePostType(event){
   if(event.target.value == "1"){
     this.postType = 1;
     this.removeValidators();
     this.commentForm.get('videoUrl').setValue('');    
   }
   else if(event.target.value == "2"){
     this.postType = 2;
     this.setValidators();
   }
   else{

   }
}
 
  setValidators(){
      this.commentForm.get('videoUrl').setValidators([Validators.required,Validators.pattern(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/)]);
  }
  removeValidators(){
    var control =  this.commentForm.get('videoUrl');
      control.clearValidators();
      control.setErrors(null);
  }

  save() {
    this.validateForm = true;

    if(this.postType==1 && (this.imageUrl=='' || this.imageUrl==null )){
      this.toaster.errorToastr("Upload Image");
      return;
    }
    if(this.postType==2 && (this.thumbnailImageUrl=='' || this.thumbnailImageUrl==null)){
      this.toaster.errorToastr("Upload Thumbnail Image");
      return;
    }

    
    if(this.commentForm.valid)
      {
      var uploadedfile;
      if(this.postType==1){
         uploadedfile = this.imageFile;
      } else if (this.postType==2){
        uploadedfile = this.thumbnailImageFile;
      }

      var obj = { file:uploadedfile  , Description: this.commentForm.value.description ,VideoUrl:this.commentForm.value.videoUrl, CorporateId:this.corporateId,  CreatedBy: this.adminLoginId ,IsAdminPost :true};
      this._service.createPost(obj).subscribe(response => {
        if (response.isSuccess) {
          this.toaster.successToastr("Post created successfully");
          this.router.navigate(['/sa/newsFeed']);
        }
      },(err)=>{
          this.toaster.errorToastr("Some error occured");
      });
      }
  }
  

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  ngOnInit() {
     this.createForm();
  }
}
