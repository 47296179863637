
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class FeedbackService {

  constructor(private router: Router, private http: HttpClient) { }


  getAllEvents(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/GetAllEvents', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAllSuperAdminEvents(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/GetAllSuperAdminEvents', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getEventFeedback(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/GetUserReport', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAllFeedback(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  submitReverseFeedback(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/CreateAdminFeedback', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAdminFeedback(obj):Observable<any>{
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/GetAdminFeedback', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
