import { Component, OnInit } from '@angular/core';
import { AdminMasterService } from '../../../shared/services/master.services';
import { FormBuilder,FormGroup,FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';



@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

   adminLoginId:number;
corporateId:number;
   skills:any;
   skillName:string;
   skillRate:number;
   selectedSkillId:number;
   filteredSkills:any;
   searchTerm:string;
   isSearchEnabled:boolean = false;
   rateValid:boolean = false;
   skillRequired:boolean = false;
   rateRequired:boolean = false;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private authentication:AuthenticationService,
    private service:AdminMasterService,
    private toaster:ToastrManager
    ) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
      this.adminLoginId = details.access_token.adminLoginId;
      this.corporateId = details.access_token.corporateId;
     }

    getSkills() {
        this.service.getSkillsByAdmin({ "AdminLoginId": this.adminLoginId,"CorporateId": this.corporateId })
       .subscribe(response => {
         
            if(response.isSuccess){
               this.skills = response.list;
               // reset controls
               this.skillName = '';
               this.skillRate = null;
               this.selectedSkillId = null;
               
            }
       })
     }

     numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  
    }

     updateSkill(){

       var valid = true;
       if(this.skillName==null || this.skillName.trim()==''){
         this.skillRequired = true;
         valid = false;
       }
       if(this.skillRate==null || this.skillRate <= 0){
         this.rateRequired = true;
         valid = false;
       }
       if(!valid){
          return;
       }

         var obj = { "AdminLoginId": this.adminLoginId, "CorporateId": this.corporateId,"SkillId":this.selectedSkillId,"Skill":this.skillName,"Rate":this.skillRate}

       this.service.addUpdateAdminSkill(obj).subscribe(response => {
         if(response.isSuccess){
            this.toaster.successToastr(response.message);
            this.skillRequired = this.rateRequired = false;
            this.getSkills();
         }
         else{
          if(response.responseMessage!=null && response.responseMessage!=''){
            this.toaster.errorToastr(response.responseMessage);
          }
          else {
           this.toaster.errorToastr("Some Error Occured");
         }
        }
       })
     }

  edit(selectedSkill) {
    this.skillName = selectedSkill.skill;
    this.skillRate = selectedSkill.rate;
    this.selectedSkillId = selectedSkill.skillId;
  }
  deleteSkill(selectedSkill) {
      this.service.updateSkillStatusByAdmin({ "AdminLoginId": this.adminLoginId, "CorporateId": this.corporateId, "SkillId": selectedSkill.skillId })
      .subscribe(response => {
        if (response.isSuccess) {
          this.toaster.successToastr('Skill Deleted Successfully');
          this.selectedSkillId = null;
          this.getSkills();
        }
        else {
       
            this.toaster.errorToastr("Something went wrong");
          
          
        }
      })
  }

  filterSkillsByName(){
    if(this.searchTerm!=null && this.searchTerm.trim()!='') {
        this.service.getSkillsByAdmin({ "AdminLoginId": this.adminLoginId, "CorporateId": this.corporateId,"SearchTerm":this.searchTerm})
      .subscribe(response => {
          if(response.isSuccess){
              this.skills = response.list;
             this.isSearchEnabled = true;
           }
           else{
             this.toaster.errorToastr("No Search Results");
           }
      })
    }    
  }
  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  clearSearchfilter(){
    this.getSkills();
    this.searchTerm='';
    this.isSearchEnabled = false;
  }

  ngOnInit() {
     this.getSkills();
  }

}
