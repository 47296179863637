import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SurveyService } from '../../shared/services/survey.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'survey-list',
  templateUrl: './surveyList.component.html',
})

export class SurveyListComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  surveys: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  titleIcon: string = "sorting";
  startDateIcon: string = "sorting";
  endDateIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;

  constructor(private _service: SurveyService, private authentication: AuthenticationService) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
  }



  getSurveys() {
    var obj = { AdminLoginId: this.adminLoginId, CorporateId: this.corporateId, IsPublic: true, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
    console.log(obj);
    this._service.getAllSurveys(obj).subscribe(response => {
      if (response.isSuccess) {
        this.surveys = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Title":
        if (this.titleIcon == "sorting") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.titleIcon == "sorting_asc") {
          this.titleIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.titleIcon == "sorting_desc") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.startDateIcon = "sorting";
        this.endDateIcon = "sorting";
        break;
      case "StartDate":
        this.titleIcon = "sorting";
        if (this.startDateIcon == "sorting") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.startDateIcon == "sorting_asc") {
          this.startDateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.startDateIcon == "sorting_desc") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.endDateIcon = "sorting";
        break;
      case "EndDate":
        this.titleIcon = "sorting";
        this.startDateIcon = "sorting";
        if (this.endDateIcon == "sorting") {
          this.endDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.endDateIcon == "sorting_asc") {
          this.endDateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.endDateIcon == "sorting_desc") {
          this.endDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getSurveys();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getSurveys();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getSurveys();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getSurveys();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getSurveys();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getSurveys();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getSurveys();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getSurveys();
    }
  }

  performAction(survey: any, statusId: number, status: string) {
    var obj = { SurveyId: survey.surveyId, StatusId: statusId };
    this._service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        survey.statusId = statusId;
        survey.status = status;
      }
    });
  }

  deleteSurvey(survey: any, index: number) {
    var obj = { SurveyId: survey.surveyId };
    this._service.deleteSurvey(obj).subscribe(response => {
      this.pageNumber = 1;
      this.getSurveys();
    });
  }

  ngOnInit() {
    this.getSurveys();
  }
}
