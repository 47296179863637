import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FormControl, FormGroup, Validators, RequiredValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LearningZoneService } from '../../shared/services/learningzone.service';
import { ToastrManager } from 'ng6-toastr-notifications';



@Component({
  selector: 'learningzone-create',
  templateUrl: './learningzone-create.component.html',
})

export class LearningZoneCreateComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  LearningPostForm: FormGroup;
  validateForm: boolean = false;
  imageFile: any;
  documentFile: any;
  imageUrl: string;
  documentUrl:string;
  mediaType: number = 1;
  isAdminPost =  true;
  errorMessage: string;
  fileName : string;
  curriculumId: any;
  campaignId: any;
  learningZoneId: any;
  details: any;

  constructor(private authentication: AuthenticationService, private learningZone: LearningZoneService,  private router: Router, private route: ActivatedRoute,private location: Location,private toastr: ToastrManager) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
      this.campaignId = this.route.snapshot.params['id'];
      this.curriculumId = this.route.snapshot.params["curriculumId"];
      this.learningZoneId = this.route.snapshot.params["learningZoneId"];
  }

  createForm() {

    this.LearningPostForm = new FormGroup({
      title: new FormControl('',[Validators.required, this.whiteSpaceValidator]),
      description: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      readerText:new FormControl('',[Validators.required,this.whiteSpaceValidator]),
      youtubeUrl:new FormControl('',),
      mediaType:new FormControl(this.mediaType),
      learningZoneId:new FormControl(this.learningZoneId)
    });
  }

  getDetails(){
     this.learningZone.getLearningZoneDetail({"LearningZoneId":this.learningZoneId})
     .subscribe(res => {
        if(res.isSuccess){

           this.details = res.data;
           this.isAdminPost = this.details.isAdminPost;
           this.imageUrl = this.details.imageUrl;
           this.documentUrl = this.details.documentUrl;
           this.mediaType = this.details.mediaType;

           this.LearningPostForm.setValue(
             {
              title:this.details.title,
              description:this.details.description,
              readerText:this.details.readerText,
              youtubeUrl:this.details.youtubeVideoUrl,
              mediaType:this.details.mediaType,
              learningZoneId:this.learningZoneId
             }
           );
      
           if(this.documentUrl!=null && this.documentUrl!='')
           {
             this.fileName = this.getFilenameFromURL(this.documentUrl);
             this.documentFile = 'hasFile';
           }
           if(this.imageUrl!=null && this.imageUrl!='')
           {
             this.imageFile = 'hasFile';
           }
        }
     },(err)=>{
       this.toastr.errorToastr("Some error occured");
     });
  }

  selectValueType(type: number) {
    
    this.imageFile = null;
    this.documentFile = null;
    this.documentUrl = '';
    this.imageUrl = '';
    this.LearningPostForm.get("youtubeUrl").setValue('');
    this.LearningPostForm.get("youtubeUrl").clearValidators();
    this.LearningPostForm.get("youtubeUrl").setErrors(null);
    this.errorMessage = '';

    if (type == 1) {
      this.mediaType = 1;    
    }
    else if(type==2){
      this.mediaType = 2
        this.LearningPostForm.get("youtubeUrl").setValidators([Validators.required, Validators.pattern(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/)]);  
    }
    else {
      this.mediaType = 3;
    }
  }
 selectImage(event) {
     var file = event.target.files[0];
     if (file != null) {
         if (file.size > 2097152) {
             this.toastr.errorToastr("Upload image upto 2 mb only");
             return;
         }
         this.imageFile = file;
         var reader = new FileReader();
         reader.onload = (e: any) => {
             this.imageUrl = e.target.result;
         }
         reader.readAsDataURL(file);
     }
     event.target.value = '';
  }

   selectDocument(event) {
       var file = event.target.files[0];

       if (file != null) {
           if (file.size > 20971520) {
               this.toastr.errorToastr("Upload file upto 20 mb only");
               return;
           }
           this.documentFile = file;
           this.fileName = file.name;
           var reader = new FileReader();
           reader.onload = (e: any) => {
               this.documentUrl = e.target.result;
           }
           reader.readAsDataURL(file);
       }
       event.target.value = '';
  }

  createLearningZonePost(){
    var formData = new FormData();
    if(this.learningZoneId!=null){
      formData.append('LearningZoneId', this.learningZoneId.toString());
    }
   
      formData.append('CurriculumId', this.curriculumId.toString());
    formData.append('CampaignId',this.campaignId.toString());
    formData.append('Description', this.LearningPostForm.value.description);
    formData.append('Title', this.LearningPostForm.value.title);
    formData.append('MediaType', this.mediaType.toString());
    formData.append('CreatedBy', this.adminLoginId.toString());
    formData.append('IsAdminPost', (this.isAdminPost==true ? 1 : 0).toString());
    formData.append("ReaderText",this.LearningPostForm.value.readerText);
   
    if(this.mediaType==1){
      formData.append("UploadImage",this.imageFile);
    }
    else if(this.mediaType==2) {
      formData.append("YoutubeVideoUrl",this.LearningPostForm.value.youtubeUrl);

      if(this.imageFile!=null || this.imageFile!= undefined){
        formData.append("UploadImage",this.imageFile);
      }
    }
    else {
      formData.append("DocumentFile",this.documentFile);
      if(this.imageFile!=null || this.imageFile!= undefined){
        formData.append("UploadImage",this.imageFile);
      }
    }

    this.learningZone.createPost(formData).subscribe(response => {
        if (response.isSuccess) {
            this.navigateBack();
       }
    })
  }

  save() { 
    var isValid = true;
    this.validateForm = true;
    this .errorMessage = '';

    if(this.mediaType == 1 && (this.imageFile==null||this.imageFile==undefined)){
        this.toastr.errorToastr("Please Select Image File"); isValid = false;
    }
    if(this.mediaType == 2 && (this.imageFile==null||this.imageFile==undefined)){      
        this.toastr.errorToastr("Please Select Thumbnail Image");
    }
    if(this.mediaType == 3){
       if(this.documentFile == null || this.documentFile == undefined){
        this.toastr.errorToastr("Please Select Document File");
        isValid = false;
       }
       if(this.imageFile==null||this.imageFile==undefined){
        this.toastr.errorToastr("Please Select Document Image");
        isValid = false;
      }
    
    }

    if(isValid && this.LearningPostForm.valid) {
      this.createLearningZonePost();
    }
   
  }

  getFilenameFromURL(url) {
    var filename = url.substring(url.lastIndexOf('/') + 1);
    return filename;
}

    navigateBack() {
        this.location.back();
    }
whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
{
    var val = control.value;

    if (val.trim() == "") {
        return { 'hasWhiteSpace': true }
    }
    else {
        return null;
    }
}
   ngOnInit() {
     this.createForm();

     if(this.learningZoneId != null){
        this.getDetails();
     }
  }
}
