import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup,FormBuilder, Validators } from '@angular/forms';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
})
export class CurriculumComponent implements OnInit {
  form: any;
  adminLoginId: any;
  corporateId: any;
  campaignId: any;
  validate: boolean;
  isSubmitEnabled: boolean = true;

  startDateOptions: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
  };
  endDateOptions: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
  };
  
 

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private auth:AuthenticationService,
    private service:AdminCampaignService,
    private fb:FormBuilder,
    private toaster:ToastrManager
  ) {
    var details = this.auth.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    this.campaignId = this.route.snapshot.params['id'];
   }

  save(){
    this.validate = true;
    if(this.form.valid){
      this.isSubmitEnabled = false;
      var formValue = this.form.value;
      var id = { "campaignId":this.campaignId }
      var request = { ...id,...formValue }
      console.log(request);
      this.service.createCurriculum(request)
      .subscribe(res => {
        if(res.isSuccess){
          this.toaster.successToastr("Curriculum created successfully");
          this.router.navigateByUrl(this.url);
        }
      },(err)=>{
           this.toaster.errorToastr("Some error occured");
           this.isSubmitEnabled = true;
      })
    }
  }

  onBackClick(){
    this.router.navigateByUrl(this.url);
  }
  get url(){
 
    return "/sa/campaigns/curriculum/"+this.campaignId;
  }

  setEndDateOptions(event){
    this.form.get("EndDate").setValue(event);
    this.endDateOptions = {
      minDate: new Date((new Date().setDate((new Date(event).getDate() - 1))))
    }
  }


  
  ngOnInit() {
    this.form = this.fb.group({
      Title:['',Validators.required],
      StartDate:new Date(),
      EndDate:new Date(),
    })
  }

 
}
