
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class QuizService {

  constructor(private router: Router, private http: HttpClient) { }

  callCreateQuizService(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/Create', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  callGetQuizService(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetQuiz', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callGetQuizListService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/Get', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callActivateQuizService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/Activate', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callDeactivateQuizService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/Deactivate', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callDeleteQuizService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/Delete', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callQuizUsersService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetQuizUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getQuizDetail(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetQuizDetail',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }
  
  callQuestionAddService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/CreateQuestion', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callGetQuestionListService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetQuizQuestions', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callGetQuestionByIdService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetQuizQuestionById', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  callPerformActionOnQuestionService(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/PerformActionOnQuestion', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
