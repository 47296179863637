import { Component, OnInit } from '@angular/core';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as _moment from 'moment';

@Component({
  selector: 'app-campaign-based-on-kind',
  templateUrl: './campaign-based-on-kind.component.html',
  styleUrls: ['./campaign-based-on-kind.component.scss']
})
export class CampaignBasedOnKindComponent implements OnInit {
  listOfKinds: any;
  kindId: number = 1;
  listOfCampaigns: any;
  Users: any;
  count: any;
  startFrom: number = 1;
  pageNumber: number = 1;
  pageSize: number = 10;
  firstPageClass: string;
  previousPageClass: string;
  endOn: number = this.pageSize;
  nextPageClass: string;
  lastPageClass: string;
  numberOfPages: number;
  searchTerm: string;

  

  constructor(private _service: AdminCampaignService, private authentication: AuthenticationService, private route: ActivatedRoute, private router: Router, private toaster: ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    }
  }

  getKinds(){
    this._service.getKinds().subscribe(response => {
       if(response.isSuccess){
          this.listOfKinds = response.list;
       }
    });
  }

  getCampaignsOnKinds(){
    this._service.getCampaignBasedOnKinds({"TypeId":this.kindId}).subscribe(response => {
       if(response.isSuccess){
          this.listOfCampaigns = response.opportunities;
          this.count = response.totalCount;
          if (this.count == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
  
          if(this.pageNumber > 1){
            this.firstPageClass = "";
            this.previousPageClass = "";
          }
          
          if (this.count <= this.pageSize) {
            this.endOn = this.count;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
            this.endOn = this.count;
        }
       
       }
    });
  }

  setKindFilter(i){
    this.kindId = i; this.getCampaignsOnKinds();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getCampaignsOnKinds();
    }
  }
  
  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getCampaignsOnKinds();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getCampaignsOnKinds();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getCampaignsOnKinds();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaignsOnKinds();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaignsOnKinds();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getCampaignsOnKinds();
    }
  }

  getVolunteers(volunteers:any){
    this.Users = volunteers;
    var ele = document.getElementById("model-user") as HTMLElement;
    ele.click();
  }

  sortColumns(sortType:string){

  }

  ngOnInit() {
    this.getKinds();
    this.getCampaignsOnKinds()
  }

}
