import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { NgDatepickerModule, DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/fr';
import { Location } from '@angular/common';

@Component({
  selector: 'survey-create',
  templateUrl: './survey-create.component.html',
})

export class SurveyCreateComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  surveyForm: FormGroup;
  validateForm: boolean = false;
  questionTypes: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  statusId: number = 2;
  countries: any[] = [];
  surveyId: number;
  file: any;
  eventId: number;
  optionError: string;
  quesType: any;
  startDate: any;
  options: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))), // Minimal selectable date
  };

  constructor(private _service: SurveyService, private authentication: AuthenticationService, private masterService: AdminMasterService, private router: Router, private route: ActivatedRoute,private location:Location) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    router.events.subscribe((response) => {
      this.surveyId = route.snapshot.params['id'];
      this.eventId = route.snapshot.params['eventId'];
    });
    this.createForm();
  }

  createForm() {
    this.surveyForm = new FormGroup({
      surveyId: new FormControl(''),
      eventId: new FormControl(''),
      statusId: new FormControl(2),
      title: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      description: new FormControl('', [Validators.required,this.whiteSpaceValidator]),
      startDate: new FormControl(new Date(), Validators.required),
      endDate: new FormControl(new Date(), Validators.required),
      questions: new FormArray([]),
      createdOn: new FormControl(new Date())
    });
  }


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          endDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  get questions(): FormArray {
    return this.surveyForm.get("questions") as FormArray;
  }

  addQuestion(data?: any) {
    this.questions.push(new FormGroup({
      questionId: new FormControl(0),
      question: new FormControl('', Validators.required),
      helpText: new FormControl(''),
      questionTypeId: new FormControl('', Validators.required),
      options: new FormArray([]),
      isDeleted: new FormControl(false),
      createdOn: new FormControl('')
    }));
  }

  removeQuestion(index: number, question: FormGroup) {
    if (question.controls.questionId.value > 0) {
      console.log(this.questions);
      this.questions.controls[index].value.isDeleted = true;//(index)
    }
    else {
      this.questions.removeAt(index)
    }
    console.log("Questions = ", this.questions);
  }


  addQuestionOptions(question: FormGroup) {
    question.value.options.push({
      optionId: 0,
      questionOption: '',
      isDeleted: false,
      createdOn: ''
    });
  }

  removeQuestionOption(index: number, question: FormGroup, option: any) {
    if (option.optionId > 0) {
      question.value.options[index].isDeleted = true;
    }
    else {
      question.value.options.splice(index, 1)
    }
  }

  saveAsDraft() {
    this.statusId = 1;
  }

 
  save() {
    this.validateForm = true;
      if(this.surveyForm.value.questions == null || this.surveyForm.value.questions == ""){
     alert("Add one Question Atleast.");
   } else{

     if (this.surveyForm.valid) {
      let isValid = this.validateOptions();
      if (isValid) {
        this.surveyForm.value.statusId = this.statusId;
        this.surveyForm.value.eventId = this.eventId;

        // let startDate = new Date(this.surveyForm.value.startDate).toUTCString();
        // let startDateLine = new Date(startDate);
        // startDateLine.setHours(startDateLine.getHours() + 5);
        // startDateLine.setMinutes(startDateLine.getHours() + 30);
        // this.surveyForm.value.startDate = startDateLine;

        this.surveyForm.value.startDate = this.startDate;
   
        let endDate = new Date(this.surveyForm.value.endDate).toUTCString();
        let endDateLine = new Date(endDate);
        endDateLine.setHours(endDateLine.getHours() + 5);
        endDateLine.setMinutes(endDateLine.getHours() + 30);
        this.surveyForm.value.endDate = endDateLine;

        if (this.surveyId > 0) {
          this._service.updateSurvey(this.surveyForm.value).subscribe(response => {
            if (response.isSuccess) {
              this.navigateBack();
            }
          });
        }
        else {
          this._service.createSurvey(this.surveyForm.value).subscribe(response => {
            if (response.isSuccess) {
              this.navigateBack();
            }
          });
        }
      }
    }
   }
   
  }



  validateOptions(): boolean {
    let isValid = true;
    for (var i = 0; i < this.surveyForm.value.questions.length; i++) {
      for (var j = 0; j < this.surveyForm.value.questions[i].options.length; j++) {
        if (this.surveyForm.value.questions[i].options[j].questionOption == '') {
          this.optionError = "Option cannot be blank";
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }

  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }

  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  getSurveyDetails() {
    var obj = { SurveyId: this.surveyId, EventId: this.eventId };
    this._service.getSurveyDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        if (detail.questions != null) {
          for (var i = 0; i < detail.questions.length; i++) {
            this.questions.push(new FormGroup({
              questionId: new FormControl(detail.questions[i].questionId),
              question: new FormControl(detail.questions[i].question),
              helpText: new FormControl(detail.questions[i].helpText),
              questionTypeId: new FormControl(detail.questions[i].questionTypeId),
              options: new FormArray([]),
              isDeleted: new FormControl(false),
              createdOn: new FormControl(detail.questions[i].createdOn)
            }));
            console.log(this.questions);
            if (detail.questions[i].options != null && detail.questions[i].options.length > 0) {
              for (var j = 0; j < detail.questions[i].options.length; j++) {
                this.questions.value[i].options.push({
                  optionId: detail.questions[i].options[j].optionId,
                  questionOption: detail.questions[i].options[j].questionOption,
                  isDeleted: false,
                  createdOn: detail.questions[i].options[j].createdOn,
                });
              }
            }
          }
        }
        this.surveyForm.setValue({
          surveyId: detail.surveyId,
          eventId: detail.eventId,
          statusId: detail.statusId,
          title: detail.title,
          description: detail.description,
          startDate: this.startDate,
          endDate: detail.endDate,
          questions: this.questions.value,
          createdOn: detail.createdOn
        });
      }
    });
  }

  whiteSpaceValidator(control: FormControl): { [key: string]: any } | null
  {
      var val = control.value;

      if (val.trim() == "") {
          return { 'hasWhiteSpace': true }
      }
      else {
          return null;
      }
  }

  navigateBack(){
    this.location.back();
  }

  getEventEndDate(){
    var request = {"EventId":this.eventId}
    this._service.getEventEndDate(request).subscribe(response => {
       if(response.isSuccess){
          this.startDate = response.endDate
          this.surveyForm.get("endDate").setValue(new Date(new Date().setFullYear(new Date(response.endDate).getFullYear(),new Date(response.endDate).getMonth(),new Date(response.endDate).getDate())))
          this.options = {
            minDate: new Date(new Date().setDate((new Date(response.endDate).getDate()-1))),                 
          }       
       }
    })
  }


  ngOnInit() {

    this.getEventEndDate();
    
    var obj = { pageNumber: 1, PageSize: 500 };
    this._service.getQuestionTypes(obj).subscribe(response => {
      this.questionTypes = response.data;
    });

    if (this.surveyId > 0) {
      this.getSurveyDetails();
    }
  }
}
