import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';



@Component({
  selector: 'contries-list',
  templateUrl: './countries-list.component.html',
})

export class CountriesListComponent implements OnInit {

  countries: any[] = [];
  countriesForCities: any[] = [];
  states: any[] = [];
  StateForCities: any[] = [];
  cities: any[] = [];
  countrySelectedForState: boolean = false;
  countryStateSelectedForcites: boolean = false;
  //Country Section
  newCountryName = '';
  panicNumber: string;
  countryValidation: boolean = false;
  panicNumberValidation: boolean = false;
  adminLoginId: number;
  editCountryId: any;
  //State Section
  newStateName = '';
  countryIdforState: any;
  editStateId: any;
  alertforStateSection: string;

  // CitySection
  newCityName = '';
  stateIdforCity: any;
  editCityId: any;
  alertforCitySection: string;







  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute) {
    router.events.subscribe((response) => {
        var details = this._service.getToken();
      
        if (details == null) {
            this._service.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this._service.logout(); return;
            }
        }
        if (details != null) {
            this.adminLoginId = details.access_token.adminLoginId;
        }
      
    });

  }



  getStates(countryId: any) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: parseInt(countryId) };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
      this.countryIdforState = parseInt(countryId);

    });
  }

  getStatesForCities(countryId: any) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: parseInt(countryId) };
    this.masterService.getStates(obj).subscribe(response => {
      this.StateForCities = response.data;
    });
  }
  getCities(stateId: any) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: parseInt(stateId) };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
      this.stateIdforCity = parseInt(stateId);
    });
  }

  getCountries() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
      this.countriesForCities = response.data;
    });
  }


  addCountry() {

    if (this.newCountryName.trim() == '') {
      this.countryValidation = true;
    }
    // else if (this.panicNumber == undefined || this.panicNumber == "") {
    //   this.panicNumberValidation = true;
    // }
    else {
      this.countryValidation = false;
      var obj = { CountryName: this.newCountryName, AdminLoginId: this.adminLoginId, CountryId: null, PanicNumber: this.panicNumber };
      this.masterService.addCountry(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCountryName = null;
          this.panicNumber = null;
          this.countryValidation = false;
          this.panicNumberValidation = false;
          this.getCountries();
        }
      });

    }
  }
  // CounTry Section
  editCountry() {
    if (this.newCountryName.trim() == '') {
      this.countryValidation = true;
    }
    // else if (this.panicNumber == undefined || this.panicNumber == "") {
    //   this.panicNumberValidation = true;
    // }
    else {
      this.countryValidation = false;
      var obj = { CountryName: this.newCountryName, AdminLoginId: this.adminLoginId, CountryId: parseInt(this.editCountryId), PanicNumber: this.panicNumber };
      console.log(obj);
      this.masterService.addCountry(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCountryName = null;
          this.panicNumber = null;
          this.countryValidation = false;
          this.panicNumberValidation = false;
          this.editCountryId = 0;
          this.getCountries();
        }
      });

    }
  }

  editCountryIconTapped(country: any, countryId: any) {
    this.countryValidation = false;
    this.panicNumberValidation = false;
    this.newCountryName = country.country;
    this.panicNumber = country.panicNumber;
    this.editCountryId = parseInt(countryId);

  }
  // State Section 
  editStateIconTapped(stateName: string, stateId: any) {
    this.newStateName = stateName;
    this.editStateId = parseInt(stateId);
  }

  addState() {
    if (this.countryIdforState == undefined) {
      this.countrySelectedForState = true;
      this.alertforStateSection = "Choose Country First!"
    }
    else if (this.newStateName.trim() == '') {
      this.countrySelectedForState = true;
      this.alertforStateSection = " Please Enter State Name First"
    }
    else {
      this.countrySelectedForState = false;
      var obj = { StateName: this.newStateName, AdminLoginId: this.adminLoginId, StateId: null, CountryId: parseInt(this.countryIdforState) };
      this.masterService.addState(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newStateName = null;
          this.getStates(parseInt(this.countryIdforState));
        }
      });
    }
  }

  editState() {
    if (this.newStateName.trim() == '') {
      this.countrySelectedForState = true;
    }

    else {
      this.countrySelectedForState = false;
      var obj = { StateName: this.newStateName, AdminLoginId: this.adminLoginId, StateId: parseInt(this.editStateId), CountryId: parseInt(this.countryIdforState) };
      console.log(obj);
      this.masterService.addState(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newStateName = null;
          this.editStateId = 0;
          this.getStates(parseInt(this.countryIdforState));
        }
      });
    }
  }
  // City Section 

  addCity() {

    if (this.stateIdforCity == undefined) {
      this.countryStateSelectedForcites = true;
      this.alertforCitySection = "Choose Country and State First";
    }
    else if (this.newCityName.trim() == '') {
      this.alertforCitySection = " Please Enter City Name First";

      this.countryStateSelectedForcites = true;
    }
    else {
      this.countryStateSelectedForcites = false;
      var obj = { CityName: this.newCityName, AdminLoginId: this.adminLoginId, StateId: parseInt(this.stateIdforCity), CityId: null };
      this.masterService.addCity(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCityName = null;
          this.getCities(parseInt(this.stateIdforCity));

        }
      });

    }
  }
  editCityIconTapped(cityName: string, cityId: any) {
    this.newCityName = cityName;
    this.editCityId = parseInt(cityId);
  }

  editCity() {
    if (this.newCityName.trim() == '') {
      this.countryStateSelectedForcites = true;
    }

    else {
      this.countryStateSelectedForcites = false;
      var obj = { CityName: this.newCityName, AdminLoginId: this.adminLoginId, StateId: parseInt(this.stateIdforCity), CityId: parseInt(this.editCityId) };
     
      this.masterService.addCity(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCityName = null;
          this.editCityId = 0;
          this.getCities(parseInt(this.stateIdforCity));
        }
      });
    }
  }
  // Delete Functionality

  deleteCity(cityId: any) {

    var obj = { StateId: parseInt(this.stateIdforCity), CityId: parseInt(cityId) };
    this.masterService.deleteCity(obj).subscribe(response => {
    
      if (response.isSuccess == true) {
        this.getCities(parseInt(this.stateIdforCity));
        this.newCityName = null;
        this.editCityId = 0;
      }
    });
  }

  deleteState(stateId: any) {
    var obj = { StateId: parseInt(stateId), CountryId: parseInt(this.countryIdforState) };

    this.masterService.deleteState(obj).subscribe(response => {
      if (response.isSuccess == true) {
        this.getStates(parseInt(this.countryIdforState));
        this.getStatesForCities(parseInt(this.countryIdforState));
        this.cities = null;
        this.newStateName = null;
        this.editStateId = 0;
      }
    });
  }

  deleteCountry(countryId: any) {
    var obj = { CountryId: parseInt(countryId), AdminLoginId: this.adminLoginId };
    console.log(obj);
    this.masterService.deleteCountry(obj).subscribe(response => {
      console.log(response);
      if (response.isSuccess == true) {
        this.getCountries();
        this.newCountryName = null;
        this.panicNumber = null;
        this.editCountryId = 0;
      }
    });
  }


  ngOnInit() {
    this.getCountries()
  }

}

