import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminCampaignService } from '../../shared/services/campaigns.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl,SafeHtml } from '@angular/platform-browser';
import { ReportService } from '../../shared/services/report.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'campaign-detail',
  templateUrl: './campaign-detail.component.html',
})

export class CampaignDetailComponent implements OnInit {
  adminLoginId: number;
  corporateId: number;
  campaignForm: FormGroup;
  validateForm: boolean = false;
  statusId: number = 2;
  campiagnId: number;
  details: any;
  pushmessage: string;
  pushmsgValidation: boolean = false;
  pushmsgSendSuccess: boolean = false;
  pushemail: string;
  pushemailValidation: boolean = false;
  pushemailSendSuccess: boolean = false;
  isShort: boolean = true;
  originalDesc: any;
  showReadMoreButton: boolean;
  htmlContent:SafeHtml;
  ngoDescription:SafeHtml;
  isSuperadminCampaigns:boolean = false;
  isPushNotification: boolean = true;
  isEmail: boolean = false;

  constructor(private _service: AdminCampaignService, private authentication: AuthenticationService, private router: Router, private route: ActivatedRoute,private reportService:ReportService,private sanitizer: DomSanitizer,private toaster:ToastrManager) {
    var details = this.authentication.getToken();
    if(details == null){
      this.authentication.logout(); return;
    } else {
      var login = details.access_token;
      if(login.role==1 ){
        this.authentication.logout(); return;
      }
    }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;

    router.events.subscribe((response) => {
      this.campiagnId = route.snapshot.params['id'];
    });
  }

  getCampaignDetail() {
    var obj = { CorporateId: this.corporateId, campaignId: this.campiagnId };
    this._service.getCampaignDetail(obj).subscribe(response => {
      if (response.isSuccess) {
      
          this.details = response.data;
          this.isSuperadminCampaigns = response.data.isSuperadminCampaigns;
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.details.description);
          if(this.details.ngos!=null && this.details.ngos.length > 0){          
            this.ngoDescription = this.sanitizer.bypassSecurityTrustHtml(this.details.ngos[0].description);
          }
         
          this.statusId = response.data.statusId;
        
      }
    });
  }

    managetapped()
    {
        var routeParams = this.route.snapshot.url;
        var route = "/admin/" + routeParams[0].path + "/" + routeParams[1].path + "/taskList/" + this.campiagnId;
        this.router.navigateByUrl(route);
    }


  sendPushNotification() {

    this.pushmsgValidation = false; this.pushemailValidation = false;

    if(this.isPushNotification){
      if (this.pushmessage == undefined || this.pushmessage == null || this.pushmessage.trim() == "" ) {
        this.pushmsgValidation = true;
      }
      else {
        this.pushmsgValidation = false;
        var obj = { CampaignId: this.campiagnId, Message: this.pushmessage };
     
        this._service.pushNotificationforCampaignsUser(obj).subscribe(response => {
          if (response.isSuccess) {
            this.toaster.successToastr("Notification Sent Successfully");
            this.pushmessage = '';
          }else {
            this.toaster.errorToastr("Notification Not Sent");
          }
        }, (err) => {
          this.toaster.errorToastr("Notification Not Sent");
        });
      }
    }
    else {
      if (this.pushemail == undefined || this.pushemail == null || this.pushemail.trim() == "" ) {
        this.pushemailValidation = true;
      }
      else {
        this.pushemailValidation = false;
        var reqobj = { CampaignId: this.campiagnId, Content: this.pushemail };
     
        this._service.mailNotificationforCampaignsUser(reqobj).subscribe(response => {
          if (response) {
            this.toaster.successToastr("Mail Sent Successfully");
            this.pushemail = '';
          }else {
            this.toaster.errorToastr("Mail Not Sent");
          }
        }, (err) => {
          this.toaster.errorToastr("Mail Not Sent");
        });
      }
    }
   
    }

    navigateBack() {
        var routeParams = this.route.snapshot.url;
        var route = "/admin/" + routeParams[0].path + "/" + routeParams[1].path
        this.router.navigateByUrl(route);
    }

    getContent(desc:string){
      this.originalDesc = desc;
    if(desc.length > 200){
      this.details.ngos[0].description = desc.substring(0,99);   this.isShort = true; this.showReadMoreButton = true;     
    }
    else{
      this.details.ngos[0].description = desc; this.showReadMoreButton = false;
    }    
}

setReadMoreOn(){
  if(this.isShort){
    this.details.ngos[0].description = this.originalDesc; this.isShort = false;
  }
  else{
    this.isShort = true; this.getContent(this.details.ngos[0].description);
  }
}

onClickEdit(){
  var redirect = "admin/campaigns/public/edit/"+this.campiagnId.toString();
  if(!this.details.isPublic){
    redirect = "admin/campaigns/private/edit/"+this.campiagnId.toString();
   }
   this.router.navigateByUrl(redirect);
}


  setPushMessage() {
    this.pushmsgSendSuccess = false;
    this.pushmsgValidation = false;
  }

  onPushNotificationSelect(){
    this.isPushNotification = true; this.isEmail = false;
  }

  onEmailSelect(){
    this.isEmail = true; this.isPushNotification = false;
  }


  generateReport() {
    var req = { "campaignId": this.campiagnId,"corporateId": this.corporateId }
    this.reportService.generateVolunteerReport(req).subscribe(response => {
      if(response.isSuccess){
        let elem = document.createElement("a");
        elem.target = "_self"; elem.href = response.url; elem.setAttribute("download", response.url);
        elem.click();
      }
    })
  }
  
  ngOnInit() {
    this.getCampaignDetail();

  }

}
