import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../shared/services/authentication.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import { CookieService } from 'angular2-cookie/core';

@Component({
  selector: 'app-basic-login',
  templateUrl: './basic-login.component.html'
//  styleUrls: ['./basic-login.component.scss']
})
export class BasicLoginComponent implements OnInit {

  email: string;
  password: string;
  roleId:any;
  errorMessage: string;
  loginForm: FormGroup;
  validateForm: boolean = false;
  rememberMe: boolean;

  constructor(private _service: AuthenticationService, private router: Router) {
    var detail = this._service.getToken();
    if(detail!=null){
      console.log(detail);
       var login = detail.access_token;
       this.roleId = login.RoleId;
       this._service.navigateBasedOnRole(login.role);
    }
  }

  createForm() {
    this.loginForm = new FormGroup({
      Email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  login() {
    this.validateForm = true;
    this.errorMessage = '';
    if (this.loginForm.valid) {
      var obj = { Email: this.loginForm.controls.Email.value, Password: this.loginForm.controls.password.value,RoleId: this.roleId};
        this._service.login(obj, this.rememberMe).subscribe(response => {
      
        if (!response.isSuccess) {
          this.errorMessage = "That Username or password is incorrect";
        }
      },
        (err) => {
          this.errorMessage = "Credentials have been rejected by admin.";
        });
    }
  }

  ngOnInit() {
    this.createForm();
  }
}
