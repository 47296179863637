import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { AuthenticationService } from '../../../shared/services/authentication.service'
import { AdminMasterService } from '../../../shared/services/master.services'
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
  sections: any;
  corporateSections:any[] = [];
  adminLoginId: any;
  corporateId: any;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private service: AdminMasterService,
    private fb: FormBuilder,
    private toastr: ToastrManager
  ) {

    var auth = this.authentication.getToken();
    if (auth == null) {
        this.authentication.logout(); return;
    }
    this.adminLoginId = auth.access_token.adminLoginId;
    this.corporateId = auth.access_token.corporateId;
  }

  getMasterSections(){
    this.service.getMasterSections().subscribe(response => {
        if(response.isSuccess){
            var sections = [];
            sections = response.list;
            if(sections.length > 0){
               sections.forEach(section => {
                   this.corporateSections.push({
                    sectionId:section.sectionId,
                    sectionName:section.sectionName,
                    isActive:false
                   });
               });
            }

            this.getCorporateSections();

            console.log("sections",this.corporateSections)
        }
    },(err)=>{
      this.toastr.errorToastr("Some error occured");
    })
  }

  getCorporateSections(){
    var request = { "corporateId":this.corporateId };
    this.service.getCorporateSections(request).subscribe(response => {
      if(response.isSuccess){

        var sections = [];
        sections = response.list;
        sections.forEach(element => {
           this.corporateSections.forEach(section => {
             if(element.sectionId == section.sectionId){
               section.isActive = element.isActive;
             }
           })
        });
      }
    })
  }

  Save(){
    var object = { "list": this.corporateSections };
    var corporate = { "corporateId":this.corporateId };
    var request = { ...object,...corporate }
    this.service.addUpdateSections(request).subscribe(response => {
        if(response.isSuccess){
          this.toastr.successToastr("Sections updated");
        }
    },(err)=>{
      this.toastr.errorToastr("Some error occured");
    })
  }

  updateStatus(i:number,isSelected){
    this.corporateSections[i].isActive = isSelected;
  }

  ngOnInit() {
    this.getMasterSections();
  }

}
