import { Component, OnInit } from '@angular/core';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-volunteer-list',
  templateUrl: './volunteer-list.component.html',
  styleUrls: ['./volunteer-list.component.scss']
})
export class VolunteerListComponent implements OnInit {
  adminLoginId: number;
  roleId:number;
  volunteers: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  nameIcon: string = "sorting";
  genderIcon: string = "sorting";
  cityIcon: string = "sorting";
  stateIcon: string = "sorting";
  countryIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
  selectedVolunteer: any;
  comment: string;
  csvResponse: any;
  errorCSVUri: string;
  csvErrorMessage: string;
  corporateId: number;
  isSearchEnabled: boolean;

  constructor(private _service:AdminVolunteerService,private authentication:AuthenticationService,private toaster:ToastrManager) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1 || login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    this.roleId = details.access_token.role;

   }

   getVolunteers() {
    var obj = { AdminLoginId: this.adminLoginId, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn,RoleId:this.roleId };
console.log(obj);
  this._service.getAllVolunteers(obj).subscribe(response => {
    console.log(response);
  
      if (response.isSuccess) {
          this.volunteers = response.list;
          this.count = response.count;
          if (this.count == 0) {
              this.startFrom = 0;
          }
          else {
              this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.count <= this.pageSize) {
              this.endOn = this.count;
              this.nextPageClass = "disabled";
              this.lastPageClass = "disabled";
          }
          else {
              this.endOn = this.pageNumber * this.pageSize;
              if (this.endOn > this.count) {
                  this.endOn = this.count;
              }
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
              this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
              this.endOn = this.count;
          }
      }
  });
}

selectvolunteer(volunteer: any) {
  this.selectedVolunteer = volunteer;
  this.comment = '';
}

sortColumns(columnName: string) {
  switch (columnName) {
    case "Name":
      if (this.nameIcon == "sorting") {
        this.nameIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.nameIcon == "sorting_asc") {
        this.nameIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.nameIcon == "sorting_desc") {
        this.nameIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.genderIcon = "sorting";
      this.cityIcon = "sorting";
      this.stateIcon = "sorting";
      this.countryIcon = "sorting";
      break;
    case "Gender":
      this.nameIcon = "sorting";
      if (this.genderIcon == "sorting") {
        this.genderIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.genderIcon == "sorting_asc") {
        this.genderIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.genderIcon == "sorting_desc") {
        this.genderIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.cityIcon = "sorting";
      this.stateIcon = "sorting";
      this.countryIcon = "sorting";
      break;
    case "City":
      this.nameIcon = "sorting";
      if (this.cityIcon == "sorting") {
        this.cityIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.cityIcon == "sorting_asc") {
        this.cityIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.cityIcon == "sorting_desc") {
        this.cityIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.genderIcon = "sorting";
      this.stateIcon = "sorting";
      this.countryIcon = "sorting";
      break;
    case "State":
      this.nameIcon = "sorting";
      if (this.stateIcon == "sorting") {
        this.stateIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.stateIcon == "sorting_asc") {
        this.stateIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.stateIcon == "sorting_desc") {
        this.stateIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.cityIcon = "sorting";
      this.genderIcon = "sorting";
      this.countryIcon = "sorting";
      break;
    case "Country":
      this.nameIcon = "sorting";
      if (this.countryIcon == "sorting") {
        this.countryIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.countryIcon == "sorting_asc") {
        this.countryIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.countryIcon == "sorting_desc") {
        this.countryIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.cityIcon = "sorting";
      this.stateIcon = "sorting";
      this.genderIcon = "sorting";
      break;
  }
  this.sortColumn = columnName;
  this.pageNumber = 1;
  this.getVolunteers();
}

firstPage() {
  if (this.pageNumber > 1) {
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.pageNumber = 1;
    this.getVolunteers();
  }
}

lastPage() {
  if (this.pageNumber < this.numberOfPages) {
    this.firstPageClass = "";
    this.previousPageClass = "";
    this.nextPageClass = "disabled";
    this.lastPageClass = "disabled";
    this.pageNumber = this.numberOfPages;
    this.getVolunteers();
  }
}

previousPage() {
  if (this.pageNumber > 1) {
    this.pageNumber = this.pageNumber - 1;
    if (this.pageNumber == 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
    }
    this.getVolunteers();
  }
}

nextPage() {
  if (this.pageNumber < this.numberOfPages) {
    this.pageNumber = this.pageNumber + 1;
    this.firstPageClass = "";
    this.previousPageClass = "";
    if (this.pageNumber == this.numberOfPages) {
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
    }
    this.getVolunteers();
  }
}

keyDownHandler(event) {
  if (event.target.value.length > 0) {
    return true;
  } else {
    if (event.keyCode === 32) {
      return false;
    }
  }
}

removeSearch(){
  this.searchTerm = '';
  this.isSearchEnabled = false;
  this.getVolunteers();
}
applySearch() {
  if(this.searchTerm == undefined || this.searchTerm == null || this.searchTerm.trim() == ''){
     this.toaster.errorToastr("Enter search term");
  }
  else{
      
      this.getFilteredList();
      this.isSearchEnabled = true;
  }        
}

getFilteredList(){
  this.pageNumber = 1;
  this.firstPageClass = "disabled";
  this.previousPageClass = "disabled";
  this.nextPageClass = "";
  this.lastPageClass = "";
  this.getVolunteers();
}

changePageSize(pageSize: number) {
  this.pageSize = pageSize;
  this.pageNumber = 1;
  this.firstPageClass = "disabled";
  this.previousPageClass = "disabled";
  this.nextPageClass = "";
  this.lastPageClass = "";
  this.getVolunteers();
}

checkIfBoxEmpty() {
  if (this.searchTerm == '' || this.searchTerm == null) {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getVolunteers();
  }
}

performAction(volunteer: any, statusId: number, status: string) {
  var obj = { UserLoginId: volunteer.userLoginId, StatusId: statusId, Comment: this.comment };
  this._service.performAction(obj).subscribe(response => {
    if (response.isSuccess) {
      volunteer.userCorporateStatusId = statusId;
      volunteer.userCorporateStatus = status;
    }
  });
}

  downloadCsvFile() {
      var list = this.volunteers;
      if (list != null && list.length > 0) {
          var obj = { AdminLoginId: this.adminLoginId, RoleId:this.roleId };
          this._service.getVolunteerCsv(obj).subscribe(response => {
              console.log(response);
              if (response.isSuccess) {
                  let elem = document.createElement("a");
                  elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                  elem.click();
              }
          });
      }
      else {
          alert("No volunteers in the list");
      }
}

uploadCSV(obj) {
  this._service.addVolunteerCsv(obj).subscribe(response => {
    this.csvResponse = response;
    this.csvErrorMessage = null;
    var element = document.getElementById("errorCsvLink");
    if (this.csvResponse.isSuccess) {

      console.log(this.csvResponse);
      this.getVolunteers();

      if (this.csvResponse != null && this.csvResponse.errorCSVUrl != null) {
        this.errorCSVUri = this.csvResponse.errorCSVUrl;
        this.csvErrorMessage = this.csvResponse.invalidCSVMessage.message;

        element.style.display = "block";
      }
      else {
        if(this.csvResponse != null && this.csvResponse.csvUploadMessage!=null)
        this.toaster.successToastr(this.csvResponse.csvUploadMessage.message);
        element.style.display = "none";
      }
    }
    else {
      element.style.display = "none";
    }
  })
  }

  // getFilteredVolunteerList() {
  //     if (this.userOnBoardStatus != '' || this.userRegistrationStatusId != '') {
  //         this.getVolunteers();

  //         var el = document.getElementById("clearFilters");
  //          el.style.display = "block";
  //     }
  //     else {
  //         alert("No filters selected");
  //     }
  // }
  // clearAllFilters(event) {
  //     if (this.userOnBoardStatus != '' || this.userRegistrationStatusId != '') {
  //         this.userRegistrationStatusId = '';
  //         this.userOnBoardStatus = '';
  //         this.getVolunteers();
  //         event.target.style.display = "none";
  //     }
  // }

  // getUserRegistrationStatus() {
  //     var obj = {}
  //     this._service.getUserRegistrationStatus(obj).subscribe(response => {
  //         console.log(response);
  //         if (response.isSuccess) {
  //             this.userRegistrationStatusList = response.masterUserRegistrationStatus;
  //             this.userRegistrationStatusId = '';
  //         }
  //     })
  // }

onFileClick(event) {
  var files = event.target.files;
  console.log(files);

  if (files && files.length > 0) {

    var _file = files[0];

    var extension = _file.name.toLowerCase().substr(_file.name.length - 3, 3);
  
    if (extension != "csv") {
      this.toaster.errorToastr("Please select csv file");
      return;
    }

    var obj = { file: _file, AdminLoginId: this.adminLoginId, CorporateId: this.corporateId  }
    this.uploadCSV(obj);
  }

}

  // selectFromDate() {
  //     this.toDateOptions = {
  //         minDate: new Date(this.fromDate),// Minimal selectable date
  //         maxDate: new Date(Date.now())
  //     };
  //     this.getVolunteers();
  // }

  // selectToDate() {
  //     this.getVolunteers();
  // }

clearFiles() {
  var c = document.getElementsByTagName("input")[0];
  c.value = '';
}

openFileDialog() {
  var btn = document.getElementById("selectFile");
  btn.click();
}


  ngOnInit() {
    this.getVolunteers();
  }

}
