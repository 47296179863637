import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { ReportService } from './../../shared/services/report.service';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
})

export class ReportListComponent implements OnInit {

  startDate: Date = new Date();
  endDate: Date = new Date();
  reportRequest: number = 0;
  errorMessage: string;
  

    constructor(private _service: ReportService, private authentication: AuthenticationService) {
        var details = this.authentication.getToken();
        if (details == null) {
            this.authentication.logout(); return;
        } else {
            var login = details.access_token;
            if (login.role == 2) {
                this.authentication.logout(); return;
            }
        }
    }

  options: DatepickerOptions = {
      minDate: new Date((new Date().setFullYear((new Date(Date.now()).getFullYear() - 1)))), // Minimal selectable date
      maxDate: new Date(Date.now())
  };

  endOptions: DatepickerOptions = {
    minDate: new Date((new Date().setFullYear((new Date(Date.now()).getFullYear() - 1)))), // Minimal selectable date
      maxDate: new Date(Date.now())
  };

  setOptions(evnt: any) {
    this.endOptions = {
      minDate: evnt, // Minimal selectable date
        maxDate: new Date(Date.now())
    };
  }

  
  downloadReport() {
   if(this.startDate > this.endDate){
  this.errorMessage = "Start date should be greater than end date";
    }


    else if( this.reportRequest == 0){
        this.errorMessage = "Please select reporting type";
    }
else{
  this.errorMessage = '';
  
  let StartDateline = new Date(this.startDate);
StartDateline.setHours(StartDateline.getHours() + 5);
StartDateline.setMinutes(StartDateline.getHours() + 30);
//this.startDate =  StartDateline;

let EndDateline = new Date(this.endDate);
EndDateline.setHours(EndDateline.getHours() + 5);
EndDateline.setMinutes(EndDateline.getHours() + 30);
//this.endDate =  EndDateline;

       var obj = { ReportRequest: this.reportRequest, StartDate: StartDateline, EndDate: EndDateline };
    this._service.generateReport(obj).subscribe(response => {
      if (response.isSuccess) {
        let a = document.createElement("a");
        a.target = "_self";
        a.href = response.fileUrl;
        a.setAttribute("download", response.fileUrl);
        a.click();
        }

    });
}

    
  }

  ngOnInit() {
  }




}
