
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
import { Broadcast } from './broadcast.service';

import { environment } from './../../../environments/environment';
//import { IAdminLogin } from './auth.interface';

@Injectable()
export class AuthenticationService {
  token: any = {
    auth_token: '',
    access_token: {
      adminLoginId: '',
      userLoginId:'',
      username: '',
      role: '',
      profilePicture: '',
      corporateId: '',
      corporateName: '',
      token:''
    }
  };

  tokenKey: string = "a5smm_utoken"
  details: any;

    constructor(private router: Router, private http: HttpClient, private broadcast: Broadcast) {
      
  }

  authorizeToken(username) {
    
    this.token.auth_token = ''; 
    this.token.access_token = {
      adminLoginId: this.details.data.adminLoginId,
      userLoginId:this.details.userLoginId,
      username: this.details.data.firstName + ' ' + this.details.data.lastName,
      role: this.details.data.roleId,
      profilePicture: this.details.data.profilePicture,
      corporateLogo: this.details.data.corporateLogo,
      token:this.details.data.token
    }

    this.setCorporate();
    this.setToken(this.token);
    this.setAdminDetails();
    this.navigateBasedOnRole(this.details.data.roleId);
  }


  login(obj, rememberMe): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/Login', obj).pipe(
      tap(res => {
          if (res.isSuccess) {
            
          this.details = res;
          this.authorizeToken(obj.Email);
          if (rememberMe) {
            document.cookie = this.tokenKey + "=" + JSON.stringify(this.token);
          }
        }
      }),
      catchError(this.handleError),);
  }

  loginAsVolunteer(obj,rememberMe): Observable<any> {
      return this.http.post<any>(environment.apiBasePath + 'User/SignIn',obj).pipe(
      tap(res=> res),
      catchError(this.handleError),);
    
  }

  register(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/Registration', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  CreateSlug(obj): Observable<any> {
      return this.http.post<any>('https://cmsapistage.mykartavya.site/api/create/client', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }

    RemoveCorporate(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Corporate/SetCorporateStatusToDeleted', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }

  forgotPassword(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/ForgetPassword', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  resetPassword(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/ResetPassword', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  checkIfLinkActive(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/ResetLinkActive', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/GetProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSuperAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/GetSuperAdminProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

updateSuperAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/UpdateSuperAdminProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  updateAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/UpdateProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  updateCorporateAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/UpdateCorporate', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  updateCorporateSlug(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/UpdateCorporateSlug', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  createUsers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/CreateUser', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getUsers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/GetUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/deleteUser', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  getAllCompanies(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getAllBirthday(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetBirthdayList', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getBirthdayCsv(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetBirthdayListCsv', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getContactCsv(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetContactListCsv', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getAllContact(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetContactList', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAllNotification(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Notification/BirthdayList', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deletecompany(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/Delete', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addCorporateLogo(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CorporateId', obj.CorporateId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/corporate/UploadLogo', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addCorporateLicense(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CorporateId', obj.CorporateId);
    if (obj.file) {
      for (var i = 0; i < obj.file.length; i++) {
        formData.append('UploadFiles', obj.file[i]);
      }
    }
    return this.http.post<any>(environment.apiBasePath + '/corporate/UploadLicenses', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  uploadProfile(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('AdminLoginId', obj.adminLoginId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/UploadImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  uploadBanner(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('AdminLoginId', obj.adminLoginId);
    formData.append('Upload', obj.file);

    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/UploadBannerImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  deletecompanyLicense(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/DeleteLicense', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


   actionforStatus(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  setCorporate() {
    // if (this.details.data.associations.length > 0) {
    this.token.access_token.corporateId = this.details.data.corporateId;
    this.token.access_token.corporateName = this.details.data.corporateName;

    // }
  }

  navigateBasedOnRole(roleId) {
    if (roleId === 1) {
      this.router.navigate(['sa/dashboard']);
    }
    else if (roleId === 2) {
      this.router.navigate(['admin/dashboard']);
    }
    else {
      this.router.navigate(['volunteermanager/volunteer/list']);
    }
  }


  logout() {
    this.removeToken();
    document.cookie = this.tokenKey + "=" + "";
    this.router.navigate(['/login']);
    this.broadcast.broadcast('is-authenticated', false);
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey));
  }

  getAuthToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey))['auth_token'];
  }

  setToken(token) {
    localStorage.setItem(this.tokenKey, JSON.stringify(token));
    this.broadcast.broadcast(this.tokenKey, JSON.stringify(token));
  }

  setAdminDetails() {
    localStorage.setItem("adLogDeta", JSON.stringify(this.details));
  }

  getAdminDetails() {
    return JSON.parse(localStorage.getItem("adLogDeta"));
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey))['access_token'];
  }

  isAuthenticated() {
    let token = localStorage.getItem(this.tokenKey);

    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

    getChapterCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Corporate/GetCorporateListCsv', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }


  removeToken() {
    localStorage.clear();
  }



  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }
}


