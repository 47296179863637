import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DashBoardService } from './../../shared/services/dashboard.service';
import { AuthenticationService } from '../../shared/services/authentication.service';


declare const AmCharts: any;

import '../../../assets/charts/amchart/amcharts.js';
import '../../../assets/charts/amchart/gauge.js';
import '../../../assets/charts/amchart/pie.js';
import '../../../assets/charts/amchart/serial.js';
import '../../../assets/charts/amchart/light.js';
import '../../../assets/charts/amchart/ammap.js';
import '../../../assets/charts/amchart/worldLow.js';
import '../../../assets/charts/amchart/continentsLow.js';

import { NotificationsService } from 'angular2-notifications';
import { ChartModule } from 'angular2-chartjs';
import { AdminEventsService } from '../../shared/services/events.services';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: [
    './default.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DefaultComponent implements OnInit {

  dashboardData: any;
  type: string = 'bar';
  data: any = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [
          "#e5233b",
          "#dda838",
          "#4ea145",
          "#c8202f",
          "#ef402f",
          "#2abfe7",
          "#fcc312",
          "#a41c44",
          "#f36b2d",
          "#e01668",
          "#f89d2a",
          "#c08e2f",
          "#407f46",
          "#1f97d4",
          "#5bb949",
          "#136a9f",
          "#1a4862",
          "#24b898"
        ],
      }
    ]
  };
  options: any = {
    legend: {
      display: false
   },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value = data.datasets[0].data[tooltipItem.index];
          var label = data.labels[tooltipItem.index];
          var sdg = '';
          switch (label) {
            case "No Poverty":
              sdg = 'No Poverty'
              break;
            case 'Zero Hunger':
              sdg = 'Zero Hunger'
              break;
            case 'Good Health and well-being':
              sdg = 'Good Health and well-being'
              break;
            case 'Quality Education':
              sdg = 'Quality Education'
              break;
            case 'Gender Equality':
              sdg = 'Gender Equality'
              break;
            case 'Clean Water and Sanitation':
              sdg = 'Clean Water and Sanitation'
              break;
            case 'Affordable and Clean Energy':
              sdg = 'Affordable and Clean Energy'
              break;
            case 'Decent Work and Economic Growth':
              sdg = 'Decent Work and Economic Growth'
              break;
            case 'Industry, Innovation and Infrastructure':
              sdg = 'Industry, Innovation and Infrastructure'
              break;
            case 'Reduced Inequality':
              sdg = 'Reduced Inequality'
              break;
            case 'Sustainable Cities and Communities':
              sdg = 'Sustainable Cities and Communities'
              break;
            case 'Responsible Consumption and Production':
              sdg = 'Responsible Consumption and Production'
              break;
            case 'Climate Action':
              sdg = 'Climate Action'
              break;
            case 'Life Below Water':
              sdg = 'Life Below Water'
              break;
            case 'Life on Land':
              sdg = 'Life on Land'
              break;
            case 'Peace and Justice Strong Institutions':
              sdg = 'Peace and Justice Strong Institutions'
              break;
            case 'Partnerships to achieve the Goal':
              sdg = 'Partnerships to achieve the Goal'
              break;
            case 'Others':
              sdg = 'Others'
              break;
          }
          //var sdg = this.dashboardData.goals.filter(a => a.goalId == label);
          return sdg + ' : ' + value;
        }
      }
    },
  };

  data1: any = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        minutes:[],
        backgroundColor: [
          "#e5233b",
          "#dda838",
          "#4ea145",
          "#c8202f",
          "#ef402f",
          "#2abfe7",
          "#fcc312",
          "#a41c44",
          "#f36b2d",
          "#e01668",
          "#f89d2a",
          "#c08e2f",
          "#407f46",
          "#1f97d4",
          "#5bb949",
          "#136a9f",
          "#1a4862",
          "#24b898"
        ],
      }
    ]
  };
  options1: any = {
    legend: {
      display: false
   },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data1) {
          var hours = data1.datasets[0].data[tooltipItem.index];
          var minutes = data1.datasets[0].minutes[tooltipItem.index];
          var value = '';
          if(hours > 0 && minutes > 0){
             value = hours+'hr' + ' ' + minutes+'min';
          } 
          else if(hours > 0){
            value = hours+'hr';
          }
          else{
            value = minutes+'min';
          }

          var label = data1.labels[tooltipItem.index];
          var sdg = '';
          switch (label) {
            case "No Poverty":
              sdg = 'No Poverty'
              break;
            case 'Zero Hunger':
              sdg = 'Zero Hunger'
              break;
            case 'Good Health and well-being':
              sdg = 'Good Health and well-being'
              break;
            case 'Quality Education':
              sdg = 'Quality Education'
              break;
            case 'Gender Equality':
              sdg = 'Gender Equality'
              break;
            case 'Clean Water and Sanitation':
              sdg = 'Clean Water and Sanitation'
              break;
            case 'Affordable and Clean Energy':
              sdg = 'Affordable and Clean Energy'
              break;
            case 'Decent Work and Economic Growth':
              sdg = 'Decent Work and Economic Growth'
              break;
            case 'Industry, Innovation and Infrastructure':
              sdg = 'Industry, Innovation and Infrastructure'
              break;
            case 'Reduced Inequality':
              sdg = 'Reduced Inequality'
              break;
            case 'Sustainable Cities and Communities':
              sdg = 'Sustainable Cities and Communities'
              break;
            case 'Responsible Consumption and Production':
              sdg = 'Responsible Consumption and Production'
              break;
            case 'Climate Action':
              sdg = 'Climate Action'
              break;
            case 'Life Below Water':
              sdg = 'Life Below Water'
              break;
            case 'Life on Land':
              sdg = 'Life on Land'
              break;
            case 'Peace and Justice Strong Institutions':
              sdg = 'Peace and Justice Strong Institutions'
              break;
            case 'Partnerships to achieve the Goal':
              sdg = 'Partnerships to achieve the Goal'
              break;
            case 'Others':
              sdg = 'Others'
              break;
          }
          //var sdg = this.dashboardData.goals.filter(a => a.goalId == label);
       
         return sdg + ' : ' + value;
        
        }
      }
    },
  };
  globalLeaderboard: any;
  skillwiseLeaderboard: any;
  corporateId: any;


    constructor(private _service: DashBoardService, private authentication: AuthenticationService) {
        var details = this.authentication.getToken();
        if (details == null) {
            this.authentication.logout(); return;
        } else{
          this.corporateId = details.access_token.corporateId;
        }

        //this.adminLoginId = details.access_token.adminLoginId;
       
    }


  getDashboardData() {

    var obj = {CorporateId:this.corporateId};
    this._service.getDashBoardData(obj).subscribe(response => {
      if (response.isSuccess) {
        this.dashboardData = response;
        this.data.labels = [];
        this.data.datasets[0].data = [];

        this.data1.labels = [];
        this.data1.datasets[0].data = [];
        this.data1.datasets[0].minutes = [];

        for (var i = 0; i < this.dashboardData.goals.length; i++) {
          // this.data.labels.push(this.createSDGshortcuts(this.dashboardData.goals[i].goal));//this.dashboardData.goals[i].goalId);
       
          this.data.labels.push(this.dashboardData.goals[i].goal);
          this.data.datasets[0].data.push(this.dashboardData.goals[i].amountSaved);

          this.data1.labels.push(this.dashboardData.goals[i].goal);            
          this.data1.datasets[0].data.push(this.dashboardData.goals[i].hoursDonated);
          this.data1.datasets[0].minutes.push(this.dashboardData.goals[i].minutesDonated);
          
        }
      }
    },
      (err) => {
      });
  }

  createSDGshortcuts(sdg: string) {
    var returnString: string = '';
    var arr = sdg.split(" ");
    for (var i = 0; i < arr.length; i++) {
      returnString += arr[i].substring(0, 1).toLocaleUpperCase();
    }
    return returnString;
  }

  getLeaderboardGlobal(){
     this._service.GetLeaderboardGlobal({}).subscribe(response => {
         if(response.isSuccess){
           this.globalLeaderboard = response.list;
         }
     },(err)=>{

     });
  }

  getLeaderboardSkillwise(){
    this._service.GetLeaderBoardUsersKarmaPoints({}).subscribe(response => {
        if(response.isSuccess){
          this.skillwiseLeaderboard = response.list;
        }
    },(err)=>{
      
    });
 }

  ngOnInit() {
    this.getDashboardData();
    this.getLeaderboardGlobal();
    this.getLeaderboardSkillwise();
  }

}
