
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class SurveyService {

  constructor(private router: Router, private http: HttpClient) { }

  getEventEndDate(obj):Observable<any>{
    return this.http.post<any>(environment.apiBasePath + '/Event/GetEventDate', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  getAllSurveys(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  performAction(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  deleteSurvey(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/Delete', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getQuestionTypes(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/GetAllQuestionTypes', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createSurvey(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(environment.apiBasePath + '/Survey/Create', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  updateSurvey(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/Update', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSurveyDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/Details', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSurveyUserDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Survey/SurveyUserDetails', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
