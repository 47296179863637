import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { NgDatepickerModule, DatepickerOptions } from 'ng2-datepicker';

import { VolunteermanagerRoutingModule } from './volunteermanager-routing.module';
import { VolunteerCreateComponent } from './volunteer/volunteer-create/volunteer-create.component';
import { VolunteerListComponent } from './volunteer/volunteer-list/volunteer-list.component';
import { VolunteerDetailComponent } from './volunteer/volunteer-detail/volunteer-detail.component';
import { VolunteermanagerProfileComponent } from './profile/volunteermanager-profile/volunteermanager-profile.component';

@NgModule({
  imports: [
    CommonModule,
    VolunteermanagerRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    NgDatepickerModule
  ],
  declarations: [VolunteerCreateComponent, VolunteerListComponent, VolunteerDetailComponent, VolunteermanagerProfileComponent]
})
export class VolunteermanagerModule { }
