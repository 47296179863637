import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { AdminMasterService } from '../../../shared/services/master.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { fbind } from 'q';

@Component({
  selector: 'app-volunteer-create',
  templateUrl: './volunteer-create.component.html',
  styleUrls: ['./volunteer-create.component.scss']
})
export class VolunteerCreateComponent implements OnInit {

  volunteersForm: FormGroup;
  validateForm: boolean = false;
  adminLoginId: number;
  corporateId: number;
  states: any[] = [];
  cities: any[] = [];
  errorMessage: string;
  countries: any[] = [];
  isSubmitEnable: boolean;
  volunteerId:number;
  volunteer:any;

  constructor(private fb:FormBuilder,private toaster:ToastrManager, private masterService: AdminMasterService, private authentication: AuthenticationService, private _service: AdminVolunteerService, private router: Router,private _location: Location,private route:ActivatedRoute) { 
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1 || login.role == 2) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    this.volunteerId = this.route.snapshot.params['id'];
    console.log( this.volunteerId)
  }

  createForm() {
    this.volunteersForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      countryId: ['', Validators.required],
      stateId: ['', Validators.required],
      cityId: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.maxLength(40), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]],
      mobileNumber:  ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],

    });
  }

  save() {
    this.validateForm = true;
    if(this.volunteersForm.valid){
   
    var val = this.volunteersForm.value;
    var pair = { "AdminLoginId": this.adminLoginId,"CorporateId":this.corporateId , "UserLoginId":this.volunteerId};
    var obj = { ...val, ...pair };
      this._service.addVolunteer(obj).subscribe(response => {
        if (response.isSuccess) {          
            this.router.navigate(['/volunteermanager/volunteer/list']);     
            this.toaster.successToastr(response.responseMessage)    
        }else{
            this.toaster.errorToastr(response.responseMessage); this.isSubmitEnable = true;
        }
      },(err)=>{
        this.toaster.errorToastr("Some error occurred"); this.isSubmitEnable = true;
      });
    }else{
      this.isSubmitEnable = true;
    }
  }

  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }


  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  backClicked() {
    this._location.back();
  }
  keyDownHandler(event) {
    if (event.target.value.length > 0) {
      return true;
    } else {
      if (event.keyCode === 32) {
        return false;
      }
    }
  }

  // only number validation
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
      this.createForm();
      this.isSubmitEnable = true;

      if(this.volunteerId!=null && this.volunteerId > 0){
        this._service.getVolunteerDetail({"UserLoginId":this.volunteerId}).subscribe(response => {
          if(response.isSuccess){
            this.volunteer = response.data;
            this.getStates(this.volunteer.countryId);
            this.getCities(this.volunteer.stateId);
            
            this.volunteersForm.setValue({
              firstName: this.volunteer.firstName,
              lastName: this.volunteer.lastName,
              countryId: this.volunteer.countryId,
              stateId: this.volunteer.stateId,
              cityId: this.volunteer.cityId,
              gender: this.volunteer.gender,
              email: this.volunteer.email,
              mobileNumber: this.volunteer.mobileNumber

            });
            this.volunteersForm.get("email").disable();
            this.volunteersForm.get("mobileNumber").disable();
          }
        })
      }
  }
  

}
