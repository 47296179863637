import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicLoginComponent } from './login/basic-login.component';
import { LayoutComponent } from './layout/all-layout/layout.component';
import { RegisterComponent } from './login/register/register.component';
import { ForgotPasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './login/resetpassword/resetpassword.component';





const routes: Routes = [
  {
    path: '',
    component: BasicLoginComponent, pathMatch: 'full'
  },
  {
    path: 'login',
    component: BasicLoginComponent, pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetPassword/:email/:time',
    component: ResetPasswordComponent
  },
    {
    path: 'resetPassword/:email',
    component: ResetPasswordComponent
  },
  {
    path: 'admin',
    component: LayoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'admin',
    component: LayoutComponent,
    children: [
      //{
      //  path: '',
      //  redirectTo: 'dashboard/default',
      //  pathMatch: 'full'
      //},
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'navigation',
        loadChildren: () => import('./layout/navigation/navigation.module').then(m => m.NavigationModule)
      }
    ]
  },
  {
    path: 'volunteermanager',
    component: LayoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'volunteermanager',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./volunteermanager/volunteermanager.module').then(m => m.VolunteermanagerModule)
      },
      {
        path: 'navigation',
        loadChildren: () => import('./layout/navigation/navigation.module').then(m => m.NavigationModule)
      }
    ]
  },
  {
    path: 'sa',
    component: LayoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'sa',
    component: LayoutComponent,
    children: [
      //{
      //  path: '',
      //  redirectTo: 'dashboard/default',
      //  pathMatch: 'full'
      //},
      {
        path: '',
        loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperadminModule)
      },
      {
        path: 'navigation',
        loadChildren: () => import('./layout/navigation/navigation.module').then(m => m.NavigationModule)
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
