import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'media-select',
  templateUrl: './selectMedia.component.html',
})

export class MediaSelectComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  eventId: number;
  taskId: number;
  medias: any[] = [];

  constructor(private _service: FeedbackService, private authentication: AuthenticationService, private router: Router, private route: ActivatedRoute) {
      var details = this.authentication.getToken();
      if (details == null) {
          this.authentication.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.authentication.logout(); return;
          }
      }
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
    router.events.subscribe((response) => {
      this.eventId = route.snapshot.params['eventId'];
      this.taskId = route.snapshot.params['taskId'];
    });
  }


  getMedia() {
    var obj = { EventId: this.eventId, PageSize: 500, PageNumber: 1, TaskId: this.taskId };
    this._service.getEventFeedback(obj).subscribe(response => {
      if (response.isSuccess) {
        this.medias = response.list;
        console.log(this.medias)
      }
    });
  }


  ngOnInit() {
    this.getMedia();
  }
}
