import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { QuizService } from '../../../shared/services/quiz.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.scss']
})
export class QuizListComponent implements OnInit {
  adminLoginId: any;
  learningZoneId: any;
  quizList: any;
  pageNumber: number=1;
  pageSize: number=10;
  count: any;
  startFrom: number;
  endOn: any;
  nextPageClass: string;
  lastPageClass: string;
  numberOfPages: number;
  firstPageClass: string;
  previousPageClass: string;
  searchTerm: string;
  quizUsers: any;
    curriculumId: any;
    campaignId: any;
    campaignStatusId: any;
  quizDetails: any;

  constructor(
    private router:Router,
    private activeRoute:ActivatedRoute,
    private service:QuizService,
    private auth:AuthenticationService,
    private toaster:ToastrManager
    ) { 
      var details = this.auth.getToken();
      if (details == null) {
          this.auth.logout(); return;
      } else {
          var login = details.access_token;
          if (login.role == 1) {
              this.auth.logout(); return;
          }
      }
      this.adminLoginId = details.access_token.adminLoginId;
      this.campaignId = this.activeRoute.snapshot.params['id'];
      this.campaignStatusId = this.activeRoute.snapshot.params['status'];
      this.curriculumId = this.activeRoute.snapshot.params["curriculumId"];
    }

    getList() {

      var obj = { "curriculumId": this.curriculumId, "adminLoginId": this.adminLoginId, "pageSize": this.pageSize, "pageNumber": this.pageNumber }

      this.service.callGetQuizListService(obj)
      .subscribe(res => {
        console.log(res);
        if(res.isSuccess){
          this.quizList = res.quizList;
            this.count = res.count;
          

          if (this.count == 0) {
            this.startFrom = 0;
          }
          else {
            this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
          }
          if (this.count <= this.pageSize) {
            this.endOn = this.count;
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          else {
            this.endOn = this.pageNumber * this.pageSize;
          }
          var pages = this.count / this.pageSize;
          this.numberOfPages = 0;
          for (var i = 0; i < pages; i++) {
            this.numberOfPages = i + 1;
          }
          if (this.endOn > this.count) {
            this.endOn = this.count;
        }
        }
      })
    }

    activateQuiz(quiz){

      var reqObj = {"adminLoginId":this.adminLoginId, "quizId":quiz.quizId }
      this.service.callActivateQuizService(reqObj)
      .subscribe(res => {
        if (res.isSuccess) {
          this.getList();
          this.toaster.successToastr("Quiz activated successfully");
        }
        else{
          if(res.responseMessage!=null && res.responseMessage!=''){
            this.toaster.errorToastr(res.responseMessage);
          }
          else{
            this.toaster.errorToastr("Some error occured");
          }
        
        }
      })
    }

    deactivateQuiz(quiz){

      var reqObj = { "adminLoginId":this.adminLoginId, "quizId":quiz.quizId }
      this.service.callDeactivateQuizService(reqObj)
      .subscribe(res => {
        if (res.isSuccess) {
          this.getList();
          this.toaster.successToastr("Quiz deactivated successfully");
        }
        else{
          this.toaster.errorToastr("Some error occured");
        }
      })
    }

    deleteQuiz(quiz){
      var reqObj = { "quizId":quiz.quizId,"adminLoginId":this.adminLoginId }
      this.service.callDeleteQuizService(reqObj)
      .subscribe(res=>{
        if(res.isSuccess){
           this.getList();
           this.toaster.successToastr("Quiz is deleted successfully");
        }
        else{
          this.toaster.errorToastr("Some error occured");
        }
      })
    }

    getQuizUsers(quiz){
      var reqObj = { "quizId":quiz.quizId }
      this.service.callQuizUsersService(reqObj)
      .subscribe(res => {
        if(res.isSuccess){
            this.quizUsers = res.quizUserList;
            let elem = document.getElementById("model-user") as HTMLElement;
            elem.click();
        }
        else{
            this.toaster.errorToastr("Some Error Occured");
        }
      })
    }

    getUserQuizDetail(evt)
    {
      this.service.getQuizDetail({"quizId":evt.quizId,"UserLoginId":evt.userId})
      .subscribe(response => 
      {
          if(response.isSuccess)
          {
            this.quizDetails = response.quizDetail;
            let elem = document.getElementById("model-detail") as HTMLElement;
            elem.click();
          }
      });
    }

    playAudio(event) {
      let opner = window.open(event, '_blank', 'height=300,width=300');
  }

    navigateBack() {
        var routeParams = this.activeRoute.snapshot.url;
        var route = "/admin/" + routeParams[0].path + "/" + routeParams[1].path + "/curriculum/";
        this.router.navigate([route, this.campaignId, this.campaignStatusId]);
    }

    firstPage() {
      if (this.pageNumber > 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.pageNumber = 1;
        this.getList();
      }
    }
  
    lastPage() {
      if (this.pageNumber < this.numberOfPages) {
        this.firstPageClass = "";
        this.previousPageClass = "";
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
        this.pageNumber = this.numberOfPages;
        this.getList();
      }
    }
  
    previousPage() {
      if (this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
        if (this.pageNumber == 1) {
          this.firstPageClass = "disabled";
          this.previousPageClass = "disabled";
          this.nextPageClass = "";
          this.lastPageClass = "";
        }
        this.getList();
      }
    }
  
    nextPage() {
      if (this.pageNumber < this.numberOfPages) {
        this.pageNumber = this.pageNumber + 1;
        this.firstPageClass = "";
        this.previousPageClass = "";
        if (this.pageNumber == this.numberOfPages) {
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        this.getList();
      }
    }
  
    checkIfBoxEmpty() {
      if (this.searchTerm == '' || this.searchTerm == null) {
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getList();
      }
    }
  
    search() {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getList();
    }
  
    changePageSize(pageSize: number) {
      this.pageSize = pageSize;
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getList();
    }
    

  ngOnInit() {
    this.getList();
  }

}
